/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
// import attch from '../attch.png';
// import Container from 'react-bootstrap/Container';
import ItemForm from '../ItemForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Button from 'react-bootstrap/Button';
import logo from '../logo.png';
import InputMask from 'react-input-mask';
import info from '../info.png';
import $ from 'jquery';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import swal from 'sweetalert';
import MenuSidebar from '../Individual/MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';
import UploadImage from '../Individual/UploadImage';

const Four = ({ func, bankowner, setForm, formData, navigation, saveAndExist }) => {
  const {
    isNZBank,
    isNZBank12,
    isNZBank2,
    primaryBankAccountName,
    primaryBankAccountName2,
    primaryBankAccountNumber,
    primaryBankAccountNumber2,
    bankSwiftCode2,
    bankSwiftCode
  } = formData;
  // const [file, setFile] = useState(null);
  const [imageLoader, setImageLoader] = useState();
  const [imageLoader1, setImageLoader1] = useState();
  // const [imageLoader2, setImageLoader2] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  const [errror, setErrror] = useState();

  const [namefull1, setNamefull1] = useState(
    formData.secondholder ? formData.secondholder.trim().split(' ') : ''
  );

  if (
    formData.secondholder != null &&
    formData.secondholder != '' &&
    formData.secondholder != 'undefined'
  ) {
    formData.firstName12 = namefull1[0];
    if (namefull1.length > 1) {
      formData.lastName12 = namefull1[namefull1.length - 1];
    }

    if (namefull1 && namefull1.length > 2) {
      formData.middleName12 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }
  // let { document, fileName } = documentFile64;
  const datetime = new Date();
  // async function uploadWithJSON(file, e) {
  //   formData.primaryBankProof = '';

  //   setErrror('');
  //   setdocumentFile64(file.name);
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);
  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {
  //     let dataupdate = {
  //       columnName:
  //         isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined'
  //           ? 'primaryBankProof'
  //           : 'secondaryBankProof',
  //       fileName:
  //         isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined'
  //           ? `primary_bank_details`
  //           : `primary_bank_details`,
  //       document: data.img64,
  //       userCode: formData.userCode
  //     };

  //     setImageLoader(true);
  //     axios
  //       .post(`${multisavedata}/genimageurl`, dataupdate, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.primaryBankProof = res.data.url;
  //         formData.primaryBankProof = res.data.url;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //         } else {
  //           setImageLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader(false);
  //       });
  //   }
  //   localStorage.setItem('irdNumberFile', data.img64);
  // }

  // async function uploadWithJSON1(file, e) {
  //   formData.primaryBankProof2 = '';

  //   setErrror('');
  //   setdocumentFile64(file.name);
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);
  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {
  //     let dataupdate = {
  //       columnName:
  //         isNZBank2 === 'true' || isNZBank2 === null || typeof isNZBank2 === 'undefined'
  //           ? 'primaryBankProof'
  //           : 'secondaryBankProof',
  //       fileName:
  //         isNZBank2 === 'true' || isNZBank2 === null || typeof isNZBank2 === 'undefined'
  //           ? `secondary_Bank_details`
  //           : `secondary_Bank_details`,
  //       document: data.img64,
  //       userCode: formData.userCode
  //     };

  //     setImageLoader1(true);
  //     axios
  //       .post(`${multisavedata}/genimageurl`, dataupdate, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.primaryBankProof2 = res.data.url;
  //         formData.primaryBankProof2 = res.data.url;

  //         if (res.status === 200) {
  //           setImageLoader1(false);
  //         } else {
  //           setImageLoader1(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader1(false);
  //       });
  //   }
  //   localStorage.setItem('irdNumberFile', data.img64);
  // }
  let labeltxt = 'Upload proof of account';
  const columnName = 'primaryBankProof';
           
  const fileName = `primary_bank_details`;
           

            let labeltxt2 = 'Upload proof of account';
            const columnName2 = 'secondaryBankProof';
            const fileName2= `secondary_Bank_details`;
                   

  const { fullName } = formData;
  const { previous, next } = navigation;
  const { go } = navigation;

  let idImage = localStorage.getItem('idImage');
  const [state, setstate] = useState();
  const [stateet, setStatet] = useState();
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user'
  };
  const handleClick = () => (setStatet(!stateet), $('.setSelfieImage').attr('src', ''));

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '7';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };
  
  const infobanks = () => {
    swal({
      text: 'Your nominated bank account must be in your name, or in the name of both joint investors. Maximum file size is 2MB.'
    });
  };

  function Onprevious(e) {
    if (formData.biometric2 === 'Biometric') {
      formData.class = 'Five';
      go('Jointten');
    } else {
      formData.class = 'Five';
      go('Jointeleven');
    }
  }

  function handleClick2(e) {



    
    if (isNZBank12 === 'false' ){
      if (primaryBankAccountName === '' || primaryBankAccountName === null) {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }
       if ((bankSwiftCode == ''  || bankSwiftCode === null) && (isNZBank === 'false')) {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
        if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
        if ( formData.primaryBankProof === ""|| formData.primaryBankProof===null ) {
        $('.document2').html('Please attach your document proof ');
      }
       if (primaryBankAccountName2 === '' || primaryBankAccountName2 === null) {
        $('.primaryBankAccountName2').html('Please enter bank account name');
      }
       if (
        ( bankSwiftCode2 == '' || bankSwiftCode2 === null) && (isNZBank2 === 'false')){
        $('.bankSwiftCode2').html('Please enter bank swift code');
      }
        if (primaryBankAccountNumber2 == '' || primaryBankAccountNumber2 === null) {
        $('.primaryBankAccountNumber2').html('Please enter bank account number');
      }
        if ( formData.primaryBankProof2 === ""|| formData.primaryBankProof2===null ) {
        $('.document3').html('Please attach your document proof ');
      }
     

     
  } else {
    if (primaryBankAccountName === '' || primaryBankAccountName === null) {
      $('.primaryBankAccountName').html('Please enter bank account name');
    }
     if ((bankSwiftCode == ''  || bankSwiftCode === null) && (isNZBank === 'false')) {
      $('.bankSwiftCode').html('Please enter bank swift code');
    }
      if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null) {
      $('.primaryBankAccountNumber').html('Please enter bank account number');
    }
      if ( formData.primaryBankProof === ""|| formData.primaryBankProof===null ) {
      $('.document2').html('Please attach your document proof ');
    }
  }



    if (isNZBank12 === 'false' ){
        if (primaryBankAccountName === '' || primaryBankAccountName === null) {
          $('.primaryBankAccountName').html('Please enter bank account name');
        }
        else if ((bankSwiftCode == ''  || bankSwiftCode === null) && (isNZBank === 'false')) {
          $('.bankSwiftCode').html('Please enter bank swift code');
        }
        else  if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null) {
          $('.primaryBankAccountNumber').html('Please enter bank account number');
        }
         else if ( formData.primaryBankProof === ""|| formData.primaryBankProof===null ) {
          $('.document2').html('Please attach your document proof ');
        }
       else  if (primaryBankAccountName2 === '' || primaryBankAccountName2 === null) {
          $('.primaryBankAccountName2').html('Please enter bank account name');
        }
        else if (
          ( bankSwiftCode2 == '' || bankSwiftCode2 === null) && (isNZBank2 === 'false')){
          $('.bankSwiftCode2').html('Please enter bank swift code');
        }
        else  if (primaryBankAccountNumber2 == '' || primaryBankAccountNumber2 === null) {
          $('.primaryBankAccountNumber2').html('Please enter bank account number');
        }
         else if ( formData.primaryBankProof2 === ""|| formData.primaryBankProof2===null ) {
          $('.document3').html('Please attach your document proof ');
        }
         else {
          formData.class = 'Twentyone';
          go('Twentyone1');
      
        }
  
       
    } else{
      if (primaryBankAccountName === '' || primaryBankAccountName === null) {
        $('.primaryBankAccountName').html('Please enter bank account name');
      }
      else if ((bankSwiftCode == ''  || bankSwiftCode === null) && (isNZBank === 'false')) {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
      else  if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
       else if ( formData.primaryBankProof === ""|| formData.primaryBankProof===null ) {
        $('.document2').html('Please attach your document proof ');
      }
       else {
        formData.class = 'Twentyone';
        go('Twentyone1');
      }

    }
    var data = $('.setSelfieImage').attr('src');

    // formData.class = 'Nine';

  }

  function removeError() {
    $('.error').html('');
  }
  const saveandexit = () => {
    if (isNZBank12 === 'true' || isNZBank12 == 'undefined') {
      formData.primaryBankAccountName2 = '';
      formData.primaryBankAccountNumber2 = '';
      formData.bankSwiftCode2 = '';
      formData.primaryBankProof2 = '';
    }

    func();
  };
  useEffect(() => {
    if (isNZBank === 'true' || isNZBank === 'undefined') {
      formData.bankSwiftCode = '';
    }
  });
  useEffect(() => {
    if (isNZBank2 === 'true' || isNZBank2 === 'undefined') {
      formData.bankSwiftCode2 = '';
    }
  });
  const handleChange = (e) => {
    e.preventDefault();
  };
  const isNZBank2yy = () => {
    formData.isNZBank2 = 'true';
  };
  useEffect(() => {
    if (isNZBank12 === 'true' || isNZBank12 == 'undefined') {
      formData.primaryBankAccountName2 = '';
      formData.primaryBankAccountNumber2 = '';
      formData.bankSwiftCode2 = '';
      formData.primaryBankProof2 = '';
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Validation Start

    if (formData.check4 === false) {
      if (primaryBankAccountName == '' || primaryBankAccountName === null) {
        $('.primaryBankAccountName').html('Please enter bank account name');
        console.log('Please enter bank account name');
      }
      // if (formData.primaryBankProof === ""||formData.primaryBankProof===null) {
      //   $('.document2').html('Please attach your document proof');

      // }
      if (primaryBankAccountNumber == '' || primaryBankAccountNumber === null) {
        $('.primaryBankAccountNumber').html('Please enter bank account number');
      }
      if (bankSwiftCode == '' || bankSwiftCode === null) {
        $('.bankSwiftCode').html('Please enter bank swift code');
      }
      // if( formData.primaryBankProof === '' ||
      //         formData.primaryBankProof === null ||
      //         formData.primaryBankProof === 'undefined' ||
      //         formData.primaryBankProof === undefined){
      //   $('.primaryBankProof').html('Please upload primary bank proof');
      // }
      if (primaryBankAccountName2 == '' || primaryBankAccountName2 === null) {
        $('.primaryBankAccountName2').html('Please enter bank account name');
        console.log('Please enter bank account name');
      }
      if (primaryBankAccountNumber2 == '' || primaryBankAccountNumber2 === null) {
        $('.primaryBankAccountNumber2').html('Please enter bank account number');
      }
      if (bankSwiftCode2 == '' || bankSwiftCode2 === null) {
        $('.bankSwiftCode2').html('Please enter bank swift code');
      }
    }

    // Validation End
  });

  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };








  
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>Step 5: Bank Account Details</h2>
        </div>

        <div className="form jointtwelve">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff joint-holder">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>

          <Row className="jointtwelves">
            <Col xs={8}>
              <label>Are you providing one joint bank account, or two bank accounts?</label>
            </Col>
            <Col xs={12} className="accountJoint">
              <div className="button_group space-bottom">
                <div class="switch-field nospace">
                  <div className="check_YN_Bank">
                    <input
                      type="radio"
                      id="radio-one"
                      className="yesno"
                      name="isNZBank12"
                      value="true"
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-one"
                      className={`toogles postalAddressButton ${typeof isNZBank12 === 'undefined' || isNZBank12 === 'true'
                          ? 'actives'
                          : null
                        }`}
                      id="show"
                    >
                      1 ACCOUNT
                    </label>
                    <input
                      type="radio"
                      id="radio-two"
                      name="isNZBank12"
                      value="false"
                      onChange={(e) => {
                        isNZBank2yy();
                        setForm(e);
                        removeError();
                      }}
                    />
                    <label
                      for="radio-two"
                      className={`toogles postalAddressButtons ${isNZBank12 === 'false' ? 'actives' : null
                        }`}
                      id="hide"
                    >
                      2 ACCOUNTS
                    </label>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <>
            <Row className="">
              <Col md={8}>
                <label>Is this a New Zealand bank account?</label>
              </Col>
              <Col xs={12}>
                <div className="button_group space-bottom">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank ">
                      <input
                        type="radio"
                        id="radio-two1"
                        name="isNZBank"
                        value="false"
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-two1"
                        className={`toogles postalAddressButtonsfirst ${isNZBank === 'false' ? 'actives' : null
                          }`}
                        id="show1"
                      >
                        No
                      </label>
                      <input
                        type="radio"
                        id="radio-one1"
                        className="yesno"
                        name="isNZBank"
                        value="true"
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                      />
                      <label
                        for="radio-one1"
                        className={`toogles postalAddressButtonfirst ${typeof isNZBank === 'undefined' || isNZBank === 'true' ? 'actives' : null
                          }`}
                        id="hide1"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </Col>

              <div className="col-md-12">
                {isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined' ? (
                  <div className="row">
                    <Col xs={12}>
                      <ItemForm
                        label="Bank account name "
                        className="quantity"
                        name="primaryBankAccountName"
                        value={primaryBankAccountName}
                        onPaste={handleChange}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        type="text"
                        placeholder="Enter account name"
                        onKeyPress={removeError}
                      />
                      <span className="error primaryBankAccountName"></span>
                      <label>Bank account number</label>
                      <InputMask
                        mask="99-9999-9999999-999"
                        maskChar={null}
                        maskPlaceholder={null}
                        className="numbers"
                        name="primaryBankAccountNumber"
                        type=""
                        value={primaryBankAccountNumber}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        placeholder="xx-xxxx-xxxxxxx-xxx"
                        onKeyPress={removeError}
                      />
                      <span className="error primaryBankAccountNumber"></span>
                    </Col>
                  </div>
                ) : (
                  <div className="row">
                    <Col xs={12}>
                      <label>Bank account name</label>
                      <ItemForm
                        className="quantity"
                        name="primaryBankAccountName"
                        type="text"
                        onPaste={handleChange}
                        value={primaryBankAccountName}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        onKeyPress={removeError}
                        placeholder="Enter account name"
                      />
                      <span className="error primaryBankAccountName accountName"></span>
                      <label>Bank swift code</label>
                      <ItemForm
                        className="digitsOnly"
                        type=""
                        maxLength={16}
                        name="bankSwiftCode"
                        value={bankSwiftCode}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        placeholder="Enter swift code"
                        onKeyPress={removeError}
                      />{' '}
                      <span className="error bankSwiftCode swiftCode"></span>
                      <label>Account/IBAN Number</label>
                      <ItemForm
                        maxlength="35"
                        className="numbers"
                        type="text"
                        name="primaryBankAccountNumber"
                        value={primaryBankAccountNumber}
                        onChange={(e) => {
                          setForm(e);
                          removeError();
                        }}
                        placeholder="Enter account number"
                        onKeyPress={removeError}
                      />
                      <span className="error primaryBankAccountNumber accountNumber"></span>
                    </Col>
                  </div>
                )}
              </div>
            </Row>

            {/* <Row className="fullinput">
              <Col xs={12}>
                <div class="yes backbt">
                  <button className="uploadBtn">
                    <span class="btn_upload bacnkbtn">
                      <input
                        type="file"
                        id="imag2"
                        title=""
                        value={document}
                        onChange={(e) => {
                          uploadWithJSON(e.target.files[0]);
                          removeError();
                        }}
                        onClick={(e) => {
                          e.target.value = null;
                          removeError();
                        }}
                      />
                                          Upload proof of account

                    </span>
                  </button>
                  <img src={info} onClick={infobanks} className="info backi" alt="" />

                  <p className="clickAttach"></p>
                </div>

                <div className="Upload-account attach-style-proof">
                <label className="bankClick">  </label>
                <span className="error file ml-0"></span>
              </div>
              </Col>

           
            </Row> */}
            {/* <div className="spceimg">
              {formData.primaryBankProof === '' ||
                formData.primaryBankProof === null ||
                formData.primaryBankProof === 'undefined' ||
                formData.primaryBankProof === undefined ? (
                ''
              ) : (
                <a href="#" className="licedocument-downloade" onClick={()=>{
                  downloadImage(formData.primaryBankProof)
                }}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              )}
              <span id="name_tag" class="preview2"></span>
              <input type="button" id="removeImage2" value="x" class="btn-rmv2" />

              {imageLoader === true ? (
                <div class="progress">
                  <span class="progress-bar" style={{ width: '100%' }}></span>
                </div>
              ) : null}
            </div> */}

            <UploadImage
            formData={formData}
            columnName={columnName}
            fileName={fileName}
            // labeltxt={labeltxt}
            buttonName='Upload proof of account'
            // infobanks={infobanks}
            // BankInfo="BankInfo"
            showIcon={true}
            inform={infobanks}
            bankcls={true}
            valuedefine="bank"
          />
          </>
           {/* <img src={info} onClick={infobanks} className="info backi" alt="" /> */}
          <span className=" error document2"></span>

          <div>
            {isNZBank12 === 'false' ? (
              <>
                <Row className="">
                  <Col md={8}>
                    <label>Is this a New Zealand bank account?</label>
                  </Col>
                  <Col xs={12}>
                    <div className="button_group space-bottom">
                      <div class="switch-field nospace">
                        <div className="check_YN_Bank">
                          <input
                            type="radio"
                            id="radio-two2"
                            name="isNZBank2"
                            value="false"
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                          />
                          <label
                            for="radio-two2"
                            className={`toogles postalAddressButtonssecond ${isNZBank2 === 'false' ? 'actives' : null
                              }`}
                            id="show2"
                          >
                            No
                          </label>
                          <input
                            type="radio"
                            id="radio-one2"
                            className="yesno"
                            name="isNZBank2"
                            value="true"
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                          />
                          <label
                            for="radio-one2"
                            className={`toogles postalAddressButtonsecond ${typeof isNZBank2 === 'undefined' || isNZBank2 === 'true'
                                ? 'actives'
                                : null
                              }`}
                            id="hide2"
                          >
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <div className="col-md-12">
                    {isNZBank2 === 'true' ||
                      isNZBank2 === null ||
                      typeof isNZBank2 === 'undefined' ? (
                      <div className="row">
                        <Col xs={12}>
                          <ItemForm
                            label="Bank account name "
                            className="quantity"
                            name="primaryBankAccountName2"
                            value={primaryBankAccountName2}
                            onPaste={handleChange}
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                            type="text"
                            placeholder="Enter account name"
                            onKeyPress={removeError}
                          />
                          <span className="error primaryBankAccountName2 accountName"></span>
                          <label>Bank account number</label>
                          <InputMask
                            mask="99-9999-9999999-999"
                            maskChar={null}
                            maskPlaceholder={null}
                            className="numbers"
                            name="primaryBankAccountNumber2"
                            type=""
                            value={primaryBankAccountNumber2}
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                            placeholder="xx-xxxx-xxxxxxx-xxx"
                            onKeyPress={removeError}
                          />
                          <span className="error primaryBankAccountNumber2 accountNumber"></span>
                        </Col>
                      </div>
                    ) : (
                      <div className="row">
                        <Col xs={12}>
                          <label>Bank account name</label>
                          <ItemForm
                            className="quantity"
                            name="primaryBankAccountName2"
                            onPaste={handleChange}
                            type="text"
                            value={primaryBankAccountName2}
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                            onKeyPress={removeError}
                            placeholder="Enter account name"
                          />
                          <span className="error primaryBankAccountName2 accountName"></span>
                          <label>Bank swift code</label>
                          <ItemForm
                            className="digitsOnly"
                            type=""
                            maxLength={16}
                            name="bankSwiftCode2"
                            value={bankSwiftCode2}
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                            placeholder="Enter swift code"
                            onKeyPress={removeError}
                          />{' '}
                          <span className="error bankSwiftCode2"></span>
                          <label>Account/IBAN Number</label>
                          <ItemForm
                            maxlength="35"
                            className="numbers"
                            type="text"
                            name="primaryBankAccountNumber2"
                            value={primaryBankAccountNumber2}
                            onChange={(e) => {
                              setForm(e);
                              removeError();
                            }}
                            placeholder="Enter account number"
                            onKeyPress={removeError}
                          />
                          <span className="error primaryBankAccountNumber2 accountNumber"></span>
                        </Col>
                      </div>
                    )}
                  </div>

                  {/* <Col xs={12}>
  
      <Search   placeholder="Enter account name"/>
  
      </Col> */}
                </Row>
                {/* <Row className="fullinput">
                  <Col xs={12}>
                  <div class="yes backbt">
                  <button className="uploadBtn">
                    <span class="btn_upload bacnkbtn">
                    <input
                            type="file"
                            id="imag2"
                            title=""
                            value={document}
                            onChange={(e) => {
                              uploadWithJSON1(e.target.files[0]);
                              removeError();
                            }}
                            onClick={(e) => (e.target.value = null)}
                          />
                                          Upload proof of account

                    </span>
                  </button>
                  <img src={info} onClick={infobanks} className="info backi" alt="" />

                  
                </div>
                    
                    <div className="Upload-account attach-style-proof">
                <label className="bankClick">  </label>
                <span className="error file ml-0"></span>
              </div>
                  </Col>

                </Row> */}

                {/* <div className="spceimg">
                  {formData.primaryBankProof2 === '' ||
                    formData.primaryBankProof2 === null ||
                    formData.primaryBankProof2 === 'undefined' ||
                    formData.primaryBankProof2 === undefined ? (
                    ''
                  ) : (
                    <a href="#" className="licedocument-downloade" onClick={()=>{
                      downloadImage(formData.primaryBankProof2)
                    }}>
                      {' '}
                      <div className="main-downloade-wrapper">
                        <div className="downloads">
                          <i className="fa fa-download downloadeStyle"></i>
                        </div>
                        <p className="downloadeMsg">View attached file</p>
                      </div>
                    </a>
                  )}
                  <span id="name_tag" class="preview2"></span>
                  <input type="button" id="removeImage2" value="x" class="btn-rmv2" />

                  {imageLoader1 === true ? (
                    <div class="progress">
                      <span class="progress-bar" style={{ width: '100%' }}></span>
                    </div>
                  ) : null}
                </div> */}

<UploadImage
            formData={formData}
            columnName={columnName2}
            fileName={fileName2}
            // labeltxt={labeltxt}
            buttonName='Upload proof of account'
            showIcon={true}
            inform={infobanks}
            bankcls={true}
            valuedefine="bank2"
          />
              </>
            ) : (
              ''
            )}
          <span className=" error document3"></span>
          </div>

          {/* <div className="spceimg">
            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
          </div> */}


          <div className="fotter-btn">
            <button className="preview" onClick={Onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next" onClick={handleClick2}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Four;
