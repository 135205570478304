/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
import axios from 'axios';
import Swal from 'sweetalert2';
import logo from '../logo.png';
import $ from 'jquery';
import info from '../info.png';
import swal from 'sweetalert';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import MenuSidebar from '../Individual/MenuSidebar';
import BiometricOption from '../BiometricOption';
import { Biometricverify, multisavedata } from 'src/auth/Configuration';
import { infodetls } from '../Individual/SaveInfo';

// const { useState } = React;

const Eighteen = ({ setForm, formData, navigation, secondholder, saveAndExist,idinfo, func }) => {
  const {biometric2} = formData;
  const [namefull1, setNamefull1] = useState(formData.secondholder ? formData.secondholder.trim().split(' ') : '');
  // let text2 = ['world!', 'ny', 'jjij'];
  // let result = text2[-1];
  if (secondholder != null && secondholder != "" && secondholder != "undefined") {
    formData.firstNameSec = namefull1[0];

    if (namefull1.length > 1) {
      formData.lastNameSec = namefull1[namefull1.length - 1];
    }

    if (namefull1.length > 2) {
      formData.middleNameSec = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }



  const { next } = navigation;

  const onprevious = () => {
    if (formData.biometric === "Biometric") {
      formData.class = 'Five';
      go("Jointeight")
    }
    else {
      formData.class = 'Five';
      go("Jointnine")
    }

  }
  let licdatee = formData.licenceExpiryDate;
  let pasdatee = formData.passportExpiryDate;
  let otherexpDate = formData.documentExpiryDate;

  const [state, setState] = useState({ shown: true });
  const [imageLoader, setImageLoader] = useState();
  useEffect(() => {
    var sdc = localStorage.getItem('licenceExpiryDate');
    if (typeof sdc !== 'object' && sdc !== '' && sdc !== null && sdc !== 'null') {
      $('.ui.icon.input>input').val(sdc);
    }

    var sdc1 = localStorage.getItem('passportExpiryDate');
    if (typeof sdc1 !== 'object' && sdc1 !== '' && sdc1 !== null && sdc1 !== 'null') {
      $('.ui.icon.input.counrtyselext7>input').val(sdc1);
    }

    let dataStr = sessionStorage.getItem('allData');

    let dataObj = JSON.parse(dataStr);
    function setDateformate(date) {
      if (
        date !== null &&
        date !== 'undefined' &&
        typeof date !== 'undefined' &&
        date.includes('-')
      ) {
        let dat = date.split('-');
        dat = dat[2] + '/' + dat[1] + '/' + dat[0];
        return dat;
      }
      return date;
    }
    if (licdatee !== null && licdatee !== 'undefined' && typeof licdatee !== 'undefined') {
      $('.ui.icon.input.counrtyselext6>input').val(setDateformate(licdatee));
    }
    if (pasdatee !== null && pasdatee !== 'undefined' && typeof pasdatee !== 'undefined') {
      $('.ui.icon.input.counrtyselext7>input').val(setDateformate(pasdatee));
    }
    if (
      otherexpDate !== null &&
      otherexpDate !== 'undefined' &&
      typeof otherexpDate !== 'undefined'
    ) {
      $('.ui.icon.input.counrtyselext8>input').val(setDateformate(otherexpDate));
    }

    $(function () {
      $('#colorselector')
        .change(function () {
          var select = $(this).find(':selected').val();
          $('.hide').hide();
          $('#' + select).show();
        })
        .change();
    });

    $('.flagsave').click(function () {
      let passportCountryOfIssue = $('.counrtyselext3 #select_flag_button > span > span').text();
      localStorage.setItem('passportCountryOfIssue', passportCountryOfIssue);

      let documentCountryOfIssue = $('.counrtyselext4 #select_flag_button > span > span').text();
      localStorage.setItem('documentCountryOfIssue', documentCountryOfIssue);
    });
  });
  const [loader, setLoader] = useState();
  let BioData = {
    reference: "IDscan reference 101",
    callback: window.location.href + "/idscan/complete",
    // callback:"https://www.duvalgroup.co.nz/",
    // callback:"https://duvalapp.invsta.io/",
    notificationUrl: "https://mysite.com/id-notify?id=001"

  }

  const saveandexit = () => {
    func();
  };
  const BiometricVerification = async () => {
    setImageLoader(true);
    const Bio = await axios.post(`${Biometricverify}/verify/identity/idscan`, BioData)
      .then((Bio) => {
        if (Bio.status === 200) {
          let hous = "2"


          formData.Capture2 = Bio.data.token
          func(hous);

          let postdata = {
            secondaryDetails: {
              captureReference: formData.Capture2 != null ? formData.Capture2 : "",
              identificationType: "Biometric",
              userEmail: formData.userEmail === formData.email1 ? formData.userCode : formData.email1 != null && formData.email1 != "" && formData.email1 != "undefined" ? formData.email1 : formData.userCode,
            }

          }
          axios
            .put(`${multisavedata}/onboard/joint`, postdata, {
              headers: { Authorization: `Bearer ${formData.token}` }
            })
            .then((res) => {
              if (res.status === 200) {
                setImageLoader(false);
                window.open(Bio.data.link);
                formData.class = 'Eight'
                go("Jointtwelve")
                setLoader(false);
              } else {
                setLoader(false);
              }
            })
            .catch(() => {
              setImageLoader(false);
              setLoader(false);
              Swal.fire({
                // icon: 'error',
                // title: 'Oops...',
                text: 'Something went wrong!'
              });
            });



        }



      })

  }



  function handleClick(e) {
    if (formData.biometric2 === "Biometric") {

      if (formData.biometricComplete1 != true) {
        formData.bioVerify = true
        BiometricVerification();
        formData.repeatOff2 = false
      }
      else {
        // formData.bioVerify = true
        formData.class = 'Eight'
        go("Jointtwelve")
      }



    }
    else if (formData.biometric2 === "document") {
      formData.biometricComplete1 = false
      next();
    }
    else {
      $('.IdentityError').html('Please Select Identity');

    }




  }
  function removeError() {
    $('.error').html('');
  }



  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '5';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };
 
  const { go } = navigation;
  idinfo = () => {
    swal({
      text: 'MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify and record information about your identity. '
    });
  };

  function setDateformateToValid(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
  }

  $(function () {
    $('#colorselector')
      .change(function () {
        var select = $(this).find(':selected').val();
        $('.hide').hide();
        $('#' + select).show();
      })
      .change();
  });

  $('.flagsave').click(function () {
    let passportCountryOfIssue = $('.counrtyselext3 #select_flag_button > span > span').text();
    localStorage.setItem('passportCountryOfIssue', passportCountryOfIssue);

    let documentCountryOfIssue = $('.counrtyselext4 #select_flag_button > span > span').text();
    localStorage.setItem('documentCountryOfIssue', documentCountryOfIssue);
  });
  const inform = () => {
    Swal.fire({
      //text: "Certified copies must be stamped or endorsed as true copies of the originals by a person authorized by law to take statutory declarations. This maybe a Lawyer, Notary Public, Justice of Peace or Court Official. ",
      html: '<p class="sizeText">Certified copies must be stamped or endorsed as true copies of the originals by a person authorized by law to take statutory declarations. This maybe a Lawyer, Notary Public, Justice of Peace or Court Official.</p>'
    })

  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Validation Star
    if (formData.check3 === false) {
      if (
        formData.biometric2 === 'Select' ||
        formData.biometric2 === undefined ||
        formData.biometric2 === '' ||
        formData.biometric2 === null
      ) {
        $('.IdentityError').html('Please Select Identity');
      }
    }

    // Validation End
  })

  return (
    <><MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2> Step 4: Identity Verification - {(formData.firstNameSec != null && formData.firstNameSec != "" && formData.firstNameSec != "undefined" && formData.firstNameSec != undefined ? formData.firstNameSec : "") + " " + (formData.lastNameSec != null && formData.lastNameSec != "" && formData.lastNameSec != "undefined" && formData.lastNameSec != undefined ? formData.lastNameSec : "")}</h2>
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit  se_invff joint-holder">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{" "}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>




          <div className="note_text">
            <div>
              <p>There are two ways you can verify your identity:</p>
              <ol className='change_option_title'>
                <b>Option 1</b>
                <li>Use an online biometrics process, where you will be required to take a photo of yourself and of your identification. 
                 <li>To use the biometrics process, your identification must be either a New Zealand Passport or a New Zealand Driver's Licence, and you will need to use your mobile phone to take these photos and complete this process.</li>
                  <i><b>Note:</b> If you do not have either a New Zealand Passport or a New Zealand Driver's Licence, or do not have a mobile phone that can take selfie photos, please select Option 2.<br/><br/></i>
                </li>
                <b>Option 2</b>
                <li>Upload a copy of your Passport or a copy of your New Zealand Driver's Licence.
                  {/* <img src={info} onClick={inform} className="fffff" /> */}
                </li>

              </ol>
            </div>

            <div>
              <label>Please select how you’d like to verify your identity.</label>

              <div className="button dropdown fullinput">
                <BiometricOption
                  autoComplete="off"
                  type="text"
                  className={biometric2 === "Select" || biometric2 === undefined || biometric2 === "" || biometric2 === null ? "colorgrey" : "colorblack"}
                  onClick={removeError}
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                  name="biometric2"
                  value={formData.biometric2} />
                <span className="error IdentityError"></span>
              </div>
            </div>
          </div>
          <br />
          <div className="fotter-btn">
            <button className="preview" onClick={onprevious}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ?
              <button className=" flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
              :
              <button type="submit" className="next flagsave" onClick={handleClick}  >Continue<img src={rightarrow} className="rightarrow" alt="" /> </button>

            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Eighteen;


