/*eslint eqeqeq: "off"*/
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {audience, duvalapi } from '../../auth/Configuration';

const Zero = ({ setForm, formData, navigation, saveAndExist, saveAndExists, logInButtons }) => {
  const { go } = navigation;

  console.log('formData.companyShareholderDetails', formData.companyShareholderDetails);
  const {
    user,

    getIdTokenClaims,
    getAccessTokenSilently,
    isAuthenticated,
    logout
  } = useAuth0();

  formData.investmentType = '';

  const [userapplication1, setUserapplication1] = useState([]);
  // const [taxotherdetails, setTaxotherdetails] = useState();
  // const [tinnumber, setTinnumber] = useState();
  // const [tinreason, setTinreason] = useState();
  formData.contactNumber = userapplication1;

  function check() {
    user.profile != 'User' ? logout() : application();
  }

  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          formData.token = accessidToken.__raw;
          console.log(formData.token)
         
            check();
          
      
        } else {
        }
      }
    } catch (e) { }
  };





  useEffect(() => {
    getUserMetadata();
  }, []);

  const [adminapplication, setAdminapplication] = useState();

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  useEffect(() => { }, [adminapplication]);

  const [dualData, setDualData] = useState('');
  const [dualData1, setDualData1] = useState('');
  const [dualJoint, setDualJoint] = useState(null);
  // console.log("dualData1dualData1dualData1",dualData)

  formData.userCode = dualData.userCode;

  sessionStorage.setItem(
    'documentExpiryDates',
    dualData !== null
      ? dualData.documentExpiryDate == null
        ? ''
        : dualData.documentExpiryDate
      : ''
  );
  sessionStorage.setItem(
    'documentExpiryDates1',
    dualData !== null
      ? dualData.documentExpiryDate == null
        ? ''
        : dualData.documentExpiryDate
      : ''
  );
  sessionStorage.setItem(
    'dateOfBirth',
    dualData !== null ? (dualData.dateOfBirth == null ? '' : dualData.dateOfBirth) : ''
  );
  sessionStorage.setItem(
    'residentialAddress1',
    dualData !== null
      ? dualData.isPrimaryResdAddress == null
        ? ''
        : dualData.userResidentialAddress
      : ''
  );
  sessionStorage.setItem(
    'PostalAddress1',
    dualData !== null ? (dualData.userPostalAddress == null ? '' : dualData.userPostalAddress) : ''
  );
  sessionStorage.setItem(
    'street_number',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].addressLine1
          : ''
      : ''
  );
  sessionStorage.setItem(
    'locality',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].city
          : ''
      : ''
  );
  sessionStorage.setItem(
    'country',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].country
          : ''
      : ''
  );
  sessionStorage.setItem(
    'postal_code',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].postalCode
          : ''
      : ''
  );
  sessionStorage.setItem(
    'sublocality_level_1',
    dualData !== null
      ? dualData.addressDetails == null
        ? ''
        : dualData.addressDetails[0] != null
          ? dualData.addressDetails[0].suburb
          : ''
      : ''
  );
  formData.applicationStage=dualData !== null && dualData.applicationStage;

  formData.submissionStatus = dualData !== null ? dualData.submissionStatus : '';
  formData.fullName =
    dualData !== null && dualData !== 'undefined' && dualData !== '' && dualData !== ''
      ? dualData.fullLegalName
      : '';
  formData.investmentType = dualData !== null ? dualData.investmentType : '';
  formData.fundCode = dualData !== null ? dualData.fundCode : '';
  formData.totalInvestment = dualData !== null ? dualData.totalInvestment : '';
  formData.documentProof1 = dualData !== null ? dualData.documentIdentificationProof : '';
  formData.documentProof21 = dualData !== null ? dualData.documentIdentificationProofSecondary : '';
  formData.userEmail = dualData != null ? dualData.userEmail : '';
  formData.userCode = dualData != null ? dualData.userCode : '';
  // formData.applicationStep = dualData != null ? dualData.applicationStep : '';
  formData.shareholdingPercentage =
    dualData != null
      ? dualData.primaryShareOfIncome != null
        ? dualData.primaryShareOfIncome
        : 50
      : 50;
  // formData.shareincome = dualJoint !== null ? dualJoint.fullLegalName : '';

  // formData.applicationStep = dualData != null ? dualData.applicationStep : '';

  formData.investorRelation =
    dualData !== null ? (dualData.investorRelation == null ? '' : dualData.investorRelation) : '';
  formData.middleName =
    dualData !== null ? (dualData.middleName == null ? '' : dualData.middleName) : '';
  formData.preferredName = dualData !== null ? dualData.preferredName : '';
  formData.dateOfBirth = dualData !== null ? dualData.dateOfBirth : '';
  formData.isPrimaryResdAddress = dualData !== null ? dualData.userResidentialAddress : '';
  formData.isUserAddressSame =
    dualData !== null ? (dualData.isUserAddressSame === 1 ? 'true' : 'false') : '';
  formData.isPrimaryPostalAdress = dualData !== null ? dualData.userPostalAddress : '';

  
  formData.isAlternateContact =
    dualData != 'undefined' && dualData !== null
      ? dualData.isAlternateContact == 0
        ? false
        : true
      : '';
      formData.futureInvestementOpportunities =
      dualData != 'undefined' && dualData !== null
        ? dualData.futureInvestementOpportunities == 0
          ? false
          : true
        : '';
      
  // formData.residentialAddress1= dualData !== null ? dualData.userResidentialAddress : '';

  formData.manuallyAddress =
    dualData !== null ? (dualData.isGoogleAddress == 1 ? 'true' : 'false') : '';

  formData.isMyFarm = dualData !== null && dualData !== undefined && dualData !== "undefined" && dualData !== ""
    ? (dualData.isMyFarm == 1 ? 'true' : 'false') : '';

  formData.street_number =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].addressLine1
      : null;
  formData.city =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].city
      : null;
  formData.country =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].country
      : null;
  formData.postalCode =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].postalCode
      : null;
  formData.region =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].suburb
      : null;
  formData.countryyCode =
    dualData != 'undefined' && dualData !== null && dualData.addressDetails != null
      ? dualData.addressDetails[0].countryCode
      : null;

  formData.residentialAddress1 = '';
  formData.priContactType = dualData !== null ? dualData.priContactType : ' Mobile';
  formData.priContactCountryCode =
    dualData !== null && dualData.priContactCountryCode !== null
      ? dualData.priContactCountryCode
      : '64';
  formData.priContactNumber = dualData !== null ? dualData.priContactNumber : '';
  formData.codeVerify =
    dualData !== null ? (dualData.isPriContactNumberVerified == 0 ? 'false' : 'true') : 'false';
  formData.secContactType = dualData !== null ? dualData.secContactType : '';
  formData.secContactCountryCode =
    dualData !== null && dualData.secContactCountryCode !== null
      ? dualData.secContactCountryCode
      : '64';

  formData.secContactNumber = dualData !== null ? dualData.secContactNumber : '';

  formData.intendToInvest = dualData !== null ? dualData.intendToInvest : '';
  formData.otherIntendToInvest = dualData !== null ? dualData.otherIntendToInvest : '';
  formData.totalIintend = dualData !== null ? dualData.totalIintend : '';
  formData.chooseToInvest = dualData !== null ? dualData.chooseToInvest : '';
  formData.otherChooseToInvest = dualData !== null ? dualData.otherChooseToInvest : '';
  formData.occupation = dualData !== null ? dualData.occupation : '';
  formData.sourceOfFund = dualData !== null ? dualData.sourceOfFund : '';


  // formData.occupation = dualData !== null ? dualData.occupation : '';
  // formData.otherOccupation = dualData !== null ? dualData.otherOccupation : '';
  formData.placeOfBirth = dualData !== null ? dualData.placeOfBirth : '';
  formData.countryOfBirth = dualData !== null ? dualData.countryOfBirth : 'NZ';
  formData.residingCountry = dualData !== null ? dualData.residingCountry : 'NZ';
  formData.userIrdNumber = dualData !== null ? dualData.userIrdNumber : '';
  formData.rwt =
    dualData !== null
      ? dualData.taxType
      : '';
  formData.rwtValue = dualData !== null ? dualData.rwtValue : '';
  formData.checkboxx = dualData !== null ? (dualData.taxExemption === 1 ? true : false) : '';
  formData.examptProof = dualData !== null ? dualData.examptProof : '';
  formData.isPostalAdd1 =
    dualData != 'undefined' && dualData !== null && dualData.isOtherCitizen == 0 ? 'true' : 'false';
  formData.tinDetails =
    dualData != 'undefined' && dualData !== null && dualData.tinDetails != null
      ? dualData.tinDetails[0].id
      : null;
  formData.tinId =
    dualData != 'undefined' && dualData !== null && dualData.tinDetails != null
      ? dualData.tinDetails[0].id
      : '';

  formData.tinResidential =
    dualData != 'undefined' && dualData !== null && dualData.tinDetails != null
      ? dualData.tinDetails[0].countryOfResidence
      : '';
  formData.tinNumber =
    dualData != 'undefined' && dualData !== null && dualData.tinDetails != null
      ? dualData.tinDetails[0].tinNumber
      : '';
  formData.tinreason =
    dualData != 'undefined' && dualData !== null && dualData.tinDetails != null
      ? dualData.tinDetails[0].tinReason
      : '';

  // for individual

  formData.biometric = dualData !== null ? dualData.identificationType : '';
  formData.idType =
    dualData !== null
      ? dualData.documentIdType === 'NZ Driver Licence'
        ? '1'
        : dualData.documentIdType === 'NZ Passport'
          ? '2'
          : dualData.documentIdType === 'other'
            ? '3'
            : ''
      : '';
  formData.documentNumber = dualData !== null ? dualData.documentNumber : '';
  formData.documentExpiryDate = dualData !== null ? dualData.documentExpiryDate : '';
  formData.documentVersionNumber = dualData !== null ? dualData.documentVersionNumber : '';
  formData.documentCountryOfIssue = dualData !== null ? dualData.documentCountryOfIssue : '';
  formData.documentProof = dualData !== null ? dualData.documentProof : '';
  formData.otherDocumentName = dualData !== null ? dualData.otherDocumentName : '';

  // for individual
  formData.isNZBank = dualData !== null ? (dualData.isNZBank === 0 ? 'false' : 'true') : '';
  formData.primaryBankAccountName = dualData !== null ? dualData.primaryBankAccountName : '';
  formData.primaryBankAccountNumber = dualData !== null ? dualData.primaryBankAccountNumber : '';
  formData.bankSwiftCode = dualData !== null ? dualData.bankSwiftCode : '';
  formData.primaryBankProof = dualData !== null ? dualData.primaryBankProof : '';

  // formData.twoYearInvestEntity = dualData !== null && dualData.certificateDetails !== null
  //   && dualData.certificateDetails !== "undefined" && dualData.certificateDetails !== undefined
  //   ? dualData.certificateDetails.largePersonValue == "None" ? "None"
  //     : dualData.certificateDetails.largePersonValue : null;

  formData.largePersonValue =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.largePersonValue ==
        "As at the last day of each of the Investor's two most recently completed financial years, the net assets of the Investor and the entities controlled by the Investor exceed $5 million."
        ? true
        : false
      : false;

  formData.twoYearInvestEntity2 =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.twoYearInvestEntity2 ==
        "In each of the Investor's two most recently completed financial years, the total consolidated turnover of the Investor and the entities controlled by the Investor exceeded $5 million."
        ? true
        : false
      : false;

  formData.investorPrincipalBusiness =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.investorPrincipalBusiness ==
        'The Investor’s principal business consists of one or more of the following (tick all that apply)'
        ? true
        : false
      : false;

  formData.investingFinancialProducts =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.investingFinancialProducts == 'Investing in financial products'
        ? true
        : false
      : false;

  formData.actingUnderwriter =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.actingUnderwriter == 'Acting as an underwriter'
        ? true
        : false
      : false;

  formData.financialAdviceService =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.financialAdviceService == 'Providing a financial advice service'
        ? true
        : false
      : false;

  formData.clientMoneyPropertyService =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.clientMoneyPropertyService ==
        'Providing a client money or property service'
        ? true
        : false
      : false;

  formData.tradingFinancialProducts =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.tradingFinancialProducts ==
        'Trading in financial products on behalf of other persons.'
        ? true
        : false
      : false;

  formData.registeredBankRBNZ =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.registeredBankRBNZ ==
        'The Investor is a registered bank under the Reserve Bank of New Zealand Act 2010.'
        ? true
        : false
      : false;

  formData.NBDTInvestor =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.NBDTInvestor ==
        'The Investor is an NBDT under the Non-Bank Deposit Takers Act 2013.'
        ? true
        : false
      : false;

  formData.licensedIssuerInsurance =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.licensedIssuerInsurance ==
        'The Investor is a licensed issuer under the Insurance (Prudential Supervision) Act 2010.'
        ? true
        : false
      : false;

  formData.registeredSchemeManager =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.registeredSchemeManager ==
        'The Investor is the manager of a registered scheme, or a discretionary investment management service, that holds a market services licence under the Financial Markets Conduct Act 2013.'
        ? true
        : false
      : false;

  formData.derivativesIssuer =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.derivativesIssuer ==
        'The Investor is a derivatives issuer that holds a market services license under the Financial Markets Conduct Act 2013.'
        ? true
        : false
      : false;

  formData.FinancialAdvisor =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.FinancialAdvisor == 'The Investor is a Financial Adviser'
        ? true
        : false
      : false;

  formData.ownedPortfolio2yrs =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.ownedPortfolio2yrs ==
        'The investor owns, or at any time during the 2-year period immediately before this certificate is given has owned, a portfolio of financial products (other than membership of a retirement scheme or financial products issued by an associate) of a value of at least $1 million (in aggregate).'
        ? true
        : false
      : false;

  formData.carriedTransactions2yrs =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.carriedTransactions2yrs ==
        'The investor has, during the 2-year period immediately before this certificate is given, carried out one or more transaction(s) to acquire financial products (other than membership of a retirement scheme or financial products issued by an associate) where the amount payable under those transactions (in aggregate) is at least $1 million.'
        ? true
        : false
      : false;

  formData.investmentBusiness10yrs =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.investmentBusiness10yrs ==
        'The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business.'
        ? true
        : false
      : false;

  formData.equitySecurities =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.equitySecurities == 'Equity securities (includes listed shares)'
        ? true
        : false
      : false;

  formData.managedFunds =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.managedFunds == 'Managed funds'
        ? true
        : false
      : false;

  formData.derivativeInstruments =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.derivativeInstruments == 'Derivative instruments'
        ? true
        : false
      : false;

  formData.debtSecurities =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.debtSecurities == 'Debt securities'
        ? true
        : false
      : false;

  formData.startupOrBusiness =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.startupOrBusiness ==
        'I/We previously invested in early Step, start up, or established businesses'
        ? true
        : false
      : false;

  formData.investorPresentation =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.investorPresentation ==
        'In the course of it’s investment activities, I/We have been involved in investor presentations and/or due diligence meetings and through this have gained an appreciation for the risks and opportunities associated with investing in businesses.'
        ? true
        : false
      : false;

  formData.myOwnInvestmentDecisions =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.myOwnInvestmentDecisions ==
        'I/We currently hold a diverse portfolio of investments. I/We regularly review my investment portfolio, with the benefit of advice from relevant professionals where appropriate, and make my own investment decisions in relation to my portfolio.'
        ? true
        : false
      : false;

  formData.valueAndRisksOfInvesting =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.valueAndRisksOfInvesting ==
        'In the course of my investment activities, I/We have been exposed to a wide range of investment analysis and commentary and this has helped me build up my knowledge and skills in assessing the value and risks of investing in financial products.'
        ? true
        : false
      : false;

  formData.capitalRaisingDirector =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.capitalRaisingDirector ==
        'I/We have served as a Director of a business that was involved in capital raising'
        ? true
        : false
      : false;

  formData.investingSharesSecurities =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.investingSharesSecurities ==
        'I/We have experience in investing in shares and other securities (listed and unlisted) and understand the risks with investing in different types of financial products.'
        ? true
        : false
      : false;

  formData.acquiringDisposingFinancialProducts =
    dualData !== null &&
      dualData.certificateDetails !== '' &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.acquiringDisposingFinancialProductsOther != null &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != 'undefined' &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != '' &&
        dualData.certificateDetails.acquiringDisposingFinancialProductsOther != undefined
        ? true
        : false
      : false;

  formData.acquiringDisposingFinancialProductsOther =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.acquiringDisposingFinancialProductsOther
      : null;

  formData.investmentBusinessNone =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.none_certificate
      : false;

  formData.investmentCriteriaNone =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.none_certificate_1
      : false;

  formData.largePersonNone =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.largePersonNone
      : false;

  formData.isMinimumInvest =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.isMinimumInvest == 1
        ? 'Yes'
        : 'No'
      : false;

  formData.fewerLegalProtection =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.fewerLegalProtection
      : null;

  /////

  formData.usualLegalRules =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.usualLegalRules ==
        'The usual legal rules that require information to be given to investors for offers of financial products do not apply if the amount invested upfront by me (plus any other investments I/We have already made in those financial products) is $750,000 or more; and'
        ? true
        : false
      : false;

  formData.notCompleteInformation =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.notCompleteInformation ==
        'I/We may not receive a complete and balanced set of information about this investment; and'
        ? true
        : false
      : false;

  // formData.materialExtent10yrs = dualData !== null && dualData.certificateDetails !== null
  // && dualData.certificateDetails !== "undefined" && dualData.certificateDetails !== undefined
  // ? dualData.certificateDetails.materialExtent10yrs == "The Investor is an individual who has, within the last ten years immediately before this certificate is given, been employed or engaged in an investment business and has, for at least two years during that ten-year period, participated to a material extent in the investment decisions made by the investment business."?true:false: false;

  formData.fewerLegalProtection =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.fewerLegalProtection ==
        'I/We have fewer legal protections for this investment; and'
        ? true
        : false
      : false;

  formData.notSuitableInvestment =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.notSuitableInvestment ==
        'This investment is not suitable for retail investors; and'
        ? true
        : false
      : false;

  formData.seekFinancialAdvice =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.seekFinancialAdvice ==
        'I/We have been advised to ask questions, read all documents carefully, and seek independent financial advice.'
        ? true
        : false
      : false;

  formData.rwt12 =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.nominatedUserType
      : null;

  formData.fullLegalNamesign =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.nominatedUserName
      : null;

  formData.userEmail1 =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.nominatedUserEmail
      : null;

  formData.isNZBank12 =
    dualData !== null
      ? dualData.isPrimaryBank != null
        ? dualData.isPrimaryBank == 1
          ? 'true'
          : 'false'
        : 'true'
      : 'true';
  formData.certificateNumber =
    dualData !== null &&
      dualData.certificateDetails !== null &&
      dualData.certificateDetails !== 'undefined' &&
      dualData.certificateDetails !== undefined
      ? dualData.certificateDetails.certificateNumber
      : null;
  formData.Capture = dualData !== null ? dualData.captureReference : '';

  formData.step = dualData !== null ? dualData.step : 0;
  formData.isPrimary =
    dualData != null && dualData != '' && dualData != 'undefined'
      ? dualData.isPrimary == 1
        ? true
        : false
      : '';

  if (formData.investmentType === 'Company') {
    // company Details:
    // formData.is_in_relation_with_main = duvalData != "" && duvalData != null && duvalData != "undefined" && duvalData != "" ?

    //   duvalData[1] != null && duvalData[1] != "" && duvalData[1] != "undefined" ? duvalData[0].user_email == (user.email) ? false : true : ""
    //   : ""
    //  formData.isPrimary=dualData1 != null && dualData1 != ""&& dualData1 !="undefined"?dualData1.isPrimary==1?true:false:""
    formData.companyName =
      dualData1 !== null ? (dualData1.companyName == null ? '' : dualData1.companyName) : '';
    formData.companyNumber =
      dualData1 !== null ? (dualData1.companyNumber == null ? '' : dualData1.companyNumber) : '';

    formData.companyTradingName =
      dualData1 !== null
        ? dualData1.companyTradingName == null
          ? ''
          : dualData1.companyTradingName
        : '';
    formData.companyRegisterAddress =
      dualData1 !== null
        ? dualData1.companyRegisterAddress == null
          ? ''
          : dualData1.companyRegisterAddress
        : '';
    formData.companyIrdNumber =
      dualData1 !== null
        ? dualData1.companyIrdNumber == null
          ? ''
          : dualData1.companyIrdNumber
        : '';
    formData.companyAddressProof =
      dualData1 !== null
        ? dualData1.companyAddressProof == null
          ? ''
          : dualData1.companyAddressProof
        : '';
    formData.fullLegalCompanyName =
      dualData1 !== null
        ? dualData1.fullLegalCompanyName == null
          ? ''
          : dualData1.fullLegalCompanyName
        : '';
    formData.entityCompanyOne =
      dualData1 !== null
        ? dualData1.entityCompanyOne != null
          ? dualData1.entityCompanyOne == 1
            ? 'false'
            : 'true'
          : 'false'
        : 'true';

    formData.isHoldingPersonalAssets =
      dualData1 !== null
        ? dualData1.isHoldingPersonalAssets != null
          ? dualData1.isHoldingPersonalAssets == 1
            ? 'false'
            : 'true'
          : 'false'
        : 'true';
    formData.isCompanyIndividualOperating =
      dualData1 !== null
        ? dualData1.isCompanyIndividualOperating != null
          ? dualData1.isCompanyIndividualOperating == 1
            ? 'false'
            : 'true'
          : 'false'
        : 'true';
    formData.isOtherCompanyIndividual =
      dualData1 !== null
        ? dualData1.isOtherCompanyIndividual != null
          ? dualData1.isOtherCompanyIndividual == 1
            ? 'false'
            : 'true'
          : 'false'
        : 'true';
    formData.otherCountryCompanyRegistered =
      dualData1 !== null
        ? dualData1.otherCountryCompanyRegistered == null
          ? ''
          : dualData1.otherCountryCompanyRegistered
        : '';
    formData.secFullLegalCompanyName =
      dualData1 !== null
        ? dualData1.secFullLegalCompanyName == null
          ? ''
          : dualData1.secFullLegalCompanyName
        : '';
    formData.companySecCountryOfResidence =
      dualData1 !== null
        ? dualData1.companySecCountryOfResidence == null
          ? ''
          : dualData1.companySecCountryOfResidence
        : '';

    formData.individualFullName =
      dualData1 !== null
        ? dualData1.individualFullName == null
          ? ''
          : dualData1.individualFullName
        : '';
    formData.IndividualRole =
      dualData1 !== null ? (dualData1.IndividualRole == null ? '' : dualData1.IndividualRole) : '';
    formData.IndividualEmail =
      dualData1 !== null
        ? dualData1.IndividualEmail == null
          ? ''
          : dualData1.IndividualEmail
        : '';

    formData.secondaryIndividualFullName =
      dualData1 !== null
        ? dualData1.secondaryIndividualFullName == null
          ? ''
          : dualData1.secondaryIndividualFullName
        : '';
    formData.secondaryIndividualRole =
      dualData1 !== null
        ? dualData1.secondaryIndividualRole == null
          ? ''
          : dualData1.secondaryIndividualRole
        : '';
    formData.SecondaryIndividualEmail =
      dualData1 !== null
        ? dualData1.SecondaryIndividualEmail == null
          ? ''
          : dualData1.SecondaryIndividualEmail
        : '';

    formData.rwtComp =
      dualData1 !== null
        ? dualData1.companyRwt === 1
          ? 'RWT'
          : dualData1.companyNrwt === 1
            ? 'NRWT'
            : dualData1.companyAIL === 1
              ? 'AIL 2%'
              : ''
        : '';
    formData.rwtValueComp = dualData1 !== null ? dualData1.companyRwtValue : '';
    formData.isCompanyOhterCountryCitizen =
      dualData1 != 'undefined' && dualData1 !== null && dualData1.isCompanyOhterCountryCitizen == 0
        ? 'false'
        : 'true';
    formData.checkboxComp =
      dualData1 !== null ? (dualData1.companyTaxExemption === 1 ? true : false) : '';
    formData.companyCountryOfResidence =
      dualData1 !== null
        ? dualData1.companyCountryOfResidence == null
          ? ''
          : dualData1.companyCountryOfResidence
        : '';
    formData.companyTinNumber =
      dualData1 !== null
        ? dualData1.companyTinNumber == null
          ? ''
          : dualData1.companyTinNumber
        : '';
    formData.companyTinReason =
      dualData1 !== null
        ? dualData1.companyTinReason == null
          ? ''
          : dualData1.companyTinReason
        : '';
    //  formData.isCompanyOhterCountryCitizen = dualData1 !== null ? dualData1.isCompanyOhterCountryCitizen == null ? "" : dualData1.isCompanyOhterCountryCitizen : "";
    formData.companyFundReason =
      dualData1 !== null
        ? dualData1.companyFundReason == null
          ? ''
          : dualData1.companyFundReason
        : '';
    formData.companyGenrateIncome =
      dualData1 !== null
        ? dualData1.companyGenrateIncome == null
          ? ''
          : dualData1.companyGenrateIncome
        : '';
    formData.companyFundsDocument =
      dualData1 !== null
        ? dualData1.companyFundsDocument == null
          ? ''
          : dualData1.companyFundsDocument
        : '';

    formData.inputList =
      dualData1 !== null
        ? dualData1.companyShareholderDetails == null
          ? ''
          : dualData1.companyShareholderDetails
        : '';
    formData.inputData =
      dualData1 !== null
        ? dualData1.companyDirectorDetails == null
          ? ''
          : dualData1.companyDirectorDetails
        : '';
  }

  // company Details End:
  console.log('dualDataformData.inputList', formData.inputList);

  //for joint

  if (formData.investmentType === 'Joint') {
    sessionStorage.setItem(
      'documentExpiryDates1',
      dualJoint !== null && dualJoint !== 'undefined' && dualJoint !== undefined
        ? dualJoint.documentExpiryDate == null ||
          dualJoint.documentExpiryDate == 'undefined' ||
          dualJoint.documentExpiryDate == undefined ||
          dualJoint.documentExpiryDate == ''
          ? ''
          : dualJoint.documentExpiryDate
        : ''
    );
    sessionStorage.setItem(
      'dateOfBirth2',
      dualJoint !== null && dualJoint !== undefined && dualJoint !== 'undefined'
        ? dualJoint.dateOfBirth == null
          ? ''
          : dualJoint.dateOfBirth
        : ''
    );
    sessionStorage.setItem(
      'residentialAddress2',
      dualJoint !== null && dualJoint !== 'undefined' && dualJoint !== undefined
        ? dualJoint.userResidentialAddress == null
          ? ''
          : dualJoint.userResidentialAddress
        : ''
    );
    sessionStorage.setItem(
      'PostalAddress2',
      dualJoint !== null && dualJoint !== 'undefined' && dualJoint !== undefined
        ? dualJoint.userPostalAddress == null ||
          dualJoint.userPostalAddress == 'undefined' ||
          dualJoint.userPostalAddress == undefined
          ? ''
          : dualJoint.userPostalAddress
        : ''
    );

    formData.isPostalAdd11 =
      dualData != 'undefined' && dualData !== null && dualData.isOtherCitizen == 0
        ? 'true'
        : 'false';

    sessionStorage.setItem(
      'street_number1',
      dualJoint !== null && dualJoint !== undefined && dualJoint !== 'undefined'
        ? dualJoint.addressDetails == null
          ? ''
          : dualJoint.addressDetails[0] != null
            ? dualJoint.addressDetails[0].addressLine1
            : ''
        : ''
    );
    sessionStorage.setItem(
      'locality1',
      dualJoint !== null && dualJoint !== undefined && dualJoint !== 'undefined'
        ? dualJoint.addressDetails == null
          ? ''
          : dualJoint.addressDetails[0] != null
            ? dualJoint.addressDetails[0].city
            : ''
        : ''
    );
    sessionStorage.setItem(
      'country1',
      dualJoint !== null && dualJoint !== undefined && dualJoint !== 'undefined'
        ? dualJoint.addressDetails == null
          ? ''
          : dualJoint.addressDetails[0] != null
            ? dualJoint.addressDetails[0].country
            : ''
        : ''
    );
    sessionStorage.setItem(
      'postal_code1',
      dualJoint !== null && dualJoint !== 'undefined' && dualJoint !== undefined
        ? dualJoint.addressDetails == null
          ? ''
          : dualJoint.addressDetails[0] != null
            ? dualJoint.addressDetails[0].postalCode
            : ''
        : ''
    );
    sessionStorage.setItem(
      'sublocality_level_11',
      dualJoint !== null && dualJoint !== undefined && dualJoint !== 'undefined'
        ? dualJoint.addressDetails == null
          ? ''
          : dualJoint.addressDetails[0] != null
            ? dualJoint.addressDetails[0].suburb
            : ''
        : ''
    );

    formData.shareholdingPercentage =
      dualData != null
        ? dualData.primaryShareOfIncome != null
          ? dualData.primaryShareOfIncome
          : 50
        : 50;

    formData.secondholder =
      dualJoint !== null && dualJoint !== 'undefined' && dualJoint !== undefined
        ? dualJoint.fullLegalName
        : '';
    formData.documentProof21 =
      dualData !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualData.documentIdentificationProofSecondary
        : '';
    formData.preferredName2 =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.preferredName !== undefined ||
          dualJoint.preferredName !== 'undefined' ||
          dualJoint.preferredName !== null
          ? dualJoint.preferredName
          : ''
        : '';
    formData.dateOfBirth2 =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.dateOfBirth
        : '';
    formData.isSecondryResdAddress =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.userResidentialAddress
        : '';
    formData.isUserAddressSame2 =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.isUserAddressSame == 1
          ? 'true'
          : 'false'
        : '';

    formData.manuallyAddress2 =
      dualJoint !== null && dualData !== undefined && dualData !== 'undefined'
        ? dualJoint.isGoogleAddress == 1
          ? 'true'
          : 'false'
        : '';

    // formData.isMyFarm = dualJoint !== null ? (dualJoint.isMyFarm == 1 ? 'true' : 'false') : '';

    formData.street_number1 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].addressLine1
        : null;
    formData.city1 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].city
        : null;
    formData.country1 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].country
        : null;
    formData.postalCode1 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].postalCode
        : null;
    formData.region1 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].suburb
        : null;
    formData.countryyCode3 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.addressDetails != null
        ? dualJoint.addressDetails[0].countryCode
        : null;

    formData.email1 =
      dualJoint != null && dualData != null
        ? dualJoint.userEmail === dualJoint.userCode
          ? dualData.userEmail
          : dualJoint.userEmail
        : '';
    formData.usercode1 = dualJoint != null ? dualJoint.userCode : '';

    formData.isAlternateContact1 =
      dualJoint != 'undefined' && dualJoint !== null
        ? dualJoint.isAlternateContact == 0
          ? false
          : true
        : '';

  formData.isSecondryPostalAdre = dualJoint !== null ? dualJoint.userPostalAddress : '';

    formData.Capture2 = dualJoint !== null ? dualJoint.captureReference : '';
    formData.priContactType2 = dualJoint !== null ? dualJoint.priContactType : 'Mobile';
    formData.priContactCountryCode2 = dualJoint !== null ? dualJoint.priContactCountryCode : '';
    formData.priContactNumber2 = dualJoint !== null ? dualJoint.priContactNumber : '';
    formData.secContactType2 = dualJoint !== null ? dualJoint.secContactType : '';
    formData.secContactCountryCode2 = dualJoint !== null ? dualJoint.secContactCountryCode : '';
    formData.secContactNumber2 = dualJoint !== null ? dualJoint.secContactNumber : '';

    formData.intendToInvest3 = dualJoint !== null ? dualJoint.intendToInvest : '';
    formData.otherIntendToInvest3 = dualJoint !== null ? dualJoint.otherIntendToInvest : '';
    formData.totalIintend3 = dualJoint !== null ? dualJoint.totalIintend : '';
    formData.chooseToInvest3 = dualJoint !== null ? dualJoint.chooseToInvest : '';
    formData.otherChooseToInvest3 = dualJoint !== null ? dualJoint.otherChooseToInvest : '';
    formData.occupation3 = dualJoint !== null ? dualJoint.occupation : '';
    formData.sourceOfFund3 = dualJoint !== null ? dualJoint.sourceOfFund : '';

    // formData.occupation2 = dualJoint !== null ? dualJoint.occupation : '';
    // formData.otherOccupation2 = dualJoint !== null ? dualJoint.otherOccupation : '';
    formData.placeOfBirth2 = dualJoint !== null ? dualJoint.placeOfBirth : '';
    formData.countryOfBirth2 = dualJoint !== null ? dualJoint.countryOfBirth : 'NZ';
    formData.residingCountry2 = dualJoint !== null ? dualJoint.residingCountry : 'NZ';
    formData.userIrdNumber2 = dualJoint !== null ? dualJoint.userIrdNumber : '';
    formData.rwt2 =
      dualJoint !== null
        ? dualJoint.taxType
        : '';
    formData.rwtValue2 = dualJoint !== null ? dualJoint.rwtValue : '';
    formData.examptProof2 = dualJoint !== null ? dualJoint.examptProof : '';
    formData.isPostalAdd12 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.isOtherCitizen == 0
        ? 'true'
        : 'false';
    formData.checkboxxjoint2 =
      dualJoint !== null ? (dualJoint.taxExemption === 1 ? true : false) : '';
    formData.tinId2 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.tinDetails != null
        ? dualJoint.tinDetails[0].id
        : '';
    formData.tinResidential2 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.tinDetails != null
        ? dualJoint.tinDetails[0].countryOfResidence
        : '';
    formData.tinNumber2 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.tinDetails != null
        ? dualJoint.tinDetails[0].tinNumber
        : '';
    formData.tinReason2 =
      dualJoint != 'undefined' && dualJoint !== null && dualJoint.tinDetails != null
        ? dualJoint.tinDetails[0].tinReason
        : '';

    // for Joint
    formData.Capture2 = dualJoint !== null ? dualJoint.captureReference : '';
    formData.biometric2 = dualJoint !== null ? dualJoint.identificationType : '';
    formData.idType2 =
      dualJoint !== null
        ? dualJoint.documentIdType === 'NZ Driver Licence'
          ? '1'
          : dualJoint.documentIdType === 'NZ Passport'
            ? '2'
            : dualJoint.documentIdType === 'other'
              ? '3'
              : ''
        : '';

    formData.firstName12 =
    dualJoint !== null ? dualJoint.firstName : '';
    formData.lastName12 =
    dualJoint !== null ? dualJoint.lastName : '';
    formData.middleName12 =
    dualJoint !== null ? dualJoint.middleName : '';
   
    formData.documentNumber2 = dualJoint !== null ? dualJoint.documentNumber : '';
    formData.documentExpiryDate2 = dualJoint !== null ? dualJoint.documentExpiryDate : '';
    formData.documentVersionNumber2 = dualJoint !== null ? dualJoint.documentVersionNumber : '';
    formData.documentCountryOfIssue2 = dualJoint !== null ? dualJoint.documentCountryOfIssue : '';
    formData.documentProof2 = dualJoint !== null ? dualJoint.documentProof : '';
    formData.otherDocumentName2 = dualJoint !== null ? dualJoint.otherDocumentName : '';

    //for Joint
    formData.isNZBank12 =
      dualJoint !== null
        ? dualJoint.isPrimaryBank != null
          ? dualJoint.isPrimaryBank == 1
            ? 'true'
            : 'false'
          : 'true'
        : 'true';
    formData.isNZBank2 = dualJoint !== null ? (dualJoint.isNZBank === 0 ? 'false' : 'true') : '';
    formData.primaryBankAccountName2 = dualJoint !== null ? dualJoint.primaryBankAccountName : '';
    formData.primaryBankAccountNumber2 =
      dualJoint !== null ? dualJoint.primaryBankAccountNumber : '';
    formData.bankSwiftCode2 = dualJoint !== null ? dualJoint.bankSwiftCode : '';
    formData.primaryBankProof2 = dualJoint !== null ? dualJoint.primaryBankProof : '';
    formData.certificateNumber =
      dualJoint !== null &&
        dualJoint.certificateDetails !== null &&
        dualJoint.certificateDetails !== 'undefined' &&
        dualJoint.certificateDetails !== undefined
        ? dualJoint.certificateDetails.certificateNumber
        : null;
  }

  const application = async () => {
    if (isAuthenticated && user.email_verified == true) {
      const applicationdata1 = await axios
        .get(`${duvalapi}/user`, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })

        .then((applicationdata1) => {
          sessionStorage.setItem('investorRelation', applicationdata1.data[0].investorRelation);
          setDualData(applicationdata1.data[0] !== null ? applicationdata1.data[0] : null);
          // setDualData1(
          //   applicationdata1.data[0] !== null ? applicationdata1.data[0].companyDetails : null
          // );
          setDualJoint(applicationdata1.data[1] !== null ? applicationdata1.data[1] : null);

          if (formData.step === '5') {
            formData.class = 'Second';

            // go('Eight');
            go('Second');
          } else {
            formData.class = 'Second';

            go('Second');
          }
        })
        .catch((error) => {
          go('Second');
        });
    }
  };

  logInButtons = () => {
    go('First');
  };

  return (
    <div className="main-loader">
      <div className="loader"></div>
    </div>
  );
};

export default Zero;
