
/*eslint eqeqeq: "off"*/
import React, { useEffect } from "react";

import swal from 'sweetalert';

import MenuSidebar from './MenuSidebar';
import { useAuth0 } from '@auth0/auth0-react';
import { infodetls } from './SaveInfo';
import invstaLogo from 'src/pages/invstaLogo.webp';


const Twentytwo = ({ setForm, formData, navigation, saveAndExist,savedata }) => {
  const {
 
    logout
  } = useAuth0();
  const { } = formData;
  // const { previous, next } = navigation;
  const { go } = navigation;

  saveAndExist = () => {
    formData.status ="PENDING"
    formData.step ="17"
    go("submit")
  }
  // infodetls = () => {
  //   swal({
  //     text: "You can save your application data by clicking on the button Save Data. You can then logout, and resume your application at a later Step by clicking on the link we have previously sent in an email to you asking you to verify your email address.",
  //   });
  // }
useEffect(()=>{
  formData.unclick=true
  formData.hidinglogout = false
},[])


  function hadnleclick(){
    sessionStorage.clear();
    localStorage.clear();
    logout();
  
  }
  return (
    <>
     <MenuSidebar navigation={navigation} formData={formData} />
   <div className="main_container">
        <div class="heading__top">
          <h2>Application Submitted</h2>
        </div>
        <div className="form">
        
          <p>
          Thank you for submitting your investment application. We're
            processing your application, and will be in contact if we require anything further.
          </p>
          <br />
       
          <button className="ok" onClick={hadnleclick}>
            LOGOUT
          </button>
          <br />
        </div>
        <div className='invsta_logo'>
            <img src={invstaLogo} alt="logo"/>
          </div>
      </div>
    </>
  );
};
export default Twentytwo;







