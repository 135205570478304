import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import axios from 'axios';
import { multisavedata } from '../../auth/Configuration';
import info from '../info.png';
import swal from 'sweetalert';


const UploadImage = ({ formData, columnName, fileName, labeltxt, inform, showIcon,buttonName,valuedefine,bankcls }) => {
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  let { document } = documentFile64;
  const [imgSize,setimgSize]=useState();


  async function uploadWithJSON(file) {
  
    if ( valuedefine=="bank2") {
      // alert(columnName)
      formData.primaryBankProof2 = '';
    } else if (valuedefine == 'document') {
      formData.documentProof = '';
    } else if (valuedefine == 'document2') {
      formData.documentProof2 = '';
    } else if (columnName == 'companyAddressProof') {
      formData.companyAddressProof = '';
    } else if (columnName == 'companyFundsDocument') {
      formData.companyFundsDocument = '';
    } else if (valuedefine=="bank") {
      // alert(columnName)
      formData.primaryBankProof = '';
    }else if(valuedefine=="exampt"){
      formData.examptProof='';
    }else if(valuedefine=="exampt2"){
      formData.examptProof2='';
    }

    setdocumentFile64(file.name);
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);

        reader.onerror = (error) => reject(error);
      });
     
    const data = {
      img64: await toBase64(file)
    };

    setdocumentFile64(data.img64);
if( file.size >2202009){
  if(valuedefine == "bank2" || valuedefine == "bank" ){
    swal({
      text: 'Your nominated bank account must be in your name, or in the name of both joint investors. Maximum file size is 2MB.'
    });
  } else if (valuedefine == "document" || valuedefine == "document2" ){
    swal({
      text: 'Please attach a copy of your photo identification document (maximum file size is 2MB).'

    });
    

  }
  else if (valuedefine == "exampt" || valuedefine == "exampt2" ){
    swal({
      text: 'Please attach proof of exemption (maximum file size is 2MB).'

    });
    

  }


} else{


    if (
      data.img64 !== null &&
      data.img64 !== '' &&
      data.img64 !== 'undefined' &&
      data.img64 !== undefined 
    ) {

      
      let dataupdate = {
        columnName: columnName,
        fileName: fileName,
        document: data.img64
      };

      setImageLoader(true);
      axios
        .post(`${multisavedata}/genimageurl`, dataupdate, {
          headers: { Authorization: `Bearer ${formData.token}` }
        })
        .then((res) => {
          if (valuedefine=="bank2") {
            formData.primaryBankProof2 = res.data.url;
          } else if (valuedefine == 'document') {
            formData.documentProof = res.data.url;
          } else if (valuedefine == 'document2') {
            formData.documentProof2 = res.data.url;
          } else if (columnName == 'companyAddressProof') {
            formData.companyAddressProof = res.data.url;
          } else if (columnName == 'companyFundsDocument') {
            formData.companyFundsDocument = res.data.url;
          } else if (valuedefine=="bank") {
            formData.primaryBankProof = res.data.url;
          }else if(valuedefine=="exampt"){
            formData.examptProof=res.data.url;
          }else if(valuedefine=="exampt2"){
            formData.examptProof2=res.data.url;
          }

          if (res.status === 200) {
            setImageLoader(false);
          } else {
            setImageLoader(false);
          }
        })
        .catch((error) => {
          setImageLoader(false);
        });
    }
  }
  }

  function removeError() {
    $('.error').html('');
  }

  if (valuedefine=="bank2") {
    formData.ProofValue = formData.primaryBankProof2;
  } else if (valuedefine=="bank") {
    formData.ProofValue = formData.primaryBankProof;

  } else if (valuedefine == 'document') {
    formData.ProofValue = formData.documentProof;
  } else if (valuedefine == 'document2') {
    formData.ProofValue = formData.documentProof2;
  } else if (columnName == 'companyAddressProof') {
    formData.ProofValue = formData.companyAddressProof;
  } else if (columnName == 'companyFundsDocument') {
    formData.ProofValue = formData.companyFundsDocument;
  }else if(valuedefine=="exampt"){
    formData.ProofValue= formData.examptProof;
  }else if(valuedefine=="exampt2"){
    formData.ProofValue=formData.examptProof2;
  }


  const downloadImage = (imageUrl) => {
    let postadv;
    if (valuedefine=="bank2") {
          postadv = {
      downloadUrl: formData.primaryBankProof2
    };
      
    } else if (valuedefine=="bank") {
       postadv = {
        downloadUrl: formData.primaryBankProof
      };
  
    } else if (valuedefine == 'document') {
      postadv = {
        downloadUrl: formData.documentProof
      };
    } else if (valuedefine == 'document2') {
      postadv = {
        downloadUrl: formData.documentProof2
      };
     
    } else if (columnName == 'companyAddressProof') {
      postadv = {
        downloadUrl: formData.companyAddressProof
      };
      
    } else if (columnName == 'companyFundsDocument') {
      postadv = {
        downloadUrl:formData.companyFundsDocument
      };
     
    }else if(valuedefine=="exampt"){
      postadv = {
        downloadUrl:formData.examptProof
      };
     
    }else if(valuedefine=="exampt2"){
      postadv = {
        downloadUrl:formData.examptProof2
      };
    
    }

    // let postadv = {
    //   downloadUrl: formData.ProofValue
    // };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };


  // useEffect(()=>{
    
  //     function readURL(input, imgControlName) {
  //       if (input.files && input.files[0]) {
  //         var reader = new FileReader();
  //         reader.onload = function (e) {
  //           $(imgControlName).attr('src', e.target.result);
  //         };
  //         reader.readAsDataURL(input.files[0]);
  //         $('#name_tag').text(input.files[0].name);
  //       }
  //     }
  
  //     $('#imag2').change(function () {
  //       var imgControlName = '#ImgPreview2';
  //       readURL(this, imgControlName);
  //       $('.preview2').addClass('it');
  //       $('.btn-rmv2').addClass('rmv');
  //     });
  
  //     $('#removeImage2').click(function (e) {
  //       e.preventDefault();
  //       $('#imag2').val('');
  //       $('#ImgPreview2').attr('src', '');
  //       $('.preview2').text('');
  //       $('.btn-rmv2').removeClass('rmv');
  //     });
    
   
  // })
  const [imgType,setimgType]=useState();
  console.log("imgTypeimgType",imgType);
  console.log("imgSize",imgSize);

  return (
    <>
      <Row className="fullinput uploaded_btn ">
   
        <div className='btn_custom'>
          <div class="yes1">
            <button
              disabled={ formData.isPrimary == 1 ? false : true}

              className="click-hover" >
              <span class="btn_upload">
                <input
                  type="file"
                  id="imag2"
                  title=""
                  value={document}
                  onChange={(e) => {

                    uploadWithJSON(e.target.files[0]);
                    setimgType(e.target.files[0].type);
                    setimgSize(e.target.files[0].size);
                    removeError();
                  }}
                  onClick={(e) => {
                    e.target.value = null;
                    removeError();
                  }}
                />
              </span>
             {buttonName}
             
             {/* {BankInfo=="BankInfo"?<img src={info} onClick={infobanks} className="info backi" alt="" /> :""} */}

            </button>
          </div>
        </div>

        <div className=" ">
          <label className="bankClick">
            {labeltxt}
            {showIcon == true ? (
              <img src={info} onClick={inform} className="inform_identity_informbutton" />
            ) : (
              ''
            )}
          </label>
          <span className="error file ml-0"></span>
        </div>
      </Row>
      <div className="spceimg">
        {formData.ProofValue === '' ||
          formData.ProofValue === null ||
          formData.ProofValue === 'undefined' ||
          formData.ProofValue === undefined ? (
          ''
        ) : (
          <a href='#' className="licedocument-downloade" onClick={()=>downloadImage(formData.ProofValue)}>
            {' '}
            <div className="main-downloade-wrapper">
              <div className="downloads">
                <i className="fa fa-download downloadeStyle"></i>
              </div>
              <p className="downloadeMsg">View file already provided </p>
            </div>
          </a>
        )}
        {/* <span id="name_tag" class="preview2"></span> */}
        {/* <input type="button" id="removeImage2" value="x" class="btn-rmv2" /> */}
        {imageLoader === true ? (
          <div class="progress">
            <span class="progress-bar" style={{ width: '100%' }}></span>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default UploadImage;
