import React from "react";

const states = [
  ["Select", "Select"],
  ["10.5 %", "10.5 %"],
  ["17.5 %", "17.5 %"],
  ["28 %", "28 %"],
  ["30 %", "30 %"],
  ["33 %", "33 %"],
  ["39 %", "39 %"],
  ["Exempt","Exempt"],

];

const NrwtOne = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default NrwtOne;
