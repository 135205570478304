import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { Link as RouterLink } from 'react-router-dom';
import logo from '../pages/replica_logo.png';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import $ from 'jquery';
import Swal from 'sweetalert2';
import { useAuth0 } from '@auth0/auth0-react';
import { Form, Input, Button, Checkbox } from 'antd';
import { multisavedata} from '../auth/Configuration';
import invstaLogo from '../pages/invstaLogo.webp';

const Register2 = () => {
  const {loginWithRedirect } = useAuth0();
  const [loader, setLoader] = useState();

  // const [emails, setEmails] = useState();

  //
  useEffect(() => {
    $(document).ready(function () {
      $('#input').bind('keydown', function () {
        var c = this.selectionStart,
          r = /[^a-z0-9 .]/gi,
          v = $(this).val();
        if (r.test(v)) {
          $(this).val(v.replace(r, ''));
          c--;
        }
        this.setSelectionRange(c, c);
      });
    });
    $(document).ready(function () {
      $('.quantity').keypress(function (e) {
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 45 ||
          e.which === 38 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          return true;
        } else {
          return false;
        }
      });
    });

    $(document).ready(function () {
      $('.numbers').keypress(function (e) {
        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
          return false;
        }
      });
    });
    $('.digitsOnly').keypress(function (e) {
      var regex = new RegExp(/[0-9A-Za-z-!# :]+/g);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });

  function setPass(values) {
    // let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    let passwordExpression = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
    if (!passwordExpression.test(values.password)) {
      $('.passworderror')
        .html(
          'Password must be 8 – 16 characters, and contain at least one number, one uppercase and lowercase letter.'
        )
        .css('color', 'gray');
    } else {
      removeError();
    }
  }
  function removeError() {
    $('.error').html('');
    $('.passworderror').html('');
  }
  $('input').on('paste', function () {
    $('.passworderror').html('');
  });

  const onFinish = (values) => {
    // values.investorRelation = "Sam Harvey";
    if (values.investorRelation === 'Select') {
      $('.invertionrel').html('Required');
    } else {
      setLoader(true);
      axios
     
      .post(`${multisavedata}/preSignUp`, values)
      
      // .post(`${multisavedata}/presignup/onboard/individual`, values)
      .then((res) => {
          if (res.status === 200) {
            Swal.fire({
        html: `<p>Thank you for starting your investment application.</p><p> We have sent you an email to verify your email address. </p> <p>Please click on the link in this email to verify your email address, you will then be able to complete your application.</p>`,
              confirmButtonText: 'ok',
              customClass: "bg_colory resgist_popup",
              allowOutsideClick: false
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                loginWithRedirect();
              } else if (result.isDenied) {
                Swal.fire({
                  text: 'Unfortunately an error (Error 450) has occurred and we are not able to create your account at this time. Please contact the Investments Team on +64 6 323 2954 for assistance.',
                  customClass: "bg_colory",
                  allowOutsideClick: false
                  // text: res.data.message
                });
              }
            });
            //
          } else {
            setLoader(false);
            Swal.fire({
              text: 'Unfortunately an error (Error 450) has occurred and we are not able to create your account at this time. Please contact the Investments Team on +64 6 323 2954 for assistance.',
              allowOutsideClick: false
            
              // text: res.data.message
            });
          }
        })
        .catch(() => {
          setLoader(false);
          Swal.fire({
            // icon: 'error',
            text: 'Unfortunately an error (Error 450) has occurred and we are not able to create your account at this time. Please contact the Investments Team on +64 6 323 2954 for assistance.',
            allowOutsideClick: false
          });
        });
    }
  };

  // const [options, setOptions] = useState([]);

  // const result = () => {
  //   axios.get(`${duvalapi}/admin/name`).then((res) => {
  //     setOptions(res.data);
  //     // console.log("options1111",res)
  //   });
  //   console.log('options', options);
  // };

  // useEffect(() => {
  //   result();
  // }, []);

  const handleChange = (e) => {
    e.preventDefault();
  };

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });

  return loader ? (
    <div className="main-loader">
      <div className="loader"></div>
    </div>
  ) : (
    <div className="registered registered_input main_reg_page bg_bacv">
      <div className=" welcome__page">
        <div className="welcome-header">
          <a href="#">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="wel-come_content">
          {/* <h3>Application Form</h3> */}
          {/* <input type='button' id='but_screenshot' value='Take screenshot'  onClick={screenshot()}/> */}
          <div className="grid-container">
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>
          <p>Please enter your information below to create your login to start your application.</p>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row className="fullinput">
              <Col md={6}>
                <Form.Item
                  label="Enter your full name"
                  name="fullLegalName"
                  onPaste={handleChange}
                  rules={[
                    {
                      pattern: new RegExp(/^[a-zA-Z._^'%$#!~@ ,-]*$/),
                      message: 'Please enter your valid full name.'
                    },
                    { required: true, message: 'Please enter your full name' }
                  ]}
                >
                  <Input placeholder="Enter full legal name" />
                </Form.Item>
              </Col>
              
              {/* <Col md={6}>
                <Form.Item
                  label="Select Investor Relations Associate"
                  name="investorRelation"
                  rules={[{ required: true, message: 'Please select your investorRelation' }]}
                >
                  // <Input/> 
                  <select
                    onChange={(e) => {
                      removeError(e);
                    }}
                    required
                  >
                    <option>Select</option>
                    {options &&
                      options.map((val, index) => <option key={index}>{val.fullLegalName}</option>)}

                    {/* <option>Select</option>
                    <option>Sam Harvey</option>
                    <option>Mark Robinson</option>
                    <option>Shao Ma</option>
                    <option>Ketan Patel</option>
                    <option>Ben Good</option>
                    <option>Paul Manion</option> 
                  </select>
                </Form.Item>
                <span className="error invertionrel" />
              </Col> */}
              
            </Row>
            <Form.Item
              label="Enter your email"
              name="userEmail"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(
                    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  ),
                  message: 'Please enter your email address'
                }
                // rules={[{ required: true, message: 'Please enter your email address' }]}
              ]}
            >
              <Input placeholder="Enter email" />
            </Form.Item>
            <Form.Item
              label="Create password"
              name="password"
              required="true"
              rules={[{ required: true, message: 'Please create a password' }]}
            >
              <Input.Password
                className="Password"
                placeholder="Enter password"
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                title="Must contain at least one number and one uppercase and lowercase letter."
                required
                onChange={(e) => {
                  setPass(e);
                }}
              />
            </Form.Item>
            <span className="error passworderror" />
            <div class="check_box_wel left">
              <Form.Item
                valuePropName="check"
                wrapperCol={{ offset: 8, span: 16 }}
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Checkbox required="true">
                  {' '}
                  <label>
                    {' '}
                    I agree that my data and information will be
                    collected and used by MyFarm in accordance with
                    MyFarm's
                    <a
                      className="anchorfont "

                    href="https://www.myfarm.co.nz/terms"
                    target="_blank"
                    >
                      {' '}
                      Privacy Policy
                    </a>
                  </label>
                </Checkbox>
              </Form.Item>
            </div>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          {/* <button><LoginButton/></button> */}
          {/* <button ><LogoutButton/></button> */}
          <div className='invsta_logo_reg'>
            <img src={invstaLogo} alt="logo"/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Register2;
