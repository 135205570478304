/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { useForm, useStep } from "react-hooks-helper";
import ItemForm from "../ItemForm";
// import { Link } from 'react-router-dom'
import leftarrow from '../leftarrow.png';
import info from "../info.png";
import swal from "sweetalert";
import rightarrow from '../rightarrow.png';
// import PhoneInput from 'react-phone-input-2'
// import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Button from 'react-bootstrap/Button'
// import { DatePicker } from 'antd';
import logo from '../logo.png';
import $ from "jquery";
// import ReactInputMask from 'react-input-mask';
import InputMask from "react-input-mask";
import MenuSidebar from '../Individual/MenuSidebar';
import { infodetls } from '../Individual/SaveInfo';

// import Googleaddress from "./Googleaddress";
const Jointthird = ({ func,setForm, formData, navigation, saveAndExist,infodetlss, google,test }) => {
  const {
  fullName,
  shareholdingPercentage,
  secondholder,
  email1
} = formData;


const saveandexit=()=>{
  formData.secondholder=secondholder != "undefined" && secondholder != "" && secondholder != null ? secondholder.trim() :""
  formData.fullName=fullName != "undefined" && fullName != "" && fullName != null ? fullName.trim() :""
  func();

}
const [namefull1, setNamefull1] = useState(formData.secondholder ? formData.secondholder.trim().split(' ') : '');
// let text2 = ['world!', 'ny', 'jjij'];
// let result = text2[-1];
if(formData.secondholder != null && formData.secondholder != "" && formData.secondholder != "undefined" ){
  formData.firstName12 = namefull1[0] 
  if(namefull1.length > 1){
    formData.lastName12 = namefull1[namefull1.length - 1] 
  };

  if(namefull1 && namefull1.length > 2 ){
    formData.middleName12 =  namefull1.slice(1, namefull1.length - 1).join().replace(/,/g, ' ') 
  }
}

// formData.fullName= fullName.trim()
  // const { previous, next } = navigation;
  useEffect(() => {
    $('.toogles').click(function () {
      $('.toogles').removeClass('active')
      $(this).addClass('active');
    });
    $('.step-change').click(function () {
      $('.step-change').removeClass('active')
      $(this).addClass('active');
    });
    $("document").ready(function () {
      $("input[type='tel']").attr("readonly", true);
    });
    $(".flagsave").click(function () {
      let phoneCode = $(".form-control").val();
      localStorage.setItem('phoneCode', phoneCode);
    });
    $("#SHAREvALUE").keyup(function () {
     let input= $(this).val();
     if(input < 100){
        return true}
     else{return false}
    });
   
  });
  function splitFullName(fn) {
    fn = fn.replace(/(^[\s]*|[\s]*$)/g, "");
    fn = fn.replace(/\s+/g, " ");
    var fnarr = fn.split(" ");
    formData.firstNames = fnarr[0];
    formData.firstNameone =  formData.firstNames
    if (fnarr.length >= 3) {
      formData.lastNames = fnarr[fnarr.length - 1];
      formData.lastNameone =  formData.lastNames
      fnarr.shift();
      fnarr.pop();
      fnarr = fnarr.toString().replace(/,/g, " ");
      formData.middleNames = fnarr;
      formData.middleNameone =  formData.middleNames
    } else if (fnarr.length === 3) {
      formData.middleNames = fnarr[1];
      formData.middleNameone =  formData.middleNames
      formData.lastNames = fnarr[2];
      formData.lastNameone =  formData.lastNames
    } else if (fnarr.length === 2) {
      formData.lastNames = fnarr[1];
      formData.lastNameone =  formData.lastNames
    }
  }

  function Onprevious(e){
    formData.class = "Second"
    go("Second") 
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  // const [errorResults, setErrorResults] = useState();
  function handleClick(e) {
    formData.secondholder=secondholder != "undefined" && secondholder != "" && secondholder != null ? secondholder.trim() :""
    formData.fullName=fullName != "undefined" && fullName != "" && fullName != null ? fullName.trim() :""
  
    
    $('.error').html('');
    let emailExpression = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // var yvalue = $(".ui.icon.input>input").val();
    var btnVal = $('.postalAddressButton').hasClass('active');
   
   let shareholdingPercentagesecond = 100 - shareholdingPercentage;
   formData.shareholdingPercentagesecond = shareholdingPercentagesecond;
    if (typeof fullName == 'undefined' || fullName === "" || fullName === null) {
      $('.fullNameerror').html('Please enter your full name (as 1st joint account holder)');
    }
    else if (!emailExpression.test(formData.email1)) {
      $(".emailerror").html(
         "Please enter a valid email address, such as example@email.com"
      );
    }
    else if (typeof formData.secondholder == 'undefined' || formData.secondholder === "" || formData.secondholder === null) {
      $('.secondholder').html('Please enter full name of second joint account holder');
    }
    else if(formData.shareholdingPercentage <= 0 || formData.shareholdingPercentage == "" || formData.shareholdingPercentage < 10){
      $('.shareholdingPercentage123').html('Please enter at least 10% ');
    }else if(formData.shareholdingPercentage > 90 ){
      $('.shareholdingPercentage123').html('Please enter value not more than 90% ');
    }
    else {
      formData.class = "Third"
      splitFullName(formData.secondholder);
     if(formData.email1 != null && formData.email1 != "" && formData.email1 != "undefined" ){
      go("Jointfour")
     }
     else{
      $('.postalError').html('Please enter email address');
     }
    }
  }
  function removeError() {
    $('.error').html('');
  }
  function numbertest() {
    let input= $(this).val();
    if(input < 100){return true}
    else{return false}
  }
  $("input").on('paste', function (e) {
    $('.error').html('');
  })
  // const [value, setValue] = useState()
  const [currentDate, setNewDate] = useState(null);
  // const onChange = (event, data) => setNewDate(data.value);
  const { go } = navigation;
  saveAndExist = () => {
    formData.status = "PENDING"
    formData.step = "3"
    go("submit")
  }


  infodetlss = () => {
    swal({
      text:
        "Please provide a different email address to the one you have used for the first joint account holder.",
    });
  };

  const goPrevious = () => {
    go("Second")
  }
  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  useEffect(()=>{
  if(formData.check6===false){
    if( fullName === null ||
    fullName === '' ||
     fullName === 'undefined' ||
     fullName === undefined){
        $('.fullNameerror').html('Please enter your full name (as 1st joint account holder)');
console.log('Please enter your full name')
    }
    if( secondholder === null ||
     secondholder === '' ||
     secondholder === 'undefined' ||
     secondholder === undefined){
        $('.secondholder').html('Please enter your full name (as 2nd joint account holder)');
    }  if(email1 === "" ||email1 === '' ||
    email1 === 'undefined' ||
    email1 === undefined){
      $('.emailerror').html('Please enter email address');
     }
  }
  })
//Menusidebar Validation start 
// console.log('formData.check6',formData.check6)

// Menusidebar Validation Endss
  return (
    <>
    <MenuSidebar navigation={navigation} formData={formData}/>
      <div className="main_container">
      <div class="heading__top">
        <h2>Step 2: Joint Account Details</h2>
      </div>
      <div className="form">
      <img src={logo} alt="img" className="imageCover" />
      <div className="save-exit se_invff">
        <p className="save next" onClick={saveandexit}>
        SAVE DATA{" "}
        </p>
        <img src={info} onClick={infodetls} className="info" />
      </div>
      <Row>
        <Col>
          <label>Full name of first joint account holder</label>
          <ItemForm type="text"   maxLength={170} name="fullName" onKeyPress={removeError} className="quantity" id="fullName" onChange={e=>{setForm(e) ; removeError();}} value={fullName !==undefined&&fullName !==null&&fullName !=="undefined"&&fullName !==""?fullName:null} placeholder="Enter full name"/>
          <span className="error fullNameerror"></span>
        </Col>
      </Row>
      <Row> 
        <Col>
          <label>Share of income attributed to you (%)</label>
          <ItemForm type="text" name="shareholdingPercentage" onKeyPress={removeError} className="numbers"  id="" onChange={e=>{setForm(e) ; removeError()}} value={formData.shareholdingPercentage}   placeholder="50%" maxLength={2} />
          <span className="error shareholdingPercentage"></span>
          <span className="error shareholdingPercentage123"></span>
        </Col>
      </Row>
      <Row>
        <Col>
        <label>Full name of second joint account holder</label>
          <ItemForm name="secondholder"   maxLength={170} onPaste={handleChange} value={formData.secondholder} className="quantity capitallll" onKeyPress={removeError} onChange={e=>{setForm(e) ; removeError();
          }} placeholder="Enter full name" />
          <span className="error secondholder"></span>
        </Col>
      </Row>
      <Row>
        <Col>
        <label>Email address for joint account holder < img src={info} onClick={infodetlss} className="info_address" /> </label>
                <InputMask
                  name="email1"
                  autoComplete="off"
                  placeholder="Enter email address"
                  className="postal_address"
                  onKeyPress={removeError}
                  onChange={(e) => {
                    setForm(e);
                    removeError();
                  }}
                  value={formData.email1 != null && formData.email1 !="" && formData.email1 != "undefined"?formData.email1:"" }
                />
                <span className="error emailerror"></span></Col>
      </Row>
      <Row>
        <Col>
          <label>Share of income attributed to second joint account holder (%)</label>
          <ItemForm name="shareholdingPercentagesecond" className="numbers" onKeyPress={removeError} value={shareholdingPercentage != undefined
            ?
           100 - shareholdingPercentage
            :
             50
             } onChange={e=>{setForm(e) ; removeError()}} placeholder="50%" />
          {/* <span className="error shareholdingPercentagesecond"></span> */}
        </Col>
      </Row>
      <Row className=" check-mb">
          <Col>
            <label className='font-fami'>We require personal information and signatures from both joint account holders, if possible please have both joint account holders ready to enter their details now. You can also save your application data and resume the application at a later time, if one of the investors is not available now.</label>
          </Col>
          <div className="col-md-12">
         <br/>
          </div>
        </Row>
      <div className="spceimg ml-50">
        <span id="name_tag" class="preview2"></span>
        <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
      </div>
      <br />
      <div className="fotter-btn">
        <button className="preview" onClick={Onprevious}>
          <img src={leftarrow} className="leftarrow" alt=""/>
          Previous
        </button>
        
        <button type="submit" className="next flagsave" onClick={handleClick}>
          Continue
          <img src={rightarrow} className="rightarrow"  alt=""/>{" "}
        </button>
      </div>
    </div>
      </div>
    </>
  );
};
export default Jointthird;