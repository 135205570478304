/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import { useForm, useStep } from 'react-hooks-helper';
import axios from 'axios';
import Swal from 'sweetalert2';
import Zero from './Individual/Zero';
import Second from './Individual/Second';
import { useAuth0 } from '@auth0/auth0-react';
import Third from './Individual/Third';
import Four from './Individual/Four';
import Five from './Individual/Five';
import Six from './Individual/Six';
import Seven from './Individual/Seven';
import Eight from './Individual/Eight';
import Nine from './Individual/Nine';
import Ten from './Individual/Ten';
import Eleven from './Individual/Eleven';
import Twelve from './Individual/Twelve';
import Thirteen from './Individual/Thirteen';
import Fifteen from './Individual/Fifteen';
import Sixteen from './Individual/Sixteen';
import Seventeen from './Individual/Seventeen';
import Eighteen from './Individual/Eighteen';
import Nineteen from './Individual/Nineteen';
import Twenty from './Individual/Twenty';
import Twentyone from './Individual/Twentyone';
import Twentyone1 from './Individual/Twentyone1';
import Twentyone2 from './Individual/Twentyone2';
import Twentytwo from './Individual/Twentytwo';
import Twentyone11 from './Individual/Twentyone11';

import JoinThird from './Joint/Jointhird';
import Jointfour from './Joint/Jointfour';
import Jointfive from './Joint/Jointfive';
import Jointsix from './Joint/Jointsix';
import Jointseven from './Joint/Jointseven';
import Jointeight from './Joint/Jointeight';
import Jointnine from './Joint/Jointnine';
import Jointten from './Joint/Jointten';
import Jointeleven from './Joint/Jointeleven';
import Jointtwelve from './Joint/Jointtwelve';
import Jointthirteen from './Joint/Jointthirteen';
import Jointfourteen from './Joint/Jointfourteen';
import Jointfivteen from './Joint/Jointfivteen';
import Jointsexteen from './Joint/Jointsexteen';
import Jointseventeen from './Joint/Jointseventeen';
import Jointeighteen from './Joint/Jointeighteen';
import Jointninteen from './Joint/Jointninteen';
import Jointtwenty from './Joint/Jointtwenty';
import Jointtwentyone from './Joint/Jointtwentyone';
import Jointtwentytwo from './Joint/Jointtwentytwo';
import Jointtwentythree from './Joint/Jointtwentythree';
import Jointtwentyfour from './Joint/Jointtwentyfour';
import Jointtwentyfive from './Joint/Jointtwentyfive';
import Jointtwentysix from './Joint/Jointtwentysix';
import Jointtwentyseven from './Joint/Jointtwentyseven';
import Jointtwentyeight from './Joint/Jointtwentyeight';
import Jointtwentynine from './Joint/Jointtwentynine';
import Jointtwentyten from './Joint/Jointtwentyten';

// import EntityOne from './Company/EntityOne';
// import EntityTwo from './Company/EntityTwo';
// import EntityThird from './Company/EntityThird';
// import EntityThird1 from './Company/EntityThird1';
// import EntityFour from './Company/EntityFour';
// import CompanyThird from './Company/CompanyThird';
// import CompanyFour from './Company/CompanyFour';

import Review from './Review';
import Submit from './Submit';
import $ from 'jquery';
import MenuSidebar from './Individual/MenuSidebar';
import { multisavedata } from 'src/auth/Configuration';
import DashboardNavbar from 'src/layouts/dashboard/DashboardNavbar';

const steps = [
  { id: 'Zero' },
  { id: 'MenuSidebar' },
  { id: 'Second' },
  { id: 'Third' },
  { id: 'Four' },
  { id: 'Five' },
  { id: 'Six' },
  { id: 'Seven' },
  { id: 'Eight' },
  { id: 'Nine' },
  { id: 'Ten' },
  { id: 'Twelve' },
  { id: 'Thirteen' },
  { id: 'Fifteen' },
  { id: 'Sixteen' },
  { id: 'Eleven' },
  { id: 'Seventeen' },
  { id: 'Eighteen' },
  { id: 'Twentyone' },
  { id: 'Twentyone1' },
  { id: 'Twentyone2' },
  { id: 'Twentyone11' },
  { id: 'Twentytwo' },

  { id: 'review' },
  { id: 'Jointhird' },
  { id: 'Jointfour' },
  { id: 'Jointfive' },
  { id: 'Jointsix' },
  { id: 'Jointseven' },
  { id: 'Jointeight' },
  { id: 'Jointnine' },
  { id: 'Jointten' },
  { id: 'Jointeleven' },
  { id: 'Jointtwelve' },
  { id: 'Jointfourteen' },
  { id: 'Jointfivteen' },
  { id: 'Jointsexteen' },
  { id: 'Jointseventeen' },
  { id: 'Jointeighteen' },
  { id: 'Jointninteen' },
  { id: 'Jointtwenty' },
  { id: 'Jointtwentyone' },
  { id: 'Jointtwentytwo' },
  { id: 'Jointtwentythree' },
  { id: 'Jointtwentyfour' },
  { id: 'Jointtwentyfive' },
  { id: 'Jointtwentysix' },
  { id: 'Jointtwentyseven' },
  { id: 'Jointtwentyeight' },
  { id: 'Jointtwentynine' },
  { id: 'Jointtwentyten' },
  { id: 'Jointtwentyten2222' },
  // company Start
  // { id: 'EntityOne' },
  // { id: 'EntityTwo' },
  // { id: 'EntityThird' },
  // { id: 'EntityThird1' },
  // { id: 'EntityFour' },
  // { id: 'CompanyThird' },
  // { id: 'CompanyFour' },

  { id: 'submit' }
];

const defaultData = {};

const MultiStepForm = ({ images }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    $(document).ready(function () {
      //called when key is pressed in textbox
      $('.quantity').keypress(function (e) {
        //if the letter is not digit then display error and don't type anything
        if (
          (e.which >= 65 && e.which <= 90) ||
          e.which === 32 ||
          e.which === 33 ||
          e.which === 36 ||
          e.which === 38 ||
          e.which === 39 ||
          e.which === 40 ||
          e.which === 41 ||
          e.which === 45 ||
          e.which === 46 ||
          (e.which >= 97 && e.which <= 122)
        ) {
          //display error message
          return true;
        } else {
          // $("#errmsg").html("Character Only").show().fadeOut("slow");
          return false;
        }
      });
    });
    $(document).ready(function () {
      //called when key is pressed in textbox
      $('.numbers').keypress(function (e) {
        //if the letter is not digit then display error and don't type anything
        if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
          //display error message
          return false;
        }
      });
    });
    $('.digitsOnly').keypress(function (e) {
      var regex = new RegExp(/^[a-zA-Z\s/0-9]+$/);
      var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (regex.test(str)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    });
  });

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const [formData, setForm] = useForm(defaultData);
  const props = { formData, setForm, navigation };
  const [loader, setLoader] = useState();

  const { go } = navigation;

 

  const {
    // getIdTokenClaims,
    // getAccessTokenSilently,
    // isAuthenticated,
    // loginWithRedirect,
    // accessToken,
    logout
  } = useAuth0();

 
  console.log("formData.isMyFarm", formData.isMyFarm)

  const applicat = async (value, status,loder) => {
    let IndividualExpiryDate = sessionStorage.getItem('documentExpiryDates');
    let JointExpiryDate = sessionStorage.getItem('documentExpiryDates1');
    let IndividualDOB = sessionStorage.getItem('dateOfBirth');
    let jointDOB = sessionStorage.getItem('dateOfBirth2');
    let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
    let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
    let residentialAddress2 = sessionStorage.getItem('residentialAddress2');
    let PostalAddress2 = sessionStorage.getItem('PostalAddress2');
    let city = sessionStorage.getItem('locality');
    let postal_code = sessionStorage.getItem('postal_code');
    let country = sessionStorage.getItem('country');
    let region = sessionStorage.getItem('sublocality_level_1');
    let route = sessionStorage.getItem('route');
    let street_number = sessionStorage.getItem('street_number');

    let city1 = sessionStorage.getItem('locality1');
    let postal_code1 = sessionStorage.getItem('postal_code1');
    let country1 = sessionStorage.getItem('country1');
    let region1 = sessionStorage.getItem('sublocality_level_11');
    let route1 = sessionStorage.getItem('route1');
    let street_number1 = sessionStorage.getItem('street_number1');

    let  adress = {
      suburb:
        formData.region != null && formData.region != '' && formData.region != 'undefined'
          ? formData.region
          : region,
      addressLine1:
        (formData.street_number != null &&
          formData.street_number != 'undefined' &&
          formData.street_number != ''
          ? formData.street_number
          : street_number != null && street_number != 'undefined'
            ? street_number
            : '') +
        ' ' +
        (route == null ? '' : route),
      postalCode:
        formData.postalCode != null &&
          formData.postalCode != 'undefined' &&
          formData.postalCode != ''
          ? formData.postalCode
          : postal_code != null && postal_code != 'undefined' && postal_code != ''
            ? postal_code
            : '',
      city:
        formData.city != null && formData.city != 'undefined' && formData.city != ''
          ? formData.city
          : city,
      countryCode:
        formData.countryyCode != null &&
          formData.countryyCode != 'undefined' &&
          formData.countryyCode != ''
          ? formData.countryyCode
          : 'NZ',
      country:
        formData.country != null && formData.country != 'undefined' && formData.country != ''
          ? formData.country
          : country,
      isPrimary: true,
      isActive: true
    }

 


    let postdata = {
      applicationStage: formData.applicationStage,
      investmentType:
        formData.investmentType != null && formData.investmentType != 'Select'
          ? formData.investmentType
          : null,
      // occupation: formData.occupation != null ? formData.occupation : null,
      fundCode: formData.fundCode != null ? formData.fundCode : null,
      totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
      isMyFarm:
        formData.isMyFarm != null &&
          formData.isMyFarm != 'undefined' &&
          formData.isMyFarm != ''
          ? formData.isMyFarm == 'true'
            ? true
            : false
          : false,

      futureInvestementOpportunities:
        formData.futureInvestementOpportunities,


      primaryShareOfIncome:formData.investmentType =="Joint"?
        formData.shareholdingPercentage !== null &&
          formData.shareholdingPercentage !== '' &&
          formData.shareholdingPercentage !== 'undefined' &&
          formData.shareholdingPercentage !== undefined
          ? formData.shareholdingPercentage
          : 50:null,

      fullLegalName:
        (formData.firstNameindi != null &&
          formData.firstNameindi != '' &&
          formData.firstNameindi != 'undefined'
          ? formData.firstNameindi
          : formData.firstName != null &&
            formData.firstName != '' &&
            formData.firstName != 'undefined'
            ? formData.firstName
            : '') +
        ' ' +
        (formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName + ' '
            : '') +
        '' +
        (formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : ''),
      preferredName: formData.preferredName != null ? formData.preferredName : null,

      dateOfBirth:
        IndividualDOB != null &&
          IndividualDOB != '' &&
          IndividualDOB != 'undefined' &&
          IndividualDOB != undefined
          ? IndividualDOB
          : null,

      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,


          
          userResidentialAddress: formData.manuallyAddress == 'false' ?
          (formData.street_number +
          (formData.region != null && formData.region != undefined && formData.region != '' ? ', ' + formData.region : '') +
          (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
          (formData.postalCode != null && formData.postalCode != ''
            ? ', ' + formData.postalCode
            : '') +
          (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
          formData.isPrimaryResdAddress,

          
          
          isUserAddressSame:
          formData.isUserAddressSame === 'true' ||
            formData.isUserAddressSame === '' ||
            formData.isUserAddressSame === 'undefined' ||
            formData.isUserAddressSame === undefined
            ? 1
            : 0,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

          userPostalAddress: formData.isPrimaryPostalAdress != null && formData.isPrimaryPostalAdress != '' ? formData.isPrimaryPostalAdress : null,
      priContactType:
        formData.priContactType === 'Mobile' ||
          formData.priContactType === '' ||
          formData.priContactType === 'undefined' ||
          formData.priContactType === undefined ||
          formData.priContactType === null
          ? 'Mobile'
          : formData.priContactType,
      priContactCountryCode:
        formData.priContactCountryCode === undefined ||
          formData.priContactCountryCode === 'undefined' ||
          formData.priContactCountryCode === null
          ? '64'
          : formData.priContactCountryCode,
      priContactNumber: formData.codeVerify === 'true' ? formData.priContactNumber : null,
      // isPriContactNumberVerified: formData.codeVerify === 'true' ? 1 : 0,
      isAlternateContact: formData.isAlternateContact,
      secContactType:
        formData.isAlternateContact == false
          ? null
          : formData.secContactType !== 'undefined' &&
            formData.secContactType !== null &&
            formData.secContactType !== ''
            ? formData.secContactType
            : 'Home',
      secContactCountryCode:
        formData.isAlternateContact == false
          ? null
          : formData.secContactCountryCode === undefined ||
            formData.secContactCountryCode === null ||
            formData.secContactCountryCode === 'undefined'
            ? '64'
            : formData.secContactCountryCode,

      secContactNumber: formData.isAlternateContact == false ? null : formData.secContactNumber,
      placeOfBirth: formData.placeOfBirth != null ? formData.placeOfBirth : null,
      countryOfBirth: formData.countryOfBirth != null ? formData.countryOfBirth : null,
      residingCountry: formData.residingCountry != null ? formData.residingCountry : null,
      userIrdNumber: formData.userIrdNumber != null ? formData.userIrdNumber : null,
      userIrdNumberProof: null,
      taxType: formData.rwt,
      rwtValue: formData.rwt === 'Resident Withholding Tax' ? formData.rwtValue : null,
      taxExemption: formData.checkboxx === true ? true : false,
      examptProof:
        formData.examptProof !== null &&
          formData.examptProof !== '' &&
          formData.examptProof !== 'undefined'
          ? formData.examptProof
          : null,
      addressDetails: adress,
      isOtherCitizen: formData.isPostalAdd11 === 'false' ? 1 : 0,

      captureReference: formData.Capture != null ? formData.Capture : null,
      identificationType: formData.biometric != null ? formData.biometric : null,
      documentIdType:
        formData.biometric == 'Biometric'
          ? null
          : formData.idType === '1' ||
            formData.idType === '' ||
            formData.idType === 'undefined' ||
            formData.idType === undefined ||
            formData.idType === null
            ? 'NZ Driver Licence'
            : formData.idType === '2'
              ? 'NZ Passport'
              : formData.idType === '3'
                ? 'other'
                : "",
      firstName:
        formData.firstName != null && formData.firstName != '' && formData.firstName != 'undefined'
          ? formData.firstName
          : formData.firstNameindi != '' &&
            formData.firstNameindi != null &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : null,
      middleName:
        formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName
            : '',
      lastName:
        formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : '',
      documentNumber: formData.biometric == 'Biometric' ? null : formData.documentNumber,
      documentExpiryDate:
        formData.biometric == 'Biometric'
          ? null
          : IndividualExpiryDate != null && IndividualExpiryDate != ''
            ? IndividualExpiryDate
            : null,
      documentVersionNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentVersionNumber != null
            ? formData.documentVersionNumber
            : null,
      documentProof:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentProof != null
            ? formData.documentProof
            : null,
      documentCountryOfIssue:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentCountryOfIssue != null
            ? formData.documentCountryOfIssue
            : null,
      otherDocumentName:
        formData.biometric == 'Biometric'
          ? null
          : formData.otherDocumentName != null
            ? formData.otherDocumentName
            : null,
      primaryBankAccountName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
     
        // primaryBankName:
        // formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
     
        primaryBankAccountNumber:
        formData.primaryBankAccountNumber != null ? formData.primaryBankAccountNumber : null,
      primaryBankProof: formData.primaryBankProof != null ? formData.primaryBankProof : null,
      bankSwiftCode:
        formData.isNZBank === 'undefined' || formData.isNZBank === 'true'
          ? null
          : formData.bankSwiftCode != null
            ? formData.bankSwiftCode
            : null,
      entityName: formData.investmentType == "Joint" ? `${formData.fullName} ${formData.secondholder !== "" && formData.secondholder !== null ? "&" : ""} ${formData.secondholder}` : formData.fullName,

      isNZBank: formData.isNZBank === 'undefined' || formData.isNZBank === 'true' ? true : false,
      isPrimaryBank:
        formData.isNZBank12 === undefined ||
          formData.isNZBank12 === null ||
          formData.isNZBank12 === 'true'
          ? true
          : false,

      // secondary

      secondaryDetails: {
        applicationStage: formData.applicationStage,
        investmentType:
          formData.investmentType != null && formData.investmentType != 'Select'
            ? formData.investmentType
            : '',
        occupation: formData.occupation != null ? formData.occupation : null,
        fundCode: formData.fundCode != null ? formData.fundCode : null,
        totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
        isMyFarm:
          formData.isMyFarm != null &&
            formData.isMyFarm != 'undefined' &&
            formData.isMyFarm != ''
            ? formData.isMyFarm == 'true'
              ? true
              : false
            : false,
        SecondaryShareOfIncome:
          formData.shareholdingPercentage != undefined ? 100 - formData.shareholdingPercentage : 50,
        fullLegalName:
          formData.secondholder != null &&
            formData.secondholder != '' &&
            formData.secondholder != 'undefined'
            ? formData.secondholder
            : '',
        preferredName: formData.preferredName2 != null ? formData.preferredName2 : null,
        dateOfBirth: jointDOB != null ? jointDOB : null,
        userResidentialAddress:
        formData.manuallyAddress2 == 'false'
              ? (formData.street_number1 +
                (formData.region1 != '' && formData.region1 != null && formData.region1 != undefined ? ', ' + formData.region1 : '') +
                (formData.city1 != '' ? ', ' + formData.city1 : '') +
                (formData.postalCode1 != null ? ', ' + formData.postalCode1 : '') +
                (formData.country1 != '' ? ', ' + formData.country1 : ''))
              :   formData.isSecondryResdAddress,
        isUserAddressSame:
          formData.isUserAddressSame2 === 'true' ||
            formData.isUserAddressSame2 === '' ||
            formData.isUserAddressSame2 === 'undefined' ||
            formData.isUserAddressSame2 === undefined
            ? 1
            : 0,
        userPostalAddress: formData.isSecondryPostalAdre != null && formData.isSecondryPostalAdre != '' ? formData.isSecondryPostalAdre : null,

        isGoogleAddress:
          formData.manuallyAddress2 != null &&
            formData.manuallyAddress2 != 'undefined' &&
            formData.manuallyAddress2 != ''
            ? formData.manuallyAddress2 == 'true' || formData.manuallyAddress2 == true
              ? true
              : formData.manuallyAddress2 == 'false' || formData.manuallyAddress2 == false
                ? false
                : true
            : true,
        priContactType:
          formData.priContactType2 === 'Mobile' ||
            formData.priContactType2 === '' ||
            formData.priContactType2 === 'undefined' ||
            formData.priContactType2 === undefined ||
            formData.priContactType2 === null
            ? 'Mobile'
            : formData.priContactType2,
        priContactCountryCode:
          formData.priContactCountryCode2 === undefined ||
            formData.priContactCountryCode2 === 'undefined' ||
            formData.priContactCountryCode2 === null
            ? '64'
            : formData.priContactCountryCode2,
        priContactNumber: formData.priContactNumber2,
        isAlternateContact: formData.isAlternateContact1,
        secContactType:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactType2 !== 'undefined' &&
              formData.secContactType2 !== '' &&
              formData.secContactType2 !== null
              ? formData.secContactType2
              : 'Home',
        secContactCountryCode:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactCountryCode2 === undefined ||
              formData.secContactCountryCode2 === null ||
              formData.secContactCountryCode2 === 'undefined'
              ? '64'
              : formData.secContactCountryCode2,
        secContactNumber: formData.isAlternateContact1 == false ? null : formData.secContactNumber2,
        placeOfBirth: formData.placeOfBirth2 != null ? formData.placeOfBirth2 : null,
        countryOfBirth: formData.countryOfBirth2 != null ? formData.countryOfBirth2 : null,
        residingCountry: formData.residingCountry2 != null ? formData.residingCountry2 : null,
        userIrdNumber: formData.userIrdNumber2 != null ? formData.userIrdNumber2 : null,
        userIrdNumberProof: null,
        taxType: formData.rwt2,
        rwtValue: formData.rwt2 === 'Resident Withholding Tax' ? formData.rwtValue2 : null,
        taxExemption: formData.checkboxxjoint2 === true ? true : false,
        examptProof:
          formData.examptProof2 !== null &&
            formData.examptProof2 !== '' &&
            formData.examptProof2 !== 'undefined'
            ? formData.examptProof2
            : null,
        captureReference: formData.Capture2 != null ? formData.Capture2 : null,
 
        addressDetails: {
          suburb:
            formData.region1 != null && formData.region1 != '' && formData.region1 != 'undefined'
              ? formData.region1
              : region1,
              
          addressLine1:
            (formData.street_number1 != null &&
              formData.street_number1 != 'undefined' &&
              formData.street_number1 != ''
              ? formData.street_number1
              : street_number1 != null && street_number1 != 'undefined'
                ? street_number1
                : '') +
            ' ' +
            (route1 == null ? '' : route1),
          postalCode:
            formData.postalCode1 != null &&
              formData.postalCode1 != 'undefined' &&
              formData.postalCode1 != ''
              ? formData.postalCode1
              : postal_code1 != null && postal_code1 != 'undefined' && postal_code1 != ''
                ? postal_code1
                : '',
          city:
            formData.city1 != null && formData.city1 != 'undefined' && formData.city1 != ''
              ? formData.city1
              : city1,
          countryCode:
            formData.countryyCode3 != null &&
              formData.countryyCode3 != 'undefined' &&
              formData.countryyCode3 != ''
              ? formData.countryyCode3
              : 'NZ',
          country:
            formData.country1 != null && formData.country1 != 'undefined' && formData.country1 != ''
              ? formData.country1
              : country1,
          isPrimary: true,
          isActive: true
        },
        isOtherCitizen: formData.isPostalAdd12 === 'false' ? 1 : 0,

        captureReference: formData.Capture2 != null ? formData.Capture2 : null,
        identificationType: formData.biometric2 != null ? formData.biometric2 : null,
        documentIdType:
          formData.biometric2 == 'Biometric'
            ? ''
            : formData.idType2 === '1' ||
              formData.idType2 === '' ||
              formData.idType2 === 'undefined' ||
              formData.idType2 === undefined ||
              formData.idType2 === null
              ? 'NZ Driver Licence'
              : formData.idType2 === '2'
                ? 'NZ Passport'
                : formData.idType2 === '3'
                  ? 'other'
                  : "",
        firstName:
          formData.firstName12 != null &&
            formData.firstName12 != '' &&
            formData.firstName12 != 'undefined'
            ? formData.firstName12
            : null,
        middleName:
          formData.middleName12 != null &&
            formData.middleName12 != '' &&
            formData.middleName12 != 'undefined'
            ? formData.middleName12
            : null,
        lastName:
          formData.lastName12 != null &&
            formData.lastName12 != '' &&
            formData.lastName12 != 'undefined'
            ? formData.lastName12
            : null,
        documentNumber: formData.biometric2 == 'Biometric' ? null : formData.documentNumber2,
        documentExpiryDate:
          formData.biometric2 == 'Biometric'
            ? null
            : JointExpiryDate != null && JointExpiryDate != ''
              ? JointExpiryDate
              : null,
        documentVersionNumber:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentVersionNumber2 != null
              ? formData.documentVersionNumber2
              : null,
        documentCountryOfIssue:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentCountryOfIssue2 != null
              ? formData.documentCountryOfIssue2
              : null,
        otherDocumentName:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.otherDocumentName2 != null
              ? formData.otherDocumentName2
              : null,
        documentProof:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentProof2 != null
              ? formData.documentProof2
              : null,
        isNZBank:
          formData.isNZBank2 === 'true'
            ? true
            : formData.isNZBank2 === undefined
              ? null
              : formData.isNZBank12 === 'false' && formData.isNZBank12 === 'false'
                ? false
                : true,
        // isNZBank: ,
        isPrimaryBank:
          formData.isNZBank12 === undefined ||
            formData.isNZBank12 === null ||
            formData.isNZBank12 === 'true'
            ? true
            : false,
        primaryBankAccountName:
          formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
       
          // primaryBankName:
          // formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
        
          primaryBankAccountNumber:
          formData.primaryBankAccountNumber2 != null ? formData.primaryBankAccountNumber2 : null,
        primaryBankProof: formData.primaryBankProof2 != null ? formData.primaryBankProof2 : null,
        bankSwiftCode:
          formData.isNZBank2 === 'undefined' || formData.isNZBank2 === 'true'
            ? ''
            : formData.bankSwiftCode2 != null
              ? formData.bankSwiftCode2
              : null,
        // entityName: `${formData.fullName !== null && formData.fullName !== undefined ? formData.fullName : ""} ${formData.secondholder !== null && formData.secondholder !== undefined ? '&' : ""} ${formData.secondholder !== null && formData.secondholder !== undefined ? formData.secondholder : ""}`,
        entityName: formData.investmentType == "Joint" ? `${formData.fullName} ${formData.secondholder !== "" && formData.secondholder !== null ? "&" : ""} ${formData.secondholder}` : formData.fullName,

        userEmail:
          formData.userEmail === formData.email1
            ? formData.userCode
            : formData.email1 != null && formData.email1 != '' && formData.email1 != 'undefined'
              ? formData.email1
              : formData.userCode,

        // certificateDetails: certificateDataSecondary
      }
    };

    if (value == "save" && status === "Progress") {

      formData.applicationStage = 'In Progress';
      if (formData.investmentType === 'Joint') {
        
        {
       
        loder=='loder'?formData.miniloader=true: setLoader(true);
        }
        await axios
          .put(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                {
                  loder=='loder'? formData.miniloader=false:       Swal.fire({
                    html: '<p class="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                    confirmButtonColor: '#231f20',
                    allowOutsideClick: false
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    }
                  });
                  }
          

                setLoader(false);
                formData.miniloader=false;
                formData.bioVerify = '';
              } else {
               {
                  loder=='loder'? formData.miniloader=false:           Swal.fire({
                    html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                    confirmButtonColor: '#231f20',
                    allowOutsideClick: false
                  });
                  } 
           
                setLoader(false);
                formData.miniloader=false;
              }
            }
            else if (res.status === 208) {
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false
              });
              setLoader(false);
              formData.miniloader=false;
            }
            else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
              formData.miniloader=false;
            }
          })
          .catch(() => {
            setLoader(false);
            formData.miniloader=false;
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });



      } else if (formData.investmentType === 'Individual') {
        {
       
          loder=='loder'?formData.miniloader=true: setLoader(true);
          }
        delete postdata.secondaryDetails;

        await axios
          .put(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                {
                  loder=='loder'? formData.miniloader=false:       Swal.fire({
                    html: '<p class="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                    confirmButtonColor: '#231f20',
                    allowOutsideClick: false
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                    }
                  });}
                setLoader(false);
                formData.miniloader=false;
                formData.bioVerify = '';
              } else {
                {
                  loder=='loder'? formData.miniloader=false:           Swal.fire({
                    html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                    confirmButtonColor: '#231f20',
                    allowOutsideClick: false
                  });
                  }
         
                setLoader(false);
                formData.miniloader=false;
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
              formData.miniloader=false;
            }
          })
          .catch(() => {
            setLoader(false);
            formData.miniloader=false;
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });


      } else if (formData.investmentType === 'Company') {
        setLoader(true);
        
        await axios
          // .put(`${multisavedata}/onboard/individual`, postdata, {
          .put(`${multisavedata}/onboard/company`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                Swal.fire({
                  html: '<p class ="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                  }
                });
                setLoader(false);
                formData.bioVerify = '';
              } else {
                Swal.fire({
                  html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                });
                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else {
        Swal.fire({
          // icon: 'error',
          // title: 'Oops...',
          text: 'Please select the Investment type'
        });
      }

    } else if (value == "post" && status === "Submit") {
      formData.applicationStage = 'Submitted';

      if (formData.investmentType === 'Joint') {
        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        await axios
          .post(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            } else if (res.status === 208) {
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false
              });
              setLoader(false);
            } else {
              Swal.close();

              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });
      } else if (formData.investmentType === 'Individual') {
        delete postdata.secondaryDetails;
        setLoader(true);
        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setLoader(false);
        delete postdata.companyDetails;

        await axios
          .post(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            } else if (res.status === 202) {
              setLoader(false);
              Swal.fire({
                text: `${res.data.message}`
              });
            } else {
              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });


      } else if (formData.investmentType === 'Company') {
        setLoader(true);

        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setLoader(false);

        await axios
          .post(`${multisavedata}/onboard/company`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            } else if (res.status === 202) {
              setLoader(false);
              Swal.fire({
                text: `${res.data.message}`
              });
            } else {
              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });
      }
    } else if (value == "saveLogout" && status === "Submit") {
      formData.applicationStage = 'In Progress';
      if (formData.investmentType === 'Joint') {
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 208) {
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false

              });
              setLoader(false);
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else if (formData.investmentType === 'Individual') {
        delete postdata.companyDetails;
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else if (formData.investmentType === 'Company') {
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/company`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else {
        Swal.fire({
          // icon: 'error',
          // title: 'Oops...',
          text: 'Please select the Investment type'
        });
      }

    }
  }



 



  const savedata=(value)=>{
formData.investmentType=value;
    applicat("save", "Progress",'loder')
  }


  const application2 = async () => {
    applicat("save", "Progress")
  };

  const application4 = async () => {
    applicat("saveLogout", "Progress")
  };
  const application3 = async () => {
    applicat("post", "Submit")

  };



  switch (id) {
    case 'Zero':
      return <Zero {...props} />;
    case 'Second':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Second func={application2} func1={application4} fun2={savedata} {...props} />
      );
    case 'Third':
      if (formData.investmentType == 'Joint') {
        return loader ? (
          <div className="main-loader">
            <div className="loader"></div>
          </div>
        ) : (
          <JoinThird func={application2} {...props} />
        );
      } else if (formData.investmentType == 'Individual') {
        return loader ? (
          <div className="main-loader">
            <div className="loader"></div>
          </div>
        ) : (
          <Third func={application2} {...props} />
        );
      }

    case 'Four':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Four func={application2} {...props} />
      );
    case 'Five':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Five func={application2} {...props} />
      );
    case 'Six':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Six func={application2} {...props} />
      );
    case 'Seven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Seven func={application2} {...props} />
      );
    case 'Eight':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eight func={application2} {...props} />
      );
    case 'Nine':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Nine func={application2} {...props} />
      );
    case 'Ten':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Ten func={application2} {...props} />
      );
    case 'Twelve':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twelve func={application2} {...props} />
      );
    case 'Thirteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Thirteen func={application2} {...props} />
      );
    // case "Fourteen":
    // return <Fourteen {...props} />;
    case 'Fifteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Fifteen func={application2} {...props} />
      );

    // case 'EntityOne':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <EntityOne func={application2} {...props} />
    //   );
    // case 'EntityTwo':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <EntityTwo func={application2} {...props} />
    //   );
    // case 'EntityThird':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <EntityThird func={application2} {...props} />
    //   );
    // case 'EntityThird1':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <EntityThird1 func={application2} {...props} />
    //   );
    // case 'EntityFour':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <EntityFour func={application2} {...props} />
    //   );
    // case 'CompanyThird':
    //   return loader ? (
    //     <div className="main-loader">
    //       <div className="loader"></div>
    //     </div>
    //   ) : (
    //     <CompanyThird func={application2} {...props} />
    //   );
    // case 'CompanyFour':
    // return loader ? (
    //   <div className="main-loader">
    //     <div className="loader"></div>
    //   </div>
    // ) : (
    //   <CompanyFour func={application2} {...props} />
    // );

    case 'Sixteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Sixteen func={application2} {...props} />
      );
    case 'Eleven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eleven func={application2} {...props} />
      );

    case 'Seventeen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Seventeen func={application2} {...props} />
      );
    case 'Eighteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Eighteen func={application2} {...props} />
      );
    case 'Nineteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Nineteen func={application2} {...props} />
      );
    case 'Twenty':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twenty func={application2} {...props} />
      );
    case 'Twentyone':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone func={application2} {...props} />
      );
    case 'Twentyone1':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone1 func={application2} {...props} />
      );
    case 'Twentyone2':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone2 func={application2} {...props} />
      );
    case 'Twentytwo':
      return loader ? (
        <div className="main-loader">
          {' '}
          <sidebarConfig />
          <div className="loader"></div>
        </div>
      ) : (
        <Twentytwo func={application2} {...props} />
      );
    case 'review':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Review func={application2} {...props} />
      );
    case 'Jointhird':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <JoinThird func={application2} {...props} />
      );
    case 'MenuSidebar':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <MenuSidebar
          func={application4}
          {...props}
        />
      );
    case 'Jointfour':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfour func={application2} {...props} />
      );
    case 'Jointfive':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfive func={application2} {...props} />
      );
    case 'Jointsix':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointsix func={application2} {...props} />
      );
    case 'Jointseven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointseven func={application2} {...props} />
      );
    case 'Jointeight':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeight func={application2} {...props} />
      );
    case 'Jointnine':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointnine func={application2} {...props} />
      );
    // case "Jointninimore":
    //   return <Jointninimore {...props} />;
    case 'Jointten':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointten func={application2} {...props} />
      );

    case 'Jointeleven':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeleven func={application2} {...props} />
      );
    case 'Jointtwelve':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointtwelve func={application2} {...props} />
      );
    case 'Jointthirteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointthirteen func={application2} {...props} />
      );
    case 'Jointfourteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfourteen func={application2} {...props} />
      );
    case 'Jointfivteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointfivteen func={application2} {...props} />
      );
    case 'Jointsexteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointsexteen func={application2} {...props} />
      );
    case 'Jointseventeen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointseventeen func={application2} {...props} />
      );
    case 'Jointeighteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointeighteen func={application2} {...props} />
      );
    case 'Jointninteen':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointninteen func={application2} {...props} />
      );
    case 'Jointtwenty':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Jointtwenty func={application2} {...props} />
      );
    case 'Jointtwentyten2222':
      return <DashboardNavbar func={application2} {...props} />;
    case 'Jointtwentyone':
      return <Jointtwentyone func={application2} {...props} />;
    case 'Jointtwentytwo':
      return <Jointtwentytwo func={application2} {...props} />;
    case 'Jointtwentythree':
      return <Jointtwentythree func={application2} {...props} />;
    case 'Jointtwentyfour':
      return <Jointtwentyfour func={application2} {...props} />;
    case 'Jointtwentyfive':
      return <Jointtwentyfive func={application2} {...props} />;
    case 'Jointtwentysix':
      return <Jointtwentysix func={application2} {...props} />;
    case 'Jointtwentyseven':
      return <Jointtwentyseven func={application2} {...props} />;
    case 'Jointtwentyeight':
      return <Jointtwentyeight func={application2} {...props} />;
    case 'Jointtwentynine':
      return <Jointtwentynine func={application2} {...props} />;
    case 'Jointtwentyten':
      return <Jointtwentyten func={application2} {...props} />;

    case 'Twentyone11':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Twentyone11 func={application2} functwo={application3} {...props} />
      );

    case 'submit':
      return loader ? (
        <div className="main-loader">
          <div className="loader"></div>
        </div>
      ) : (
        <Submit func={application2} {...props} />
      );

    default:
      return null;
  }
};
// export const renderd = () => (
//   application4()
// );
export default MultiStepForm;
