import React, { useState, useEffect } from 'react';
import rightarrow from '../rightarrow.png';
// import InputMask from 'react-input-mask';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import Entity from '../Entity';
import Fundss from '../Fundss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import info from '../info.png';
import MenuSidebar from './MenuSidebar';
import NumberFormat from 'react-number-format';
import { infodetls } from './SaveInfo';
const Address = ({ func,fun2, setForm, formData, navigation }) => {
  const { next } = navigation;

  const { isMyFarm } = formData

  const saveandexit = () => {
    if (
      typeof formData.investmentType === 'undefined' ||
      formData.investmentType === '' ||
      formData.investmentType === 'Select' ||
      formData.investmentType === null
    ) {
      $('.investmentTypeerror').html('Please select the investment type ');
    } else if (
      typeof formData.totalInvestment === 'undefined' ||
      formData.totalInvestment === 0 ||
      formData.totalInvestment === '' ||
      formData.totalInvestment === null ||
      formData.totalInvestment.replace(/,/g, '') < 20000
    ) {
      $('.unitserror').html('Miminmum investment should be $20,000');
    } else if (
      typeof formData.totalInvestment === 'undefined' ||
      formData.totalInvestment === 0 ||
      formData.totalInvestment === '' ||
      formData.totalInvestment === null ||
      formData.totalInvestment.replace(/,/g, '') % 10000 !== 0
    ) {
      $('.unitserror').html('increase by multiples of $10,000');
    } else {
      func();
    }
  };

  const { investmentType, totalInvestment, fundCode } = formData;

  formData.fundCode = fundCode;
  //

  const brandId = '';

  function handleClick(e) {
    // formData.verification=""
    if (
      typeof formData.investmentType === 'undefined' ||
      formData.investmentType == '' ||
      formData.investmentType == 'Select' ||
      formData.investmentType == null
    ) {
      $('.investmentTypeerror').html('Select the type of application ');
    } else if (
      formData.fundCode === 'undefined' ||
      formData.fundCode === '' ||
      formData.fundCode === 'Select' ||
      formData.fundCode === null
    ) {
      $('.funderror').html('Please select investment fund');
    } else if (
      typeof formData.totalInvestment === 'undefined' ||
      formData.totalInvestment === 0 ||
      formData.totalInvestment === '' ||
      formData.totalInvestment === null ||
      formData.totalInvestment.replace(/,/g, '') < 20000
    ) {
      $('.unitserror').html(
        'Minimum investment amount $20,000, then increase by multiples of $10,000'
      );
    } else if (
      typeof formData.totalInvestment === 'undefined' ||
      formData.totalInvestment === 0 ||
      formData.totalInvestment === '' ||
      formData.totalInvestment === null ||
      formData.totalInvestment.replace(/,/g, '') % 10000 !== 0
    ) {
      $('.unitserror').html(
        'Minimum investment amount $20,000 then increase by multiples of $10,000'
      );
    } else {
      //   go("EntityOne")
      // formData.class = 'Jointhird';

      if (formData.investmentType === 'Joint') {

        formData.class = 'Jointhird';
        next();
        
      } else if (formData.investmentType === 'Company') {
        go('EntityOne');
        formData.class = 'Jointhird';
      } else {
        formData.class = 'Third';
        next();
      }
    }
  }

  function removeError() {
    $('.error').html('');
  }
  $('.numbers').on('paste', function (e) {
    $('.error').html('');
  });
  const { go } = navigation;

  

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });
  const addsymbol = '+'.sup();
  const sessionClear = (e) => {
    formData.userResidentialAddress = null;
    formData.userResidentialAddress2 = null;
    formData.countryyCode = null;
    sessionStorage.removeItem('street_number1');
    sessionStorage.removeItem('locality1');
    sessionStorage.removeItem('administrative_area_level_11');
    sessionStorage.removeItem('sublocality_level_11');
    sessionStorage.removeItem('postal_code1');
    sessionStorage.removeItem('premise1');
    sessionStorage.removeItem('route1');
    sessionStorage.removeItem('country1');
    sessionStorage.removeItem('countryCode1');
    sessionStorage.removeItem('street_number');
    sessionStorage.removeItem('locality');
    sessionStorage.removeItem('administrative_area_level_1');
    sessionStorage.removeItem('sublocality_level_1');
    sessionStorage.removeItem('postal_code');
    sessionStorage.removeItem('premise');
    sessionStorage.removeItem('route');
    sessionStorage.removeItem('residentialAddress1');
    sessionStorage.removeItem('country');
    sessionStorage.removeItem('countryCode');
    sessionStorage.removeItem('PostalAddress1');
    sessionStorage.removeItem('residentialAddress2');
    sessionStorage.removeItem('PostalAddress2');
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // if (formData.check2 === false) {
    //   if (
    //     typeof formData.investmentType === 'undefined' ||
    //     formData.investmentType == '' ||
    //     formData.investmentType == 'Select' ||
    //     formData.investmentType == null
    //   ) {
    //     $('.investmentTypeerror').html('Select the type of application ');
    //   }
    //   if (
    //     formData.fundCode === 'undefined' ||
    //     formData.fundCode === '' ||
    //     formData.fundCode === 'Select' ||
    //     formData.fundCode === null
    //   ) {
    //     $('.funderror').html('Please select investment fund');
    //   }
    //   if (
    //     formData.totalInvestment === 0 ||
    //     formData.totalInvestment === '' ||
    //     formData.totalInvestment < 20000
    //   ) {
    //     $('.unitserror').html(
    //       'Minimum investment amount $20,000, then increase by multiples of $10,000'
    //     );
    //   }
    //   //  if (
    //   //   formData.totalInvestment === 0 ||
    //   //   formData.totalInvestment === '' ||
    //   //   formData.totalInvestment.replace(/,/g, '') % 50000 !== 0
    //   // ) {
    //   //   $('.unitserror').html(
    //   //     'Minimum investment amount $250,000, then increase by multiples of $50,000'
    //   //   );
    //   // }
    // }
  });

  const [ticks, setTicks] = useState(false);
  // alert(ticks)
  formData.ticks = ticks;


  const savefunc=(value)=>{
    
    fun2(value);
  }
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>Step 1: Investment Details</h2>
        </div>

        <div className="form second_form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit  se_invff">
            <p className="save next " onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>

          <div className="mmyset">
            <Entity
              autoComplete="off"
              type="text"
              label=
              // "Select the type of investment entity"
              "What kind of application are you making?"
              className={
                investmentType === 'Select' ||
                  investmentType === undefined ||
                  investmentType === 'undefined' ||
                  investmentType === '' ||
                  investmentType === null
                  ? 'colorgrey '
                  : 'colorblack '
              }
              onClick={removeError}
              onChange={(e) => {
             
                setForm(e);
                removeError();
                sessionClear(e);
                // alert(e.target.value)
                savefunc(e.target.value);
              }}
              name="investmentType"
              value={investmentType}
            />
            <span className="error investmentTypeerror"></span>
          </div>
          <div>
            <Row>
              <Col  xs={12} >
                <label>
                  Have you made an investment with MyFarm in the
                  past 2 years in this same individual/joint account
                  name?
                  </label>
              </Col>
              <Col
                xs={4}
                disabled={formData.isPrimary == 1 ? false : true}>
                <div className="button_group space-bottom">
                  <div class="switch-field nospace">
                    <div className="check_YN_Bank swtich11">
                      <input
                        type="radio"
                        id="radio-one2"
                        className="yesno"
                        name="isMyFarm"
                        value="false"
                        onChange={(e) => {
                          formData.isMyFarm = "false";
                          setForm(e);
                          // removeError();
                        }}
                      />
                      <label
                        for="radio-one2"
                        className={`toogles postalAddressButton ${typeof isMyFarm === 'undefined' || isMyFarm === 'false'
                          ? 'actives'
                          : null
                          }`}
                        id="show"
                      >
                        No
                      </label>
                      <input
                        type="radio"
                        id="radio-two3"
                        name="isMyFarm"
                        value="true"
                        onChange={(e) => {
                          formData.isMyFarm = "true";

                          setForm(e);
                          // removeError();
                        }}
                      />
                      <label
                        for="radio-two3"
                        className={`toogles postalAddressButtons ${isMyFarm === 'true' ? 'actives' : null
                          }`}
                        id="hide"
                      >
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="">
            <Col xs={12}>
              <label>
                {/* Select which Investment fund you are applying for */}
                What investment opportunity are you applying for?

              </label>
            </Col>
            <Col xs={12}>
              <Fundss
                type="text"
                autoComplete="off"
                className={
                  fundCode === 'Select' ||
                    fundCode === undefined ||
                    fundCode === 'undefined' ||
                    fundCode === '' ||
                    fundCode === null
                    ? 'colorgrey'
                    : 'colorblack fundesss'
                }
                onClick={removeError}
                onChange={(e) => {

                  setForm(e);
                  removeError();
                }}
                name="fundCode"
                value={fundCode}
              />
              <span className="error funderror"></span>
            </Col>

            <br />
            <Col>
              {fundCode === 'EastPack Notes' ? (
                <p className='Lorem-Ipsum'>
                 <br/>EastPack Notes is an offer of unsecured, subordinated debt securities.  The Notes are issued by
                EastPack Limited, one of New Zealand’s largest kiwifruit post-harvest operators.<br/><br/>EastPack Notes offer a minimum interest rate of 8.5% p.a., or the New Zealand government 5 Year
bond rate +4.5%, which is reviewed annually. Interest is paid quarterly in arrears.<br/><br/>The note term is 5 years (but may be redeemed by EastPack after 3 years) with a minimum
investment of $20,000.<br/><br/>The Product Disclose Statement for this offer can be found <a className='termLink'  href="https://events.myfarm.co.nz/eastpack_pds/"  target="_blank" >here</a>, or by calling 0800 MYFARM (0800
693276).<br/><br/>Please ensure you read the PDS before completing your investment application.<br/><br/> 
                </p>
                // <>
                //   <p>
                //     My Farm MORTGAGE FUND LIMITED PARTNERSHIP FSP1000594 IS A 10% P.A RETURN
                //     <sup>+</sup> INVESTMENT* MANAGED BY My Farm CAPITAL PARTNERS.
                //   </p>
                //   <p>
                //     {' '}
                //     * Investment in the My Farm Mortgage Fund Limited Partnership is only available
                //     to wholesale investors as defined in the Financial Markets Conduct Act 2013.
                //   </p>
                //   <p>
                //     {' '}
                //     <sup>+</sup> For further information about the return and the risks please go to{' '}
                //     <a className="duvalpartners_Link" href="#">
                //       www.myfarmpartners.com
                //     </a>{' '}
                //     to request a copy of the Information Memorandum.
                //   </p>
                // </>
              ) : brandId === '2' ? (
                <p></p>
              ) : (
                ''
              )}
            </Col>
          </Row>

          <label>
            {/* Enter the $ amount you wish to apply for (minimum investment $20,000, then upwards in $10,000 amounts) */}
            How much would you like to invest?
             </label>
          {/* <InputMask */}
          <NumberFormat
            isNumericString={true}
            thousandSeparator={true}
            maxLength={14}
            autocomplete="off"
            className="numberss1 aa"
            // mask="999,999,999,99"
            maskChar={null}
            name="totalInvestment"
            value={totalInvestment}
            onKeyPress={removeError}
            onChange={(e) => {

              setForm(e);
              removeError();
            }}
            placeholder="Minimum investment amount $20,000 then increase by multiples of $10,000"
          />
          <span id="errmsg"></span>
          <span className="error unitserror"></span>

          <div className="fotter-btn second">
            <button type="submit" className="next se_inv" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Address;
