
/*eslint eqeqeq: "off"*/
import React, { useState, useEffect, useRef } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
// import download from '../download.png';
// import ItemForm from '../ItemForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../logo.png';
import swal from 'sweetalert';
import info from '../info.png';
import $ from 'jquery';
import MenuSidebar from './MenuSidebar';
import { infodetls } from './SaveInfo';


const Twentyone2 = ({
  func,
  setForm,
  formData,
  navigation,
  saveAndExist
}) => {
  const { termsss1, termsss2, futureInvestementOpportunities } = formData;
  // const { previous, next } = navigation;
  let JointAcount = sessionStorage.getItem('Jointacoount');
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    go('submit');
  };

  
  let applicationType = sessionStorage.getItem('applicationType');

  const saveandexit = () => {
    func();
  };

  // function previousHandler() {
  //   formData.class = "Eight"

  //   if (formData.investmentType === "Joint") {
  //       go("Jointtwelve")
  //   }
  //   else {
  //       go("Eight")
  //   }
  //}

  let previousHandler = () => {
    // formData.termsss1 = false
    // formData.termsss2 = false

    if (applicationType === 'Trust') {
      go('Jointfivteen');
    } else {
      go('Twentyone1');
      // previous();
    }
  };
  // useEffect(()=>{
  //   alert("hii");
  //   formData.termsss1 = false;
  //   formData.termsss2 = false;
  // },[]);

  useEffect(() => {
    $(window).scrollTop(0);

  },[]);
  function handleClick(e) {

    formData.termsss1 = termsss1;
    formData.termsss2 = termsss2;
    formData.futureInvestementOpportunities = futureInvestementOpportunities;

    if(valid==false && formData.termsss3==false){
      $('.linkerror').html(
        'Please click on the link to view the PDS'
      );
    }else{
      if (
        (formData.investmentType == 'Joint' &&
          formData.termsss1 && formData.termsss2 && formData.termsss3) ||
        (formData.investmentType == 'Individual' &&
          formData.termsss1 && formData.termsss2 &&formData.termsss3)
      ) {
  
        formData.class = 'Twentyone11';
        go('Twentyone11');
      } else {
        if (formData.investmentType == 'Joint') {
          $('.errorghgh').html(
            'Please read the terms and conditions and privacy policy and tick all boxes to confirm and agree'
          );
        } else {
          $('.errorghgh').html(
            'Please read the terms and conditions and privacy policy and tick both boxes to confirm and agree'
          );
        }
      }
    }

  
  }
  function removeError() {
    $('.error').html('');
  }
  const { go } = navigation;

  const [scrollMove, setScrollMove] = useState(true)
  const myRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entry) => {
      const ee = entry[0];
      console.log("entry", ee.isIntersecting);
      if (ee.isIntersecting === true) {
        setScrollMove(false)
      }
    })
    observer.observe(myRef.current)
  }, [])

  const tickerror = () => {
    if (scrollMove == true) {
      $('.ticks').html(
        'Please read the terms & conditions and privacy policy then tick the boxes to confirm and continue.'
      );
    }
  };
  
  const[valid, SetValid]=useState(false)
const clickLink=()=>{
  // window.open("https://events.myfarm.co.nz/eastpack_pds/")
  SetValid(true)
  removeError();
}

const error=()=>{
  if(valid==false){
    $('.linkerror').html(
      'Please click on the link to view the PDS'
    );
  } else{
    $('.linkerror').html(
      ''
    );

  }
}
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Joint" ?
              <h2>Step 6: Terms and Conditions</h2> :
              <h2>Step 5: Terms and Conditions</h2>
          }
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />

          {!JointAcount ? (
            <>
              <div className="save-exit se_invff">
                <p className="save next" onClick={saveandexit}>
                  SAVE DATA{' '}
                </p>
                <img src={info} onClick={infodetls} className="info" />
              </div>
            </>
          ) : null}
          <div className="grid-container">
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item "></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item active"></div>
          </div>
          <p className="policy_font">
            Please read and accept the below Terms & Conditions and Privacy Policy.
          </p>
          {/* <Row>
            <Col md={12}>
              <div id="scrollsss" className='entitytextareas '
                rows="2"
                cols="80">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                <p className="readMoreTarget"><span ref={myRef} className="entitytextareas"> Read More Target</span></p>
                </div>
            </Col>
          </Row> */}
          <Row>
            <Col md={12}>
              <div id="scrollsss" className='entitytextareas terms_notes '
                rows="2"
                cols="80">
                  <h1>TERMS AND CONDITIONS FOR EASTPACK NOTES</h1>
                  <h5>The Product Disclosure Statement is available by contacting MyFarm at 0800 693276, or <a href='mailto:investments@myfarm.co.nz'>investments@myfarm.co.nz</a>, or on www.business.govt.nz/disclose offer number 13417.</h5>
    <b className='startup'>I/We confirm that:</b>
    <div className='cont_list'>
      <ul>
        <li>I/We have received, read and understood a copy of the Product Disclosure Statement dated 4 November 2022 (“PDS”). </li>
        <li>I/We acknowledge the Notes will be issued in accordance with the terms of the PDS, the Master Trust Deed and the Supplemental Trust Deed.</li>
        <li>Investment decisions are very important, and it has been made clear to me/us that I/we am/are free to take such other professional advice as is necessary.  I/We have been provided with all the relevant information required to make the investment decision and have taken any advice that is appropriate.
</li>
        <li><b>I/We accept that I/We must make full payment totalling NZ $1.00 per Note (100% of investment) with this application.
</b></li>
        <li>I/We confirm that the owner/entity who takes up the investment, can make the payment as detailed above.
</li>
        <li>I/We acknowledge that the completed application once submitted to EastPack and/or MyFarm, cannot be withdrawn without authorisation by EastPack and/or MyFarm.</li>
        <li>I/We acknowledge that EastPack and/or MyFarm reserves the right to accept any application and reject any application subject to its discretion.</li>
        <li>If the offer for the Notes is oversubscribed EastPack may scale any application at its discretion, but will not scale any application to below $20,000 (or to an amount that is not a multiple of $10,000)
</li>
        <li>Under the terms of the Unsolicited Electronic Messages Act 2007, I/we provide my/our consent to receiving commercial electronic messages for the purpose of that Act.
</li>
        <li>I/We agree that funds paid by me/us to EastPack for the purposes of this Application will be held in trust by EastPack and I/We irrevocably authorise EastPack to:
<ul className='list_inner'>
<li>Return those funds to me/us in accordance with my/our written instructions (and subject to compliance with any AML requirements) if the offer described in the PDS does not proceed; or
</li>
<li>Release those funds to EastPack immediately following the issue of the Notes.
</li>
</ul>
</li>
        <li>I/We agree that the terms of Notes described in the PDS, Master Trust Deed and Supplemental Trust Deed, or as otherwise disclosed to us will take effect from the date of issue of the Notes under the PDS and from that date I/We, together with all other applicants, will be a Noteholder and will be bound by the terms of the Notes.
</li>
        <li>I/we have received this Offer in New Zealand and am/are therefore eligible to participate in the Offer.
</li>
        <li><b>Privacy Act 2020:</b> The personal information that you provide to us by way of this application and any subsequent personal information you may provide in the future may be used by EastPack and/or MyFarm (and its related entities) for the purposes of arranging and managing your investment and to contact you in relation to your investment. You authorise EastPack and/or MyFarm to disclose your personal information to any third parties as required to perform services on your behalf; to regulatory bodies or law enforcement agencies as may be required by law; and otherwise, to meet the Company’s regulatory obligation. You have the right to access all personal information that EastPack and/or MyFarm holds about you. If any of such information is incorrect you have the right to have it corrected.
</li>
      </ul>
<div className='Privacy_Policy'>
  <p>We collect personal details from you, including:</p>
  <ul>
    <li>Your Name</li>
    <li>Contact information</li>
    <li>Location</li>
  </ul>
  <p>We collect your personal details in order to deliver further information related to investment opportunities and investment research of interest.</p>
  <p>Providing your details is optional but if you don’t provide your phone number or postal address, we'll be unable to send hard copies of our Investment Information or speak directly with you about an investment of interest</p>
  <p>You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be corrected if you think it is wrong. If you’d like to ask for a copy of your information, or to have it corrected, please contact us directly at <a href="mailto:investments@myfarm.co.nz">investments@myfarm.co.nz</a> or 0800 MYFARM (0800 693276).</p>
</div>
    </div>
                <p className="readMoreTarget"><span ref={myRef} className="entitytextareas"> Read More Target</span></p>
                </div>
            </Col>
          </Row>
          <Row>
            {scrollMove == false ? '' : <span className="error ticks"></span>}
          </Row>
          <Row>
            <Col>
              <div className="checkbox-my left">
                <label className="containers font-fami" onClick={() => tickerror()}>
                  I have read and agree to the Terms & Conditions.
                  <input
                    type="checkbox"
                    disabled={scrollMove}
                    // id="paymentss"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    // className="flirts"
                    checked={(formData.termsss1 === true) ? true : false}
                    value={formData.termsss1}
                    // disabled={scrolls===40?false:true}
                    name="termsss1"
                  />
                  <span className="checkmark"></span>
                </label>
                {/* <span className="error errorghgh"></span> */}

                <div className="error results pl-34"></div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="checkbox-my left">
                <label className="containers font-fami" onClick={() => tickerror()}>
                  I have read and agree to the Privacy Policy.
                  <input
                    type="checkbox"
                    id="payments"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    disabled={scrollMove}
                    className="flirt"
                    checked={(formData.termsss2 === true) ? true : false}
                    value={formData.termsss2}
                    name="termsss2"
                  />
                  <span className="checkmark"></span>
                </label>
                <div className="error results pl-34"></div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="checkbox-my left chrk">
                {/* <label className="containers font-fami" onClick={() => tickerror()}> */}
                <label className="containers font-fami" >
                <span className="herClas"  onClick={()=>error()} >I have received, read and understand the Product Disclosure Statement, which can be found</span> <a className='termLink' onClick={clickLink} href="https://events.myfarm.co.nz/eastpack_pds/" target="_blank" >here.</a>
                  
            <span onClick={()=>error()}>
            <input
                    type="checkbox"
                    id="payments"
                    disabled={valid==false?true:false}
                    
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    // disabled={scrollMove}
                    className="flirt"
                    checked={(formData.termsss3 === true) ? true : false}
                    value={formData.termsss3}
                    name="termsss3"
                  />
                                    <span className="checkmark"></span>

            </span>
                  
                </label>
                <div className="error linkerror"></div>
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="checkbox-my left">
                <label className="containers font-fami" onClick={() => tickerror()}>
                I wish to receive emails about future MyFarm investment opportunities.
                  <input
                    type="checkbox"
                    id="payments"
                    className="flirt"
                    onChange={(e) => {
                      setForm(e);
                      removeError();
                    }}
                    checked={(formData.futureInvestementOpportunities === true) ? true : false}
                    value={formData.futureInvestementOpportunities}
                    name="futureInvestementOpportunities"
                  />
                  <span className="checkmark"></span>
                </label>

                <div className="error results pl-34"></div>
              </div>

            </Col>
          </Row>
          <span className="error errorghgh"></span>
          <div className="fotter-btn">
            <button className="preview" onClick={previousHandler}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            <button type="submit" className="next setwidht" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Twentyone2;
