import React from 'react';
// import Swal from 'sweetalert2';
import logo from '../pages/replica_logo.png';

const BiometricMsg = () => {

  return (
    <div className='pppp'>
      <div className="welcome__page register_page bioooometric">
       
        <div className="wel-come_content">
        <div className="welcome-header">
          {/* <a href="#">
            <img src={logo} alt="" />
          </a> */}
          <span>  <img src={logo} alt="" /></span>
        </div>
         <div className='bio_texdt'>
         <h3>Thank you for completing the biometrics process.</h3>
          <p>
            Please return to the other internet browser window in which you were completing your
            application, this should still be open, and you can resume your investment application.
          </p>
          <p>
          The window/browser you were previously in should still be open. However, if for some reason you are unable to find it, you can click on the link to resume your application that was provided in the email we previously sent to you to verify your email address.
           
            {/* If for some reason that window is no longer open, you can click on this <a  href="/dashboard">link</a>to login to
            your application again. */}
          </p>
         </div>
          <div className="fotter-btn registercss"></div>
        </div>
      </div>
    </div>
  );
};

export default BiometricMsg;
