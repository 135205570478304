/*eslint eqeqeq: "off"*/
import React, { useState, useEffect, useRef } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';

import swal from 'sweetalert';
import info from '../info.png';
import $ from 'jquery';
// import axios from 'axios';
import MenuSidebar from './MenuSidebar';
// import logo from '../logo.png';
import logo from '../replica_logo.png';
// import logoBlack  from '../pages/replica_logo.png';

// import { multisavedata } from '../../auth/Configuration';
import { infodetls } from './SaveInfo';


const Twentyone1 = ({ func, setForm, formData, navigation, saveAndExist }) => {

  // const [imageLoader, setImageLoader] = useState();
  const [namefull, setNamefull] = useState(
    formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.fullName !== undefined &&
      formData.fullName !== 'undefined'
      ? formData.fullName.split(' ')
      : ''
  );
  // let text2 = ['world!', 'ny', 'jjij'];
  // let result = text2[-1];

  formData.firstName = namefull[0];

  if (namefull.length > 1) {
    formData.lastName = namefull[namefull.length - 1];
  }

  if (namefull.length > 2) {
    formData.middleName = namefull
      .slice(1, namefull.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  const [namefull1, setNamefull1] = useState(
    formData.secondholder !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined
      ? formData.secondholder.split(' ')
      : ''
  );
  

  formData.firstName1 = namefull1[0];

  if (namefull1.length > 1) {
    formData.lastName1 = namefull1[namefull1.length - 1];
  }

  if (namefull1.length > 2) {
    formData.middleName1 = namefull1
      .slice(1, namefull1.length - 1)
      .join()
      .replace(/,/g, ' ');
  }

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };
  const mySignatureRefAderence = useRef(null);
  // const mySignaturejointHolderDetails = useRef(null);

 

  const saveandexit = () => {
    func();
  };

  function previousHandler() {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true')&&formData.investmentType =="Joint") {
      formData.class="Third"
     go('Jointsix')
     
     
    }else if((formData.isMyFarm === true || formData.isMyFarm === 'true')&&formData.investmentType =="Individual"){
      formData.class="Third"
      go('Third')
    } else {
      formData.class = "Eight"

      if (formData.investmentType === "Joint") {
        go("Jointtwelve")
      }
      else {
        go("Eight")
      }

    }
   
  }

  // let previousHandler = () => {
  //   if (applicationType === 'Trust') {
  //     go('Jointfivteen');
  //   } else {
  //     go('Twentyone');
  //     // previous();
  //   }
  // };
  useEffect(() => {
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate2').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate21').text(val);
      formData.certificateB_Date = val;
    });
    $(document).ready(function () {
      var date = new Date();
      var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
      $('#printDate1').text(val);
      formData.certificateB_Date = val;
    });
    $('#terms').scroll(function () {
      if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
        $('#payment').removeAttr('disabled');
        $('#term_conditionTest1').val('true');
      }
    });
    $('#termss').scroll(function () {
      if ($(this).scrollTop() === $(this)[0].scrollHeight - $(this).height()) {
        $('#payments').removeAttr('disabled');
        $('#term_conditionTest2').val('true');
      }
    });
    // $(document).ready(function () {
    //   alert(payment)
    //   let payment = "term";
    //   $(".yesno").click(function () {
    //     payment = $(this).val();
    //     alert(payment)
    //   });
    // });

    //   $(document).ready(function(){
    //     $('input[type="checkbox"]').click(function(){
    //         if($(this).prop("checked") == true){
    //             // $(".error").html("");
    //         }
    //         else if($(this).prop("checked") == false){
    //             // $("#result").html("Checkbox is unchecked.");
    //         }
    //     });
    // });

    $('#payment').click(function () {
      let test = $('#term_conditionTest1').val();
      if (
        $('#terms').scrollTop() + $('#terms').innerHeight() >= $('#terms')[0].scrollHeight ||
        test === 'true'
      ) {
        return true;
      } else {
        // $(".result").html(
        // 	"Please read and review terms and conditions to proceed. You will need to scroll to the bottom before you can accept."
        // );
        // return false;
        return true;
      }
    });
    $('#payments').click(function () {
      let test = $('#term_conditionTest2').val();
      if (
        $('#termss').scrollTop() + $('#termss').innerHeight() >= $('#termss')[0].scrollHeight ||
        test === 'true'
      ) {
        return true;
      } else {
        // $(".results").html(
        // 	"Please read and review privacy policy to proceed. You will need to scroll to the bottom before you can accept."
        // );
        // return false;
        return true;
      }
    });
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function removeError() {
    $('.error').html('');
  }

  const Clear = () => {
    var date = new Date();
    var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    $('#printDate').text(val);
    const setValues = localStorage.getItem('setValues');
    let adherenceSign = mySignatureRefAderence.current.clear();
    formData.adherenceSign = adherenceSign;
  };
  // const Clear12 = () => {
  //   var date = new Date();
  //   var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //   $('#printDate').text(val);
  //   // formData.certificateA_Date = val;
  //   const setValues = localStorage.getItem('setValues');
  //   let mySignaturejointHolderDetails1 = mySignaturejointHolderDetails.current.clear();
  //   formData.mySignaturejointHolderDetails = mySignaturejointHolderDetails1;
  // };

  // function secondaryjointsign() {
  //   var date = new Date();
  //   var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //   $('#printDate').text(val);
  //   formData.certificateAD_Date = val;

  //   const setValues = localStorage.getItem('setValues');
  //   let mySignaturejointHolderDetails1 = mySignaturejointHolderDetails.current.toDataURL();

  //   let isEmpty = mySignaturejointHolderDetails.current.isEmpty();

  //   let data = {
  //     columnName: 'secAdherenceSign',
  //     fileName: 'secAdherenceSign',
  //     document: mySignaturejointHolderDetails1,
  //     userCode: formData.userCode
  //   };

  //   setImageLoader(true);

  //   if (setValues === 'Yes') {
  //     axios
  //       .post(`${multisavedata}/genimageurl`, data, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.mySignaturejointHolderDetailsUrl = res.data.url;
  //         formData.result = res.status;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //           formData.class = 'Twentyone';
  //           go('Twentyone2');
  //         } else {
  //           setImageLoader(false);
  //         }
  //       });
  //   } else {
  //     localStorage.setItem('Step20', 'Step20');
  //     setImageLoader(true);

  //     axios
  //       .post(`${multisavedata}/genimageurl`, data, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.mySignaturejointHolderDetailsUrl = res.data.url;

  //         if (res.status === 200) {
  //           setImageLoader(false);
  //           formData.class = 'Twentyone';
  //           go('Twentyone2');
  //           setImageLoader(false);
  //         }
  //       });
  //   }
  // }
  function handleClick(e) {
    formData.termsss1 = false
      formData.termsss2 = false
      formData.termsss3=false
      formData.futureInvestementOpportunities =false
    formData.class = 'Twentyone';
    go('Twentyone2');

  }
  // function handleClick(e) {
  //   if (formData.investmentType === 'Joint') {
  //     if (
  //       OptionFund12 === 'Select' ||
  //       OptionFund12 === null ||
  //       OptionFund12 === 'undefined' ||
  //       OptionFund12 === '' ||
  //       OptionFund12 === undefined
  //     ) {
  //       //
  //       $('.Role').html('Please select your role');
  //     } else if (
  //       OptionFund121 === 'Select' ||
  //       OptionFund121 === null ||
  //       OptionFund121 === 'undefined' ||
  //       OptionFund121 === '' ||
  //       OptionFund121 === undefined
  //     ) {
  //       $('.Role1').html('Please select your role');
  //     } else {
  //       formData.terms = '';
  //       formData.termss = '';
  //       var date = new Date();
  //       var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //       $('#printDate').text(val);
  //       formData.certificateAD_Date = val;

  //       const setValues = localStorage.getItem('setValues');
  //       let adherenceSign = mySignatureRefAderence.current.toDataURL();

  //       let isEmpty = mySignatureRefAderence.current.isEmpty();
  //       let isEmpty1;
  //       if (formData.investmentType === 'Joint') {
  //         isEmpty1 = mySignaturejointHolderDetails.current.isEmpty();
  //       }

  //       let data = {
  //         columnName: 'adherenceSign',
  //         fileName: formData.investmentType === 'Joint' ? 'priAdherenceSign' : 'adherenceSign',
  //         document: adherenceSign,
  //         userCode: formData.userCode
  //       };
  //       if (isEmpty) {
  //         $('.signError').html('Please sign');
  //       } else if (isEmpty1 && formData.investmentType === 'Joint') {
  //         $('.signError1').html('Please sign');
  //       } else {
  //         setImageLoader(true);
  //         if (setValues === 'Yes') {
  //           axios
  //             .post(`${multisavedata}/genimageurl`, data, {
  //               headers: { Authorization: `Bearer ${formData.token}` }
  //             })
  //             .then((res) => {
  //               formData.adherenceSignUrl = res.data.url;
  //               formData.result = res.status;
  //               if (res.status === 200) {
  //                 setImageLoader(false);

  //                 if (formData.investmentType === 'Joint') {
  //                   secondaryjointsign();
  //                 } else {
  //                   formData.class = 'Twentyone';
  //                   go('Twentyone2');
  //                 }
  //               } else {
  //                 setImageLoader(false);
  //               }
  //             });
  //           // go("Twentyone");
  //         } else {
  //           localStorage.setItem('Step20', 'Step20');
  //           setImageLoader(true);

  //           axios
  //             .post(`${multisavedata}/genimageurl`, data, {
  //               headers: { Authorization: `Bearer ${formData.token}` }
  //             })
  //             .then((res) => {
  //               formData.adherenceSignUrl = res.data.url;

  //               if (res.status === 200) {
  //                 setImageLoader(false);

  //                 if (formData.investmentType === 'Joint') {
  //                   secondaryjointsign();
  //                 } else {
  //                   formData.class = 'Twentyone';
  //                   go('Twentyone2');
  //                 }
  //               }
  //             });
  //         }
  //       }
  //     }
  //   } else {
  //     if (
  //       OptionFund12 === 'Select' ||
  //       OptionFund12 === null ||
  //       OptionFund12 === 'undefined' ||
  //       OptionFund12 === '' ||
  //       OptionFund12 === undefined
  //     ) {
  //       $('.Role').html('Please select your role');
  //     } else {
  //       formData.terms = '';
  //       formData.termss = '';
  //       var date = new Date();
  //       var val = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
  //       $('#printDate').text(val);
  //       formData.certificateAD_Date = val;

  //       const setValues = localStorage.getItem('setValues');
  //       let adherenceSign = mySignatureRefAderence.current.toDataURL();

  //       let isEmpty = mySignatureRefAderence.current.isEmpty();

  //       let data = {
  //         columnName: 'adherenceSign',
  //         fileName: formData.investmentType === 'Joint' ? 'priAdherenceSign' : 'adherenceSign',
  //         document: adherenceSign,
  //         userCode: formData.userCode
  //       };
  //       if (isEmpty) {
  //         $('.signError').html('Please sign');
  //       } else {
  //         setImageLoader(true);
  //         if (setValues === 'Yes') {
  //           axios
  //             .post(`${multisavedata}/genimageurl`, data, {
  //               headers: { Authorization: `Bearer ${formData.token}` }
  //             })
  //             .then((res) => {
  //               formData.adherenceSignUrl = res.data.url;
  //               formData.result = res.status;
  //               if (res.status === 200) {
  //                 setImageLoader(false);
  //                 formData.class = 'Twentyone';
  //                 go('Twentyone2');
  //               } else {
  //                 setImageLoader(false);
  //               }
  //             });
  //           // go("Twentyone");
  //         } else {
  //           localStorage.setItem('Step20', 'Step20');
  //           setImageLoader(true);

  //           axios
  //             .post(`${multisavedata}/genimageurl`, data, {
  //               headers: { Authorization: `Bearer ${formData.token}` }
  //             })
  //             .then((res) => {
  //               formData.adherenceSignUrl = res.data.url;

  //               if (res.status === 200) {
  //                 setImageLoader(false);

  //                 if (formData.investmentType === 'Joint') {
  //                   formData.class = 'Twentyone';
  //                   go('Twentyone2');
  //                 } else {
  //                   formData.class = 'Twentyone';
  //                   go('Twentyone2');
  //                 }
  //               }
  //             });
  //         }
  //       }
  //     }
  //   }
  // }


  const { go } = navigation;
  // individual
  let usrcode =formData.userCode;
  let mycode =usrcode.split('-')[1];
  // alert(mycode)
  let userFirst=formData.fullName;
  let firstLetterName=userFirst.charAt(0)
  // console.log("dhgjgjg",firstLetterName)
  let LastName=formData.lastName;
  // alert(LastName)

  //joint
  let JointLname=formData.lastName1;
  
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div class="heading__top">
          {
            formData.investmentType =="Joint"?
            <h2>Step 6: Terms and Conditions</h2>:
            <h2>Step 5: Terms and Conditions</h2>
          }
          
        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>
          <div className="spceimg ml-50">
            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />
          </div>
          
          <p>In the following pages, you will be asked to read and review the Terms and Conditions of the
offer, before reviewing and submitting your application.<br/><br/>Once you have submitted your application, full payment is required to complete your
application.<br/><br/>After clicking “submit application”, you will receive an email with the below bank account
details for the payment:<br/>
</p>
<div>
 <div className='term_card'>
 <p className='lbe1'> Bank Account Name:</p>
  <p className='lbe2'>  EastPack Limited</p>
 </div>
 <div className='term_card'>
 <p className='lbe1'> Bank Account Number:</p>
  <p className='lbe2'> 02-0466-0292893-04</p>
 </div>
 <div className='term_card'>
 <p className='lbe1'> Reference:</p>
  <p className='lbe2'> {mycode} {formData.investmentType=="Individual"? <span className='termstt'> { firstLetterName !=null && firstLetterName !="" &&firstLetterName !=undefined ? firstLetterName : ""}  {LastName !=null && LastName !="" && LastName !=undefined ?LastName :"" } </span> : <span className='termstt'>{LastName !=null && LastName !="" && LastName !=undefined ?LastName :"" } { JointLname !=null && JointLname !=undefined && JointLname !="" ?"&" :"" }  { JointLname !=null && JointLname !=undefined && JointLname !="" ?JointLname :"" }</span>}</p>
 </div>


</div>

          <div className="fotter-btn">
            <button className="preview" onClick={previousHandler}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>

            <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button>
          </div>
        </div>
      </div >
    </>

  );
};

export default Twentyone1;
