import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import LoginButton from '../auth/LoginButton';
import logo from '../pages/replica_logo.png';
import rightarrow from '../pages/rightarrow.png';
// import { useAuth0 } from '@auth0/auth0-react';

export default function Register() {

  return (
    <div className="main_reg_page bg_bacv">
      <div className="welcome__page">
        <div className="welcome-header">
          <a href="#">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className="wel-come_content">
          <h3>Investment Application Form</h3>
          <div className="grid-container">
            <div className="grid-item active"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
            <div className="grid-item"></div>
          </div>
          <p>Welcome to the MyFarm online application.</p>
          <p>
            You'll need to complete this online application and provide information about
            yourself, and any joint account investment holders.
          </p>
          <p>To complete your application, you will need the following information on hand:</p>
          <ul className="welcome-space">

            <li>Photo ID (e.g. Passport)</li>
            <li>Bank Account Details (e.g. Bank statement)</li>
            <li>IRD Number</li>
          </ul>
          <div className="fotter-btn   registercss regiiister">
          <RouterLink to="/register2">
              START NEW APPLICATION <img src={rightarrow} className="rightarrow" alt="" />
            </RouterLink>
            <div className='loginbuttton'>
              <LoginButton />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}





