import { Navigate, useRoutes } from 'react-router-dom';
// layouts
// import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Register from './pages/Register';
import Register2 from './pages/Register2';
import NotFound from './pages/Page404';
import BiometricMsg from './pages/BiometricMsg';
import LoginURLpage from './pages/LoginURLpage';

// ----------------------------------------------------------------------

export default function Route2() {
  return useRoutes([
  
    {
      path: '/',
      // element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Navigate to="/register" /> },
        { path: 'dashboard', element: <Navigate to="/register" /> },
        { path: 'register', element: <Register /> },
        { path: 'register2', element: <Register2 />},
        {path:'userlogin', element:<LoginURLpage/>},
        // { path: 'dashboard/biometric/complete', element: <BiometricMsg/> },idscan/complete
        { path: 'dashboard/idscan/complete', element: <BiometricMsg/> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/register" /> },
        { path: '*', element: <Navigate to="/register" /> }
      ]
    },
    { path: '*', element: <Navigate to="/register" replace /> }
  ]);
}
