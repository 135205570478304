/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
// import attch from '../attch.png';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import { Dropdown } from 'semantic-ui-react';
import axios from 'axios';
import info from '../info.png';
import logo from '../logo.png';
import $ from 'jquery';
import swal from 'sweetalert';
import InputMask from 'react-input-mask';
// import { Form, Input, Button, Checkbox, message, Upload } from 'antd';
// import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import ReactFlagsSelect from 'react-flags-select';
// import { validateIrd } from '../IrdValidator';
// import { Switch } from 'antd';
import Rwt from '../Rwt';
import Nrwt from '../Nrwt';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import Swal from 'sweetalert2';
import { infodetls } from '../Individual/SaveInfo';
import UploadImage from '../Individual/UploadImage';


const Four = ({ setForm, formData, navigation, saveAndExist, infodetls11, func }) => {
  const {
    rwtValue,
    checkboxx,
    rwt,
    placeOfBirth,
    isPostalAdd11,    
    countryOfBirth,    
    userIrdNumber,   
    residingCountry,
  } = formData;
  const { previous, next } = navigation;
  let countrtyCode = [
    'NZ',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RO',
    'RU',
    'RW',
    'KN',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VI',
    'YE',
    'ZM',
    'ZW'
  ];

  // formData.secondholder = secondholder.trim();
  // formData.fullName = fullName.trim();

  const [selected, setSelected] = useState(
    formData.countryOfBirth !== null &&
      formData.countryOfBirth !== '' &&
      formData.countryOfBirth !== 'undefined' &&
      formData.countryOfBirth !== undefined
      ? formData.countryOfBirth
      : 'NZ'
  );
  const [selected1, setSelected1] = useState(
    formData.residingCountry !== null &&
      formData.residingCountry !== '' &&
      formData.residingCountry !== 'undefined' &&
      formData.residingCountry !== undefined
      ? formData.residingCountry
      : 'NZ'
  );
  const [selected2, setSelected2] = useState(
    formData.tinResidential !== null &&
      formData.tinResidential !== '' &&
      formData.tinResidential !== 'undefined' &&
      formData.tinResidential !== undefined
      ? formData.tinResidential
      : ''
  );

  formData.countryOfBirth = selected;
  formData.residingCountry = selected1;

  // const [file, setFile] = useState(null);
  // async function uploadWithJSON() {
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   next();
  //   localStorage.setItem('userIrdNumberFile', data.img64);
  // }
  useEffect(() => {
    $(document).ready(function () {
      $('#hide').click(function () {
        $('#showmy').hide();
        $('#hidemy').show();
      });

      $('#show').click(function () {
        $('#hidemy').hide();
        $('#showmy').show();
      });

      // $("#select_flag_button").click(function () {
      $('.flagsave').click(function () {
        let country = $('.counrtyselext #select_flag_button > span > span').text();
        localStorage.setItem('countryOfBirth', country);
        // formData.countryOfBirth = country;
        let countryOfCi = $('.counrtyselext1 #select_flag_button > span > span').text();

        //  formData.countryOfCitizenship = countryOfCi;
        localStorage.setItem('countryOfCitizenship', countryOfCi);
        let countryOfRes = $('.counrtyselext2 #select_flag_button > span > span').text();
        // formData.residingCountry =countryOfRes ;
        localStorage.setItem('residingCountry', countryOfRes);
        // alert(formData.residingCountry  + "  "  + formData.residingCountry)
      });
      $(document).ready(function () {
        // $("#select_flag_button").click(function () {
      });
      $(document).ready(function () {
        // $("#select_flag_button").click(function () {
        let isNZCitizen = true;
        localStorage.setItem('isNZCitizen', isNZCitizen);
        $('.yesno').click(function () {
          isNZCitizen = $(this).val();
          localStorage.setItem('isNZCitizen', isNZCitizen);
        });
      });
    });
    $('.toogles').click(function () {
      $('.toogles').removeClass('actives');
      $(this).addClass('actives');
    });
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
  }, []);

  function handleClick(e) {
    formData.residingCountry = selected1;
    formData.tinResidential = selected2;
    if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
      $('.rwterror').html('Please select tax rate')
    }
    if (formData.placeOfBirth === null|| formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
      $('.placeofbirtherror').html('Please enter place of birth ')
    }
    if (formData.userIrdNumber === null|| formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
      $('.userIrdNumbers').html('Please enter your IRD number')
    }
    if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
      $('.funderror').html('Please confirm your tax type')
    }
    if (formData.placeOfBirth === null || formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
      $('.placeofbirtherror').html('Please enter place of birth ')
    }
    if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
      $('.exemptprooferror').html('Please attach proof of exemption')
    }
    
    if (formData.placeOfBirth === null || formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
      $('.placeofbirtherror').html('Please enter place of birth ')
    }
    else if (formData.userIrdNumber === null || formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
      $('.userIrdNumbers').html('Please enter your IRD number')
    }
    else if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
      $('.funderror').html('Please confirm your tax type')
    }
    else if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
      $('.rwterror').html('Please select tax rate')
    } else if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
      $('.exemptprooferror').html('Please attach proof of exemption')
    }

    else {
      next();
    }
    // formData.formData.tinResidential = selected2


  }

  // function removeError() {
  //   $('.error').html('');
  // }
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '4';
    // swal({
    //   text: "Your application saved",
    // });
    go('submit');
  };



  infodetls11 = () => {
    Swal.fire({
      html:
        'For help selecting your correct tax rate, please click on this link to visit the ' +
        '<a href="https://www.ird.govt.nz/income-tax/withholding-taxes/resident-withholding-tax-rwt/using-the-right-rwt-tax-rate" target="_blank">IRD website</a> '
    });
  };

  const { go } = navigation;

  var hasPostal = false;
  if (formData.postalad == 'false') {
    hasPostal = true;
  }

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });

  const saveandexit = () => {
    func();
  };

  function checkboxchange(e) {
    const { name, value } = e.target;
  }

  useEffect(() => {
    if (isPostalAdd11 == 0 || isPostalAdd11 == 'true') {
      formData.tinResidential = '';
      formData.tinNumber = '';
      formData.tinreason = '';
    }
  });
  const [namefull1, setNamefull1] = useState(
    formData.secondholder ? formData.secondholder.trim().split(' ') : ''
  );
  let text2 = ['world!', 'ny', 'jjij'];
  let result = text2[-1];
  if (
    formData.secondholder != null &&
    formData.secondholder != '' &&
    formData.secondholder != 'undefined'
  ) {
    formData.firstName12 = namefull1[0];
    if (namefull1.length > 1) {
      formData.lastName12 = namefull1[namefull1.length - 1];
    }

    if (namefull1 && namefull1.length > 2) {
      formData.middleName12 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {

    // Menusidebar Validation Start
    if (formData.check === false) {
      if ((formData.rwtValue == null || formData.rwtValue == undefined || formData.rwtValue === "Select") && formData.rwt == "Resident Withholding Tax") {
        $('.rwterror').html('Please select tax rate')
      }
      if (formData.placeOfBirth === null|| formData.placeOfBirth == undefined || formData.placeOfBirth === "") {
        $('.placeofbirtherror').html('Please enter place of birth ')
      }
      if (formData.userIrdNumber === null|| formData.userIrdNumber == undefined || formData.userIrdNumber === "") {
        $('.userIrdNumbers').html('Please enter your IRD number')
      }
      if (formData.rwt !== "Resident Withholding Tax" && formData.rwt !== "Non Resident Withholding Tax" && formData.rwt !== "AIL 2%") {
        $('.funderror').html('Please confirm your tax type')
      }     
      if ((formData.examptProof === null || formData.examptProof === "" || formData.examptProof == undefined) && formData.rwt == "Resident Withholding Tax" && rwtValue === 'Exempt') {
        $('.exemptprooferror').html('Please attach proof of exemption')
      }

    }

    // Menusidebar Validation Endss
  })
  const [imageLoader, setImageLoader] = useState();
  const [documentFile64, setdocumentFile64] = useState('');
  // let { document } = documentFile64;
  const datetime = new Date();
  const [errror, setErrror] = useState();
  // async function uploadWithJSON4(file, e) {
  //   formData.examptProof = '';
  //   setErrror('');
  //   setdocumentFile64(file.name);
  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);

  //       reader.onerror = (error) => reject(error);
  //     });
  //   const data = {
  //     img64: await toBase64(file)
  //   };
  //   // next();
  //   setdocumentFile64(data.img64);
  //   if (
  //     data.img64 !== null &&
  //     data.img64 !== '' &&
  //     data.img64 !== 'undefined' &&
  //     data.img64 !== undefined
  //   ) {

  //     let datadocument = {
  //       columnName: 'examptProof',
  //       fileName: `examptProof`,
  //       document: data.img64,
  //       userCode: formData.userCode
  //     };

  //     setImageLoader(true);
  //     axios
  //       .post(`${multisavedata}/genimageurl`, datadocument, {
  //         headers: { Authorization: `Bearer ${formData.token}` }
  //       })
  //       .then((res) => {
  //         formData.examptProof = res.data.url;
  //         if (res.status === 200) {
  //           setImageLoader(false);
  //           removeErrorOnlyOne("exemptprooferror");

  //         } else {
  //           setImageLoader(false);
  //         }
  //       })
  //       .catch((error) => {
  //         setImageLoader(false);
  //       });
  //   }
  //   localStorage.setItem('irdNumberFile', data.img64);
  // }
  let labeltxt = 'Please attach proof of exemption (maximum file size is 2MB).';
  const columnName = 'examptProof';
  const fileName = `examptProof`;
  const valuedefine="exampt";

  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };


  function removeErrorOnlyOne(valueClass) {
    $(`.${valueClass}`).html('');
  }


  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>
            Step 3: Personal Information - {' '}
            {(formData.firstNameIndi != null ? formData.firstNameIndi : '') +
              ' ' +
              (formData.lastNameIndi != null ? formData.lastNameIndi : '')}
          </h2>{' '}
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff joint-holder">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>

          <Row className="country__w">
            <Col md={12}>
              <label>
                {/* Place of birth */}
                What city/town were you born in?
              </label>
              <ItemForm
                autoComplete="off"
                type="text"
                className="digitsOnly"
                onKeyPress={()=>removeErrorOnlyOne('placeofbirtherror')}
                name="placeOfBirth"
                value={placeOfBirth}
                onChange={(e) => {
                  setForm(e);
                  removeErrorOnlyOne('placeofbirtherror')
                }}
                placeholder="Enter town/city of birth"
              />
              <span className="error placeofbirtherror"></span>
            </Col>
            <Col className="FlagsSelectcss" md={12}>
              {' '}
              <label>
                {/* Country of birth */}
                What country were you born in?

              </label>
              <ReactFlagsSelect
                autoComplete="off"
                // className={selected === null || selected === undefined || selected === ""  ? "colorgrey" : "colorblack"}
                name="countryOfBirth"
                selected={selected}
                placeholder="Select"
                value={countryOfBirth}
                onChange={(e) => {
                  setForm(e);
                  // removeError();
                }}
                onSelect={(code) => setSelected(code)}
                countries={countrtyCode}
                defaultCountry={countryOfBirth}
              />
            </Col>
          </Row>
          <span className="error countryofbirtherror"></span>

          <Row className="fullinput"></Row>

          <Row className=" mrgns">
            <div className="fullspace pandingfinsh block">
              <Col lg={12} className="FlagsSelectcss">
                <div>
                  <label>
                    {/* Country of residence */}
                    What country do you live in?
                  </label>
                  <ReactFlagsSelect
                    autoComplete="off"
                    name="residingCountry"
                    selected={selected1}
                    value={residingCountry}
                    placeholder="Select"
                    onChange={(e) => {
                      setForm(e);
                      // removeError();
                    }}
                    onSelect={(code) => setSelected1(code)}
                    countries={countrtyCode}
                    defaultCountry={
                      residingCountry && residingCountry !== null
                        ? residingCountry.toUpperCase()
                        : null
                    }
                  />

                  <span className="error residingCountry"></span>
                </div>
              </Col>
              <Col lg={12}>
                <label>
                  {/* Please enter your IRD number */}
                  What is your New Zealand IRD number?
                </label>
                <InputMask
                  autoComplete="off"
                  mask="999-999-999"
                  maskChar={null}
                  lassName="full"
                  type="text"
                  name="userIrdNumber"
                  value={userIrdNumber}
                  maskPlaceholder={null}
                  onKeyPress={()=>removeErrorOnlyOne('userIrdNumbers')}
                  onChange={(e) => {
                    setForm(e);
                    removeErrorOnlyOne('userIrdNumbers')
                  }}
                  placeholder="xxx-xxx-xxx"
                />
                <span className="error userIrdNumbers"></span>
              </Col>
            </div>
          </Row>

          <Row className="">
            <Col xs={12}>
              <label className='d-inline'>What type of Resident Withholding Tax will you pay on your interest payments?
              </label>
              <img src={info} onClick={infodetls11} className="info myinfo trr" />
              {/* <span className="error file ml-0"></span> */}
            </Col>
            <Col xs={12}>
              <Rwt
                autoComplete="off"
                type="text"
                className={
                  rwt === 'Select' ||
                    rwt === undefined ||
                    rwt === 'undefined' ||
                    rwt === '' ||
                    rwt === null
                    ? 'colorgrey'
                    : 'colorblack'
                }
                onClick={()=>{removeErrorOnlyOne('funderror');removeErrorOnlyOne('rwterror'); removeErrorOnlyOne('exemptprooferror')  }}
                onChange={(e) => {
                  setForm(e);
                  removeErrorOnlyOne('funderror')
                }}
                name="rwt"
                value={formData.rwt}
              />
              <span className="error funderror"></span>
            </Col>

            <Col>
              {rwt === 'Resident Withholding Tax' ? (
                <Nrwt
                  type="text"
                  label="Select your Resident Withholding Tax rate"
                  className={
                    rwtValue === 'Select' ||
                      rwtValue === undefined ||
                      rwtValue === '' ||
                      rwtValue === null
                      ? 'colorgrey'
                      : 'colorblack'
                  }
                  onClick={()=>removeErrorOnlyOne('rwterror')}
                  onChange={(e) => {
                    setForm(e);
                    removeErrorOnlyOne('rwterror')
                  }}
                  name="rwtValue"
                  value={formData.rwtValue}
                />
              ) : rwt !== ' Resident Withholding Tax' && rwt !== 'AIL 2%' && rwt === 'Non Resident Withholding Tax' ? (
                <div className="checkbox-my nrdt_chack">
                  {' '}
                  <div className="checkmystyle">
                    {' '}
                    <>
                      <input
                        type="checkbox"
                        id="TickHere"
                        defaultChecked={checkboxx === true ? true : false}
                        name="checkboxx"
                        onChange={(e) => {
                          setForm(e);
                          checkboxchange(e);
                        }}
                      />
                      <span class="checkmark"></span>{' '}
                      <label for="TickHere">Tick here if you hold a valid Resident Withholding Tax exemption.</label>
                    </>
                  </div>{' '}
                </div>
              ) : (
                ''
              )}
              <span className='rwterror error'></span>
            </Col>
          </Row>
          {rwtValue === 'Exempt' && rwt == 'Resident Withholding Tax' ?
              
        
             <UploadImage
            formData={formData}
            columnName={columnName}
            fileName={fileName}
            labeltxt={labeltxt}
            buttonName='CLICK TO ATTACH'
            valuedefine={valuedefine}
          />
          
            
            : ""

          }
             <Col xs={12}>
                  <span className='exemptprooferror error'></span>

                </Col>

          <div className="fotter-btn">
            <button className="preview" onClick={previous}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
            {/* <button type="submit" className="next flagsave" onClick={handleClick}>
              Continue
              <img src={rightarrow} className="rightarrow" alt="" />{' '}
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Four;
