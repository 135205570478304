import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import BiometricMsg from './pages/BiometricMsg';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([

    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard" /> },
        { path: 'dashboard', element: <DashboardApp /> },
      ]
    },
    {

      path: '/',
      // element: <LogoOnlyLayout />,
      children: [
        { path: 'register', element: <Navigate to="/dashboard" /> },
        // { path: 'biometrics', element: <BiometricMsg/> },
        // { path: 'dashboard/biometric/complete', element: <BiometricMsg/> },
        { path: 'dashboard/idscan/complete', element: <BiometricMsg /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/register" /> },
        { path: '*', element: <Navigate to="/" /> }
      ]
    },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);
}
