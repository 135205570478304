

/*eslint eqeqeq: "off"*/
import React from "react";

const Review = ({ setForm, formData, navigation }) => {
  const {
    fullName,
    emails,
    entity,
    Syndication,
    units,
    investment,
    preferredname,
    date,
    residential,
    postal,
    place,
    phoneNumber,
    irdNumber,
    enternumber
  } = formData;
  const { go } = navigation;

  return (
    <div className="form print">
      <h3>Review your data</h3>
      <h4>
      Application Form
        <button onClick={() => go("names")}>Edit</button>
      </h4>
      <div>Full Legal Name: {`${fullName}`}</div>
      <div>
        {" "}
        Enter your email address: {`${emails}`},
        <br />
        Which type of entity is the application for: {`${entity}`},
      </div>
      <div>Select which investment opportunity you are applying for: {`${Syndication}`}</div>
      
      <h4>
      investment amount
        <button onClick={() => go("address")}>Edit</button>
      </h4>
      <div>
      Number of units applied for: {`${units}`},
        <br />
        Total investment: {` ${investment}`},
        <br />
       
       
     
      </div>
      <h4>
      provide your details below
        <button onClick={() => go("contact")}>Edit</button>
      </h4>
      <div>
      Full Legal Name: {`${fullName}`},
        <br />
        preferre name: {`${preferredname}`},
        <br />
     
        Date of Birth: {`${date}`}
        <br/>
        Residential Address: {`${residential}`}
        <br/>
        Postal Address: {`${postal}`}
        <br/>
        Contact Number: {`${place}`}
        <br/>
        phoneNumber: {`${phoneNumber}`}
        <br/>
        
        irdNumber: {`${irdNumber}`}
        <br/>
        Enter Number: {`${enternumber}`}
        <br/>
      </div>
      <div>
        <button className="submit" onClick={() => go("submit")}>Submit</button>
      </div>
    </div>
  );
};

export default Review;
