/*eslint eqeqeq: "off"*/
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Biometricverify, multisavedata } from 'src/auth/Configuration';
import Avatar, { ConfigProvider } from 'react-avatar';
import $ from 'jquery';
import { useAuth0 } from '@auth0/auth0-react';
import { useIdleTimer } from 'react-idle-timer';
import {audience, duvalapi } from '../../auth/Configuration';

import Swal from 'sweetalert2';
const MenuSidebar = ({ navigation, formData, func }) => {
  const { logout , getAccessTokenSilently , getIdTokenClaims } = useAuth0();
  const { bankSwiftCode2 } = formData;

  const timeout = 900000;
  // const [remaining, setRemaining] = useState(timeout);
  // console.log(remaining)
  const handleOnIdle = () => {
    application4();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getUserMetadata();
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);


  const getUserMetadata = async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: audience,
        useRefreshTokens: true,
        scope: 'read:current_user'
      });
    } catch (e) { }
    try {
      const accessidToken = await getIdTokenClaims({
        audience: audience,
        useRefreshTokens: true,
        scope: 'read:current_user'
      });
      {
        if (
          accessidToken.__raw !== 'undefined' ||
          accessidToken.__raw !== null ||
          accessidToken.__raw !== undefined
        ) {
          formData.token = accessidToken.__raw;
          console.log(formData.token)
      
        } else {
        }
      }
    } catch (e) { }
  };
  const { } = useIdleTimer({
    timeout,
    onIdle: handleOnIdle
  });

  const namefull = formData.fullName ? formData.fullName.split(' ') : '';

  formData.firstName = namefull[0];
  if (namefull.length > 1) {
    formData.lastName = namefull[namefull.length - 1];
  }
  if (namefull.length > 2) {
    formData.middleName = namefull
      .slice(1, namefull.length - 1)
      .join()
      .replace(/,/g, ' ');
  }
  const { go } = navigation;
  function HandleSecond(e) {
    if (formData.investmentType === 'Joint') {
      formData.class = 'Jointhird';
      go('Jointhird');
    }

    formData.class = 'Second';
    go('Second');
  }

  function HandleThird(e) {
    if (formData.investmentType === 'Joint') {
      formData.class = 'Third';
      go('Jointfour');
    } else if (formData.investmentType === 'Individual') {
      formData.class = 'Third';
      go('Third');
    }
    else {
      formData.class = 'Third';
      go('Third');
    }
  }

  function HandleEight(e) {
    formData.class = 'Eight';

    formData.investmentType === 'Joint' ? go('Jointtwelve') : go('Eight');
  }
  function handleFive(e) {
    formData.class = 'Five';

    formData.investmentType === 'Joint' ? go('Jointeight') : go('Five');

    //   go("Indentity")
  }
  function HandleNine(e) {
    formData.class = 'Nine';

    formData.investmentType === 'Joint' ? go('Nine') : go('Nine');
  }
  function Handletwentyone(e) {
    formData.class = 'Twentyone';
    go('Twentyone1');
  }

  function Handletwentyone11(e) {
    formData.class = 'Twentyone11';
    go('Twentyone11');
  }
  const llogggout = () => {
    if (formData.applicationStage === 'In Progress') {
      application4();
    } else {
      logout();
    }
  };
  const [loader, setLoader] = useState();

  //   const [toggle, setToggle] = useState(true);
  const [isActive, setActive] = useState('false');
  const handleToggle = () => {
    setActive(!isActive);
  };

  function HandleJointThird() {
    if (formData.investmentType == 'Joint') {
      formData.class = 'Jointhird';
      go('Jointhird');
    }
  }

  function HandleEntityOne() {
    if (formData.investmentType == 'Company') {
      formData.class = 'Jointhird';
      go('EntityOne');
    }
  }

  // tick functionality

  const { isNZBank } = formData;

  let {
    firstTick,
    secondTick,
    thirdTick,
    fourthTick,
    fifthTick,
    sixthTick,
    seventhTick,
    eightTick,
    // nineTick,
    elevenTick
  } = false;

  //  for second page
  const [right, setRight] = useState(false);
  const sss = () => {
    console.log('formData.totalInvestment123', formData.totalInvestment);
    if (
      formData.investmentType !== null &&
      formData.investmentType !== '' &&
      formData.investmentType !== 'undefined' &&
      formData.investmentType !== undefined &&
      formData.investmentType !== 'Select' &&
      formData.fundCode !== null &&
      formData.fundCode !== '' &&
      formData.fundCode !== 'undefined' &&
      formData.fundCode !== undefined &&
      formData.fundCode !== 'Select' &&
      formData.totalInvestment !== null &&
      formData.totalInvestment !== '' &&
      formData.totalInvestment !== 'undefined' &&
      formData.totalInvestment !== undefined &&
      formData.totalInvestment.replace(/,/g, '') >= '100000' &&
      // formData.totalInvestment.replace(/,/g, '') >="250000"
      formData.totalInvestment.replace(/,/g, '') % 10000 === 0
    ) {
      firstTick = true;
      setRight(true);
      $('#first').removeClass('fillColor');
      formData.firstTick = true;
    } else {
      formData.firstTick = false;
    }
  };
  //for company entity details
  const [companyentity, setCompanyentity] = useState(false);


  const [jointthirdentity, setJointthirdentity] = useState(false);

  const jointentitydetailsss = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.fullName !== 'undefined' &&
      formData.fullName !== undefined &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      formData.email1 !== null &&
      formData.email1 !== '' &&
      formData.email1 !== 'undefined' &&
      formData.email1 !== undefined
    ) {
      secondTick = true;
      setJointthirdentity(true);
      $('#second').removeClass('fillColor');
      formData.secondTick = true;
    } else {
      if (
        formData.fullName !== null &&
        formData.fullName !== '' &&
        formData.fullName !== 'undefined' &&
        formData.fullName !== undefined &&
        formData.secondholder !== null &&
        formData.secondholder !== '' &&
        formData.secondholder !== 'undefined' &&
        formData.secondholder !== undefined &&
        formData.email1 !== null &&
        formData.email1 !== '' &&
        formData.email1 !== 'undefined' &&
        formData.email1 !== undefined
      ) {
        secondTick = true;
        setJointthirdentity(true);
        $('#second').removeClass('fillColor');
        formData.secondTick = true;
      } else {
        formData.secondTick = false;
      }
    }
  };

  // for personal information

  const [personal, setPersonal] = useState(false);
  let IndividualDOB = sessionStorage.getItem('dateOfBirth');
  let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
  let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
  let PostalAddress2 = sessionStorage.getItem('PostalAddress2');

  const IndividualPersonal = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.codeVerify !== 'false' &&
      IndividualDOB !== null &&
      IndividualDOB !== '' &&
      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined)))&&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== ''

    ) {
      thirdTick = true;
      setPersonal(true);
      $('#third').removeClass('fillColor');
      formData.thirdTick = true;
    } else {
      if (
        formData.fullName !== null &&
        formData.fullName !== '' &&
        formData.codeVerify !== 'false' &&
        IndividualDOB !== null &&
        IndividualDOB !== '' &&
        (
          (formData.isPrimaryResdAddress !== '' &&
            formData.isPrimaryResdAddress !== null &&
            formData.isPrimaryResdAddress !== 'undefined' ||
           (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
            formData.street_number !== '' &&
            formData.street_number !== undefined &&
            formData.city !== null &&
            formData.city !== '' &&
            formData.city !== undefined &&
            formData.postalCode !== null &&
            formData.postalCode !== '' &&
            formData.postalCode !== undefined))) &&
        formData.priContactNumber !== null &&
        formData.priContactNumber !== '' &&

        // formData.intendToInvest !== "" && formData.intendToInvest !== null && formData.intendToInvest !== 'Select' &&
        // formData.totalIintend !== "" && formData.totalIintend !== null && formData.totalIintend !== 'Select' &&
        // formData.chooseToInvest !== "" && formData.chooseToInvest !== null && formData.chooseToInvest !== 'Select' &&
        // formData.occupation !== "" && formData.occupation !== null &&
        // formData.sourceOfFund !== "" && formData.sourceOfFund !== null &&

        formData.placeOfBirth !== null &&
        formData.placeOfBirth !== '' &&
        formData.countryOfBirth !== null &&
        formData.countryOfBirth !== '' &&
        formData.residingCountry !== null &&
        formData.residingCountry !== '' &&
        formData.userIrdNumber !== null &&
        formData.userIrdNumber !== '' &&
        formData.rwt !== null &&
        formData.rwt !== '' &&
        formData.rwt !== 'Select' &&
        ((formData.rwt === 'Resident Withholding Tax'
          ? formData.rwtValue !== null &&
          formData.rwtValue !== '' &&
          formData.rwtValue !== 'Select' &&
          (formData.rwtValue == 'Exempt' && formData.rwt == 'Resident Withholding Tax'
            ? formData.examptProof !== '' &&
            formData.examptProof !== null &&
            formData.examptProof !== 'undefined' &&
            formData.examptProof !== undefined
            : formData.rwtValue !== 'Select' &&
            formData.rwtValue !== undefined &&
            formData.rwtValue !== '' &&
            formData.rwtValue !== null)
          : formData.rwt !== null && formData.rwt !== '' && formData.rwt !== 'Select') ||
          formData.rwt === 'Non Resident Withholding Tax' ||
          formData.rwt === 'AIL 2%') &&
        (formData.isUserAddressSame === 'false'
          ? formData.isPrimaryPostalAdress !== null &&
          formData.isPrimaryPostalAdress !== '' &&
          formData.isPrimaryPostalAdress !== 'undefined' &&
          formData.isPrimaryPostalAdress !== undefined
          : formData.isPrimaryPostalAdress === null ||
          formData.isPrimaryPostalAdress === '' ||
          formData.isPrimaryPostalAdress === 'undefined' ||
          formData.isPrimaryPostalAdress === undefined) 
   
      ) {

        thirdTick = true;
        setPersonal(true);
        $('#third').removeClass('fillColor');
        formData.thirdTick = true;
      }

      else {
        formData.thirdTick = false;
      }
    }
  };
  // for Joint Personal Information

  const [jointpersonal, setJointpersonal] = useState(false);
  let jointDOB = sessionStorage.getItem('dateOfBirth2');
  let residentialAddress2 = sessionStorage.getItem('residentialAddress2');

  const jointPersonalinformation = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      jointDOB !== null &&
      jointDOB !== '' &&

      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined))) &&
          (
            (formData.isSecondryResdAddress !== '' &&
              formData.isSecondryResdAddress !== null &&
              formData.isSecondryResdAddress !== 'undefined' || (formData.manuallyAddress2 == 'false' && formData.street_number1 !== null &&
              formData.street_number1 !== '' &&
              formData.street_number1 !== undefined &&
              formData.city1 !== null &&
              formData.city1 !== '' &&
              formData.city1 !== undefined &&
              formData.postalCode1 !== null &&
              formData.postalCode1 !== '' &&
              formData.postalCode1 !== undefined
              ))
              ) &&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== '' &&
      formData.priContactNumber2 !== null &&
      formData.priContactNumber2 !== ''
    ) {
      fourthTick = true;
      setJointpersonal(true);
      $('#third').removeClass('fillColor');
      formData.fourthTick = true;
      formData.thirdTick = true;
    } else {
      if (
        formData.fullName !== null &&
        formData.fullName !== '' &&
        formData.secondholder !== null &&
        formData.secondholder !== '' &&
        formData.secondholder !== 'undefined' &&
        formData.secondholder !== undefined &&
        formData.codeVerify !== 'false' &&
        IndividualDOB !== null &&
        IndividualDOB !== '' &&
        jointDOB !== null &&
        jointDOB !== '' &&

        (
          (formData.isPrimaryResdAddress !== '' &&
            formData.isPrimaryResdAddress !== null &&
            formData.isPrimaryResdAddress !== 'undefined' ||
           (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
            formData.street_number !== '' &&
            formData.street_number !== undefined &&
            formData.city !== null &&
            formData.city !== '' &&
            formData.city !== undefined &&
            formData.postalCode !== null &&
            formData.postalCode !== '' &&
            formData.postalCode !== undefined)))&&
            (
              (formData.isSecondryResdAddress !== '' &&
                formData.isSecondryResdAddress !== null &&
                formData.isSecondryResdAddress !== 'undefined' || (formData.manuallyAddress2 == 'false' && formData.street_number1 !== null &&
                formData.street_number1 !== '' &&
                formData.street_number1 !== undefined &&
                formData.city1 !== null &&
                formData.city1 !== '' &&
                formData.city1 !== undefined &&
                formData.postalCode1 !== null &&
                formData.postalCode1 !== '' &&
                formData.postalCode1 !== undefined
                ))
                )  &&
        formData.priContactNumber !== null &&
        formData.priContactNumber !== '' &&
        formData.priContactNumber2 !== null &&
        formData.priContactNumber2 !== '' &&

        formData.placeOfBirth !== null &&
        formData.placeOfBirth !== '' &&
        formData.placeOfBirth2 !== null &&
        formData.placeOfBirth2 !== '' &&
        formData.placeOfBirth2 !== 'undefined' &&
        formData.placeOfBirth2 !== undefined &&
        formData.countryOfBirth !== null &&
        formData.countryOfBirth !== '' &&
        formData.countryOfBirth2 !== null &&
        formData.countryOfBirth2 !== '' &&
        formData.countryOfBirth2 !== undefined &&
        formData.countryOfBirth2 !== 'undefined' &&
        formData.residingCountry !== null &&
        formData.residingCountry !== '' &&
        formData.residingCountry2 !== null &&
        formData.residingCountry2 !== '' &&
        formData.residingCountry2 !== undefined &&
        formData.residingCountry2 !== 'undefined' &&
        formData.userIrdNumber !== null &&
        formData.userIrdNumber !== '' &&
        formData.userIrdNumber2 !== null &&
        formData.userIrdNumber2 !== '' &&
        formData.userIrdNumber2 !== undefined &&
        formData.userIrdNumber2 !== 'undefined' &&
        formData.rwt !== null &&
        formData.rwt !== '' &&
        formData.rwt !== 'Select' &&
        formData.rwt !== undefined &&

        formData.rwt2 !== null &&
        formData.rwt2 !== 'Select' &&
        formData.rwt2 !== '' &&
        formData.rwt2 !== 'undefined' &&
        formData.rwt2 !== undefined &&
        ((formData.rwt === 'Resident Withholding Tax'
          ? formData.rwtValue !== null &&
          formData.rwtValue !== '' &&
          formData.rwtValue !== 'Select' &&
          (formData.rwtValue == 'Exempt' && formData.rwt == 'Resident Withholding Tax'
            ? formData.examptProof !== '' &&
            formData.examptProof !== null &&
            formData.examptProof !== 'undefined' &&
            formData.examptProof !== undefined
            : formData.rwtValue !== 'Select' &&
            formData.rwtValue !== undefined &&
            formData.rwtValue !== '' &&
            formData.rwtValue !== null)
          : formData.rwt !== null && formData.rwt !== '' && formData.rwt !== 'Select') ||
          formData.rwt === 'Non Resident Withholding Tax' ||
          formData.rwt === 'AIL 2%')

        &&
        ((formData.rwt2 === 'Resident Withholding Tax'
          ? formData.rwtValue2 !== null &&
          formData.rwtValue2 !== '' &&
          formData.rwtValue2 !== 'Select' &&
          (formData.rwtValue2 == 'Exempt' && formData.rwt2 == 'Resident Withholding Tax'
            ? formData.examptProof2 !== '' &&
            formData.examptProof2 !== null &&
            formData.examptProof2 !== 'undefined' &&
            formData.examptProof2 !== undefined
            : formData.rwtValue2 !== 'Select' &&
            formData.rwtValue2 !== undefined &&
            formData.rwtValue2 !== '' &&
            formData.rwtValue2 !== null)
          : formData.rwt2 !== null && formData.rwt2 !== '' && formData.rwt2 !== 'Select') ||
          formData.rwt2 === 'Non Resident Withholding Tax' ||
          formData.rwt2 === 'AIL 2%') 
          &&
        ((formData.isUserAddressSame === 'false' || formData.isUserAddressSame === false)
          ? formData.isPrimaryPostalAdress !== null &&
          formData.isPrimaryPostalAdress !== '' &&
          formData.isPrimaryPostalAdress !== 'undefined' &&
          formData.isPrimaryPostalAdress !== undefined
          : formData.isPrimaryPostalAdress === null ||
          formData.isPrimaryPostalAdress === '' ||
          formData.isPrimaryPostalAdress === 'undefined' ||
          formData.isPrimaryPostalAdress === undefined) &&
        (formData.isUserAddressSame2 === 'false'
          ? formData.isSecondryPostalAdre !== null &&
          formData.isSecondryPostalAdre  !== '' &&
          formData.isSecondryPostalAdre  !== 'undefined' &&
          formData.isSecondryPostalAdre  !== undefined
          : formData.isSecondryPostalAdre  === null ||
          formData.isSecondryPostalAdre  === '' ||
          formData.isSecondryPostalAdre  === 'undefined' ||
          formData.isSecondryPostalAdre  === undefined) 
      ) {
        fourthTick = true;
        setJointpersonal(true);
        $('#third').removeClass('fillColor');
        formData.fourthTick = true;
        formData.thirdTick = true;
      } else {
        formData.fourthTick = false;
      }
    }
  };

  // for Identity Verification

  const [identity, setIdentity] = useState(false);
  let IndividualExpiryDate = sessionStorage.getItem('documentExpiryDates');

  const IndividualIdentityVerify = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.codeVerify !== 'false' &&
      IndividualDOB !== null &&
      IndividualDOB !== '' &&
      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined))) &&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== ''
      //  &&

      // formData.intendToInvest !== "" && formData.intendToInvest !== null && formData.intendToInvest !== 'Select' &&
      // formData.totalIintend !== "" && formData.totalIintend !== null && formData.totalIintend !== 'Select' &&
      // formData.chooseToInvest !== "" && formData.chooseToInvest !== null && formData.chooseToInvest !== 'Select' &&
      // formData.occupation !== "" && formData.occupation !== null &&
      // formData.sourceOfFund !== "" && formData.sourceOfFund !== null


    ) {

      fifthTick = true;
      setIdentity(true);
      $('#fifth').removeClass('fillColor');
      formData.fifthTick = true;

    } else {
      if (
        (formData.idType !== null &&
          formData.idType !== '' &&
          formData.biometric !== 'Biometric' &&
          formData.documentNumber &&
          IndividualExpiryDate !== null &&
          IndividualExpiryDate !== '' &&
          formData.fullName !== null &&
          formData.fullName !== '' &&
          (formData.idType === '1' ||
            formData.idType === '2' ||
            formData.idType === '' ||
            formData.idType === null
            ? formData.documentProof !== null &&
            formData.documentProof !== '' &&
            formData.documentProof !== 'undefined'
            : formData.documentProof !== null &&
            formData.documentProof !== '' &&
            formData.documentProof !== 'undefined' &&
            formData.otherDocumentName !== '' &&
            formData.otherDocumentName !== null) &&
          ((formData.documentCountryOfIssue !== null && formData.documentCountryOfIssue !== '') ||
            (formData.documentVersionNumber !== null && formData.documentVersionNumber !== ''))) ||
        formData.biometricComplete === true
      ) {
        fifthTick = true;
        setIdentity(true);
        $('#fifth').removeClass('fillColor');
        formData.fifthTick = true;
      } else {
        formData.fifthTick = false;
      }
    }
  };

  // for joint Identity Verification
  let JointExpiryDate = sessionStorage.getItem('documentExpiryDates1');
  const [jointIdentity, setJointIdentity] = useState(false);

  const JointIdentityVerify = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      jointDOB !== null &&
      jointDOB !== '' &&

      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false' && formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined))) &&
      (
        (formData.isSecondryResdAddress !== '' &&
          formData.isSecondryResdAddress !== null &&
          formData.isSecondryResdAddress !== 'undefined' || (formData.manuallyAddress2 == 'false' && formData.street_number1 !== null &&
          formData.street_number1 !== '' &&
          formData.street_number1 !== undefined &&
          formData.city1 !== null &&
          formData.city1 !== '' &&
          formData.city1 !== undefined &&
          formData.postalCode1 !== null &&
          formData.postalCode1 !== '' &&
          formData.postalCode1 !== undefined
          ))
          ) &&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== '' &&
      formData.priContactNumber2 !== null &&
      formData.priContactNumber2 !== ''
      // &&

      // formData.intendToInvest !== "" && formData.intendToInvest !== null && formData.intendToInvest !== 'Select' &&
      // formData.totalIintend !== "" && formData.totalIintend !== null && formData.totalIintend !== 'Select' &&
      // formData.chooseToInvest !== "" && formData.chooseToInvest !== null && formData.chooseToInvest !== 'Select' &&
      // formData.occupation !== "" && formData.occupation !== null &&
      // formData.sourceOfFund !== "" && formData.sourceOfFund !== null &&

      // formData.intendToInvest3 !== undefined && formData.intendToInvest3 !== "undefined" && formData.intendToInvest3 !== "" && formData.intendToInvest3 !== null && formData.intendToInvest3 !== 'Select' &&
      // formData.totalIintend3 !== undefined && formData.totalIintend3 !== "undefined" && formData.totalIintend3 !== "" && formData.totalIintend3 !== null && formData.totalIintend3 !== 'Select' &&
      // formData.chooseToInvest3 !== undefined && formData.chooseToInvest3 !== "undefined" && formData.chooseToInvest3 !== "" && formData.chooseToInvest3 !== null && formData.chooseToInvest3 !== 'Select' &&
      // formData.occupation3 !== undefined && formData.occupation3 !== "undefined" && formData.occupation3 !== "" && formData.occupation3 !== null &&
      // formData.sourceOfFund3 !== undefined && formData.sourceOfFund3 !== "undefined" && formData.sourceOfFund3 !== "" && formData.sourceOfFund3 !== null

    ) {
      sixthTick = true;
      setJointIdentity(true);
      $('#fifth').removeClass('fillColor');
      formData.sixthTick = true;
      formData.fifthTick = true;
    } else {
      // if (
      //   ((formData.idType !== null &&
      //     formData.idType !== '' &&
      //     formData.biometric !== 'Biometric' &&
      //     formData.documentNumber &&
      //     IndividualExpiryDate !== null &&
      //     IndividualExpiryDate !== '' &&
      //     formData.fullName !== null &&
      //     formData.fullName !== '' &&
      //     (formData.idType === '1' ||
      //       formData.idType === '2' ||
      //       formData.idType === '' ||
      //       formData.idType === null
      //       ? formData.documentProof !== null &&
      //       formData.documentProof !== '' &&
      //       formData.documentProof !== 'undefined'
      //       : formData.documentProof !== null &&
      //       formData.documentProof !== '' &&
      //       formData.documentProof !== 'undefined' &&
      //       formData.otherDocumentName !== '' &&
      //       formData.otherDocumentName !== null) &&
      //     ((formData.documentCountryOfIssue !== null && formData.documentCountryOfIssue !== '') ||
      //       (formData.documentVersionNumber !== null && formData.documentVersionNumber !== ''))) ||
      //     formData.biometricComplete === true ||
      //     formData.biometricComplete === 'undefined') &&
      //   ((formData.idType2 !== null &&
      //     formData.idType2 !== '' &&
      //     formData.biometric2 !== 'Biometric' &&
      //     formData.documentNumber2 &&
      //     JointExpiryDate !== null &&
      //     JointExpiryDate !== '' &&
      //     formData.secondholder !== null &&
      //     formData.secondholder !== '' &&
      //     (formData.idType2 === '1' ||
      //       formData.idType2 === '2' ||
      //       formData.idType2 === '' ||
      //       formData.idType2 === null
      //       ? formData.documentProof2 !== null &&
      //       formData.documentProof2 !== '' &&
      //       formData.documentProof2 !== 'undefined'
      //       : formData.documentProof2 !== null &&
      //       formData.documentProof2 !== '' &&
      //       formData.documentProof2 !== 'undefined' &&
      //       formData.otherDocumentName2 !== '' &&
      //       formData.otherDocumentName2 !== null) &&
      //     ((formData.documentCountryOfIssue2 !== null && formData.documentCountryOfIssue2 !== '') ||
      //       (formData.documentVersionNumber2 !== null && formData.documentVersionNumber2 !== ''))) ||
      //     formData.biometricComplete1 === true)
      if ((((formData.idType !== null && formData.idType !== "" && formData.biometric !== "Biometric") &&
        formData.documentNumber && IndividualExpiryDate !== null && IndividualExpiryDate !== "" && formData.fullName !== null && formData.fullName !== "" &&
        (formData.idType === "1" || formData.idType === "2" || formData.idType === "" || formData.idType === null ?
          formData.documentProof !== null && formData.documentProof !== "" && formData.documentProof !== "undefined" : formData.documentProof !== null && formData.documentProof !== "" && formData.documentProof !== "undefined"
          && formData.otherDocumentName !== '' && formData.otherDocumentName !== null) &&
        ((formData.documentCountryOfIssue !== null && formData.documentCountryOfIssue !== "") || (formData.documentVersionNumber !== null && formData.documentVersionNumber !== "")
        )
      ) || (formData.biometricComplete === true || formData.biometricComplete === "undefined"))
        &&
        (((formData.idType2 !== null && formData.idType2 !== "" && formData.biometric2 !== "Biometric") &&
          formData.documentNumber2 && JointExpiryDate !== null && JointExpiryDate !== "" && formData.secondholder !== null && formData.secondholder !== "" &&
          (formData.idType2 === "1" || formData.idType2 === "2" || formData.idType2 === "" || formData.idType2 === null ?
            formData.documentProof2 !== null && formData.documentProof2 !== "" && formData.documentProof2 !== "undefined" : formData.documentProof2 !== null && formData.documentProof2 !== "" && formData.documentProof2 !== "undefined" && formData.otherDocumentName2 !== "" && formData.otherDocumentName2 !== null)

          &&
          (
            (formData.documentCountryOfIssue2 !== null && formData.documentCountryOfIssue2 !== "" && formData.documentCountryOfIssue2 !== undefined && formData.documentCountryOfIssue2 !== "undefined") || (formData.documentVersionNumber2 !== null && formData.documentVersionNumber2 !== "")
          )
        ) || (formData.biometricComplete1 === true))
      ) {
        sixthTick = true;
        setJointIdentity(true);
        $('#fifth').removeClass('fillColor');
        formData.sixthTick = true;
        formData.fifthTick = true;
      } else {
        formData.sixthTick = false;
      }
    }
  };

  // for Bank Details
  const [bank, setBank] = useState(false);
  const IndividualBank = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.codeVerify !== 'false' &&
      IndividualDOB !== null &&
      IndividualDOB !== '' &&
      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined))) &&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== ''
      //  &&

      // formData.intendToInvest !== "" && formData.intendToInvest !== null && formData.intendToInvest !== 'Select' &&
      // formData.totalIintend !== "" && formData.totalIintend !== null && formData.totalIintend !== 'Select' &&
      // formData.chooseToInvest !== "" && formData.chooseToInvest !== null && formData.chooseToInvest !== 'Select' &&
      // formData.occupation !== "" && formData.occupation !== null &&
      // formData.sourceOfFund !== "" && formData.sourceOfFund !== null
    ) {
      seventhTick = true;
      $('#seven').removeClass('fillColor');
      formData.seventhTick = true;
      setBank(true);
    } else {
      if (
        formData.isNZBank !== '' &&
        formData.isNZBank !== null &&
        (isNZBank === 'true' || isNZBank === null || typeof isNZBank === 'undefined'
          ? formData.primaryBankAccountName !== null &&
          formData.primaryBankAccountName !== undefined &&
          formData.primaryBankAccountName !== "undefined" &&
          formData.primaryBankAccountName !== '' &&
          formData.primaryBankAccountNumber !== null &&
          formData.primaryBankAccountNumber !== '' &&
          formData.primaryBankAccountNumber !== 'undefined' &&
          formData.primaryBankAccountNumber !== undefined
          : formData.primaryBankProof !== null &&
          formData.primaryBankProof !== '' &&
          formData.primaryBankProof !== undefined &&
          formData.primaryBankProof !== 'undefined' &&
          formData.primaryBankAccountName !== null &&
          formData.primaryBankAccountName !== '' &&
          formData.primaryBankAccountNumber !== null &&
          formData.primaryBankAccountNumber !== '' &&
          formData.bankSwiftCode !== null &&
          formData.bankSwiftCode !== 'undefined' &&
          formData.bankSwiftCode !== '' &&
          formData.bankSwiftCode !== undefined) &&
        formData.primaryBankProof !== null &&
        formData.primaryBankProof !== '' &&
        formData.primaryBankProof !== undefined &&
        formData.primaryBankProof !== 'undefined'
      ) {
        seventhTick = true;
        $('#seven').removeClass('fillColor');
        formData.seventhTick = true;

        setBank(true);
      } else {
        formData.seventhTick = false;
      }
    }
  };

  // for joint Bank details

  const [jointBank, setJointBank] = useState(false);

  const jointBankdetails = () => {
    if ((formData.isMyFarm === true || formData.isMyFarm === 'true') &&
      formData.fullName !== null &&
      formData.fullName !== '' &&
      formData.secondholder !== null &&
      formData.secondholder !== '' &&
      formData.secondholder !== 'undefined' &&
      formData.secondholder !== undefined &&
      jointDOB !== null &&
      jointDOB !== '' &&

      (
        (formData.isPrimaryResdAddress !== '' &&
          formData.isPrimaryResdAddress !== null &&
          formData.isPrimaryResdAddress !== 'undefined' ||
         (formData.manuallyAddress == 'false'&& formData.street_number !== null &&
          formData.street_number !== '' &&
          formData.street_number !== undefined &&
          formData.city !== null &&
          formData.city !== '' &&
          formData.city !== undefined &&
          formData.postalCode !== null &&
          formData.postalCode !== '' &&
          formData.postalCode !== undefined))) &&
          (
            (formData.isSecondryResdAddress !== '' &&
              formData.isSecondryResdAddress !== null &&
              formData.isSecondryResdAddress !== 'undefined' || (formData.manuallyAddress2 == 'false' && formData.street_number1 !== null &&
              formData.street_number1 !== '' &&
              formData.street_number1 !== undefined &&
              formData.city1 !== null &&
              formData.city1 !== '' &&
              formData.city1 !== undefined &&
              formData.postalCode1 !== null &&
              formData.postalCode1 !== '' &&
              formData.postalCode1 !== undefined
              ))
              ) &&
      formData.priContactNumber !== null &&
      formData.priContactNumber !== '' &&
      formData.priContactNumber2 !== null &&
      formData.priContactNumber2 !== ''
     
    ) {
      eightTick = true;
      setJointBank(true);
      $('#seven').removeClass('fillColor');
      formData.eightTick = true;
      formData.seventhTick = true;
    } else {

      if (
        formData.isNZBank12 === "false" ?
          (formData.isNZBank !== '' &&
            formData.isNZBank !== null &&
            ((formData.isNZBank === 'true' || formData.isNZBank === null || formData.isNZBank == undefined || typeof formData.isNZBank === 'undefined'
              ? formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
              formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined &&
              formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" &&
              formData.primaryBankAccountNumber !== "" &&
              formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
              formData.primaryBankProof !== undefined

              : formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
              formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined && formData.primaryBankAccountNumber !== "" &&
              formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" &&
              formData.bankSwiftCode !== null && formData.bankSwiftCode !== "undefined" && formData.bankSwiftCode !== "" && formData.bankSwiftCode !== undefined &&
              formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
              formData.primaryBankProof !== undefined)
              &&

              (formData.isNZBank2 === 'true' || formData.isNZBank2 === null || typeof formData.isNZBank2 === 'undefined'
                ?
                formData.primaryBankAccountName2 !== null && formData.primaryBankAccountName2 !== "" && formData.primaryBankAccountName2 !== "undefined"
                && formData.primaryBankAccountName2 !== undefined &&
                formData.primaryBankAccountNumber2 !== null && formData.primaryBankAccountNumber2 !== "" && formData.primaryBankAccountNumber2 !== "undefined" &&
                formData.primaryBankAccountNumber2 !== undefined &&
                formData.primaryBankProof2 !== null && formData.primaryBankProof2 !== "" && formData.primaryBankProof2 !== "undefined" &&
                formData.primaryBankProof2 !== undefined
                : formData.primaryBankAccountName2 !== null && formData.primaryBankAccountName2 !== "" &&
                formData.primaryBankAccountName2 !== "undefined" && formData.primaryBankAccountName2 !== undefined &&
                formData.primaryBankAccountNumber2 !== null && formData.primaryBankAccountNumber2 !== "" && formData.primaryBankAccountNumber2 !== undefined && formData.primaryBankAccountNumber2 !== "" &&
                formData.bankSwiftCode2 !== null && formData.bankSwiftCode2 !== "undefined" &&
                formData.bankSwiftCode2 !== "" && formData.bankSwiftCode2 !== undefined &&
                formData.primaryBankProof2 !== null && formData.primaryBankProof2 !== "" && formData.primaryBankProof2 !== "undefined" &&
                formData.primaryBankProof2 !== undefined)
            )
          ) :
          (formData.isNZBank === 'true' || formData.isNZBank === null || typeof formData.isNZBank === 'undefined'
            ? formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" &&
            formData.primaryBankAccountName !== "undefined" && formData.primaryBankAccountName !== undefined &&
            formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" && formData.primaryBankAccountNumber !== undefined && formData.primaryBankAccountNumber !== ""
            && formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
            formData.primaryBankProof !== undefined
            : formData.primaryBankAccountName !== null && formData.primaryBankAccountName !== "" && formData.primaryBankAccountName !== undefined &&
            formData.primaryBankAccountNumber !== null && formData.primaryBankAccountNumber !== "undefined" && formData.primaryBankAccountNumber !== undefined && formData.primaryBankAccountNumber !== "" &&
            formData.bankSwiftCode !== null && formData.bankSwiftCode !== "undefined" && formData.bankSwiftCode !== "" && formData.bankSwiftCode !== undefined &&
            formData.primaryBankProof !== null && formData.primaryBankProof !== "" && formData.primaryBankProof !== "undefined" &&
            formData.primaryBankProof !== undefined)

      ) {
        eightTick = true;
        setJointBank(true);
        $('#seven').removeClass("fillColor");
        formData.eightTick = true
        formData.seventhTick = true
      }
      else {
        formData.eightTick = false
      }
    }
  };


  // for Wholesale Certification

  // const [wholeSale, setWholeSale] = useState(
  //   );
  // const wholeSale =
  //   formData.signatureB !== null &&
  //   formData.signatureB !== '' &&
  //   formData.signatureB !== 'undefined' &&
  //   formData.signatureB !== undefined &&
  //   formData.usualLegalRules === true &&
  //   formData.notCompleteInformation === true &&
  //   // formData.materialExtent10yrs === true &&
  //   formData.fewerLegalProtection === true &&
  //   formData.notSuitableInvestment === true &&
  //   formData.seekFinancialAdvice === true &&
  //   (formData.investmentType == 'Joint'
  //     ? formData.OptionFund3 !== 'Select' &&
  //       formData.OptionFund3 !== undefined &&
  //       formData.OptionFund3 !== null &&
  //       formData.OptionFund4 !== 'Select' &&
  //       formData.OptionFund4 !== undefined &&
  //       formData.OptionFund4 !== null
  //     : formData.OptionFund3 !== 'Select' &&
  //       formData.OptionFund3 !== undefined &&
  //       formData.OptionFund3 !== null) &&
  //   formData.isMinimumInvest === 'YES'
  //     ? true
  //     : (false &&
  //         formData.largePersonValue !== null &&
  //         formData.largePersonValue !== '' &&
  //         formData.largePersonValue !== undefined) ||
  //       (formData.twoYearInvestEntity2 !== null &&
  //         formData.twoYearInvestEntity2 !== '' &&
  //         formData.twoYearInvestEntity2 !== undefined &&
  //         (formData.investmentType == 'Joint'
  //           ? formData.OptionFundCF2 !== 'Select' &&
  //             formData.OptionFundCF2 !== undefined &&
  //             formData.OptionFundCF2 !== null &&
  //             formData.OptionFundCF23 !== 'Select' &&
  //             formData.OptionFundCF23 !== undefined &&
  //             formData.OptionFundCF23 !== null
  //           : formData.OptionFundCF2 !== 'Select' &&
  //             formData.OptionFundCF2 !== undefined &&
  //             formData.OptionFundCF2 !== null))
  //     ? //
  //       formData.signatureA !== null &&
  //       formData.signatureA !== '' &&
  //       formData.signatureA !== 'undefined' &&
  //       formData.signatureA !== undefined &&
  //       true
  //     : formData.CertificationValue === true &&
  //       formData.fullLegalNamesign !== null &&
  //       formData.fullLegalNamesign !== '' &&
  //       formData.fullLegalNamesign !== 'undefined' &&
  //       formData.userEmail1 !== null &&
  //       formData.userEmail1 !== '' &&
  //       formData.userEmail1 !== 'undefined' &&
  //       formData.rwt12 !== 'Select' &&
  //       formData.rwt12 !== null &&
  //       formData.rwt12 !== ''
  //     ? true
  //     : false;
  // // for Terms and Condition
  // const Certification = () => {
  //   if (formData.CertificationValue === true) {
  //     nineTick = true;
  //     $('#nine').removeClass('fillColor');
  //     formData.nineTick = true;
  //   } else {
  //     nineTick = false;
  //     formData.nineTick = false;
  //   }
  // };
  const [termcondi, setTermcondi] = useState(false);

  const TermsAndCondition = () => {
    if (
      formData.termsss1 !== false &&
      formData.termsss1 !== false &&
      formData.termsss1 !== undefined &&
      formData.termsss1 !== null &&
      formData.termsss2 !== false &&
      formData.termsss2 !== false &&
      formData.termsss2 !== undefined &&
      formData.termsss2 !== null &&
      formData.termsss3 !== false &&
      formData.termsss3 !== false &&
      formData.termsss3 !== undefined &&
      formData.termsss3 !== null
    ) {
      elevenTick = true;
      setTermcondi(true);
      $('#eleven').removeClass('fillColor');
      formData.elevenTick = true;
    } else {
      formData.elevenTick = false;
    }
  };
  const finalTick = () => {
    if (firstTick && thirdTick && fifthTick && seventhTick && elevenTick) {
      formData.tickCount = 6;
    } else if (
      firstTick &&
      secondTick &&
      fourthTick &&
      sixthTick &&
      eightTick &&
      // nineTick &&
      elevenTick
    ) {
      formData.tickCount = 7;
    } else {
      formData.tickCount = 0;
    }
  };

  const captureComplete = async () => {
    const Bio = await axios
      .get(`${Biometricverify}/get/idscan/${formData.Capture}`)
      .then((Bio) => {
        formData.biometricComplete =
          Bio !== 'undefined' &&
            Bio !== undefined &&
            Bio.data.idscan !== 'undefined' &&
            Bio.data.idscan !== undefined &&
            Bio.data.idscan.completed !== 'undefined'
            ? Bio.data.idscan.completed
            : '';
        if (formData.biometricComplete === true) {
          IndividualIdentityVerify();

          // setIdentity(true);

          formData.fifthTick = true;
          formData.repeatOff = true;
        }
      });
    console.log(Bio);
  };

  const captureCompletejoint = async () => {
    const Bio = await axios
      .get(`${Biometricverify}/get/idscan/${formData.Capture2}`)
      .then((Bio) => {
        formData.biometricComplete1 =
          Bio !== 'undefined' &&
            Bio !== undefined &&
            Bio.data.idscan !== 'undefined' &&
            Bio.data.idscan !== undefined &&
            Bio.data.idscan.completed !== 'undefined'
            ? Bio.data.idscan.completed
            : '';
        if (formData.biometricComplete1 === true) {
          JointIdentityVerify();
          // setJointIdentity(true);
          formData.sixthTick = true;
          formData.repeatOff2 = true;
        }
      });
    console.log(Bio);
  };

  useEffect(() => {
    if (
      formData.Capture2 !== null &&
      formData.Capture2 !== '' &&
      formData.Capture2 !== 'undefined'
    ) {
      formData.repeatOff2 = false;
    }
    if (formData.Capture !== null && formData.Capture !== '' && formData.Capture !== 'undefined') {
      formData.repeatOff = false;
    }

    sss();
    // companyentitydetails();

    if (formData.investmentType === 'Joint') {
      jointPersonalinformation();
      JointIdentityVerify();
      jointBankdetails();
      jointentitydetailsss();
    }
    // else if(formData.investmentType === "Company"){
    //   companyentitydetails();
    // }
    else {
      IndividualPersonal();
      IndividualIdentityVerify();
      IndividualBank();
    }
    // Certification();
    TermsAndCondition();
    finalTick();
  }, []);

  useEffect(() => {
    if (formData.repeatOff === false) {
      if (formData.biometric === 'Biometric' && formData.biometricComplete !== true) {
        captureComplete();
      }
    }

    if (formData.repeatOff2 === false) {
      if (
        formData.investmentType === 'Joint' &&
        formData.biometric2 === 'Biometric' &&
        formData.biometricComplete1 !== true
      ) {
        captureCompletejoint();
      }
    }
  });




  const application4 = () => {
    applicat("saveLogout", "Progress")
  };

  const applicat = async (value, status) => {
    let IndividualExpiryDate = sessionStorage.getItem('documentExpiryDates');
    let JointExpiryDate = sessionStorage.getItem('documentExpiryDates1');
    let IndividualDOB = sessionStorage.getItem('dateOfBirth');
    let jointDOB = sessionStorage.getItem('dateOfBirth2');
    let residentialAddress1 = sessionStorage.getItem('residentialAddress1');
    let PostalAddress1 = sessionStorage.getItem('PostalAddress1');
    let residentialAddress2 = sessionStorage.getItem('residentialAddress2');
    let PostalAddress2 = sessionStorage.getItem('PostalAddress2');
    let city = sessionStorage.getItem('locality');
    let postal_code = sessionStorage.getItem('postal_code');
    let country = sessionStorage.getItem('country');
    let region = sessionStorage.getItem('sublocality_level_1');
    let route = sessionStorage.getItem('route');
    let street_number = sessionStorage.getItem('street_number');

    let city1 = sessionStorage.getItem('locality1');
    let postal_code1 = sessionStorage.getItem('postal_code1');
    let country1 = sessionStorage.getItem('country1');
    let region1 = sessionStorage.getItem('sublocality_level_11');
    let route1 = sessionStorage.getItem('route1');
    let street_number1 = sessionStorage.getItem('street_number1');


    // let city2 = sessionStorage.getItem('locality1');
    // let postal_code2 = sessionStorage.getItem('postal_code1');
    // let country2 = sessionStorage.getItem('country1');
    // let region2 = sessionStorage.getItem('sublocality_level_11');
    // let route2 = sessionStorage.getItem('route1');
    // let street_number2 = sessionStorage.getItem('street_number1');
    let   adress = {
      suburb:
        formData.region != null && formData.region != '' && formData.region != 'undefined'
          ? formData.region
          : region,
      addressLine1:
        (formData.street_number != null &&
          formData.street_number != 'undefined' &&
          formData.street_number != ''
          ? formData.street_number
          : street_number != null && street_number != 'undefined'
            ? street_number
            : '') +
        ' ' +
        (route == null ? '' : route),
      postalCode:
        formData.postalCode != null &&
          formData.postalCode != 'undefined' &&
          formData.postalCode != ''
          ? formData.postalCode
          : postal_code != null && postal_code != 'undefined' && postal_code != ''
            ? postal_code
            : '',
      city:
        formData.city != null && formData.city != 'undefined' && formData.city != ''
          ? formData.city
          : city,
      countryCode:
        formData.countryyCode != null &&
          formData.countryyCode != 'undefined' &&
          formData.countryyCode != ''
          ? formData.countryyCode
          : 'NZ',
      country:
        formData.country != null && formData.country != 'undefined' && formData.country != ''
          ? formData.country
          : country,
      isPrimary: true,
      isActive: true
    }

    // let adress;
    // if (formData.investmentType === 'Joint') {
    //   adress = {
    //     suburb:
    //       formData.region != null && formData.region != '' && formData.region != 'undefined'
    //         ? formData.region
    //         : region2,
    //     addressLine1:
    //       (formData.street_number != null &&
    //         formData.street_number != 'undefined' &&
    //         formData.street_number != ''
    //         ? formData.street_number
    //         : street_number2 != null && street_number2 != 'undefined'
    //           ? street_number2
    //           : '') +
    //       ' ' +
    //       (route2 == null ? '' : route2),
    //     postalCode:
    //       formData.postalCode != null &&
    //         formData.postalCode != 'undefined' &&
    //         formData.postalCode != ''
    //         ? formData.postalCode
    //         : postal_code2 != null && postal_code2 != 'undefined' && postal_code2 != ''
    //           ? postal_code2
    //           : '',
    //     city:
    //       formData.city != null && formData.city != 'undefined' && formData.city != ''
    //         ? formData.city
    //         : city2,
    //     countryCode:
    //       formData.countryyCode != null &&
    //         formData.countryyCode != 'undefined' &&
    //         formData.countryyCode != ''
    //         ? formData.countryyCode
    //         : 'NZ',
    //     country:
    //       formData.country != null && formData.country != 'undefined' && formData.country != ''
    //         ? formData.country
    //         : country2,
    //     isPrimary: true,
    //     isActive: true
    //   }
    // }
    // else {
    //   adress = {
    //     suburb:
    //       formData.region != null && formData.region != '' && formData.region != 'undefined'
    //         ? formData.region
    //         : region,
    //     addressLine1:
    //       (formData.street_number != null &&
    //         formData.street_number != 'undefined' &&
    //         formData.street_number != ''
    //         ? formData.street_number
    //         : street_number != null && street_number != 'undefined'
    //           ? street_number
    //           : '') +
    //       ' ' +
    //       (route == null ? '' : route),
    //     postalCode:
    //       formData.postalCode != null &&
    //         formData.postalCode != 'undefined' &&
    //         formData.postalCode != ''
    //         ? formData.postalCode
    //         : postal_code != null && postal_code != 'undefined' && postal_code != ''
    //           ? postal_code
    //           : '',
    //     city:
    //       formData.city != null && formData.city != 'undefined' && formData.city != ''
    //         ? formData.city
    //         : city,
    //     countryCode:
    //       formData.countryyCode != null &&
    //         formData.countryyCode != 'undefined' &&
    //         formData.countryyCode != ''
    //         ? formData.countryyCode
    //         : 'NZ',
    //     country:
    //       formData.country != null && formData.country != 'undefined' && formData.country != ''
    //         ? formData.country
    //         : country,
    //     isPrimary: true,
    //     isActive: true
    //   }
    // }

    let postdata = {
      applicationStage: formData.applicationStage,
      investmentType:
        formData.investmentType != null && formData.investmentType != 'Select'
          ? formData.investmentType
          : null,
      // occupation: formData.occupation != null ? formData.occupation : null,
      fundCode: formData.fundCode != null ? formData.fundCode : null,
      totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
      isMyFarm:
        formData.isMyFarm != null &&
          formData.isMyFarm != 'undefined' &&
          formData.isMyFarm != ''
          ? formData.isMyFarm == 'true'
            ? true
            : false
          : false,
      futureInvestementOpportunities:
        formData.futureInvestementOpportunities,

      primaryShareOfIncome:
        formData.shareholdingPercentage !== null &&
          formData.shareholdingPercentage !== '' &&
          formData.shareholdingPercentage !== 'undefined' &&
          formData.shareholdingPercentage !== undefined
          ? formData.shareholdingPercentage
          : 50,
      fullLegalName:
        (formData.firstNameindi != null &&
          formData.firstNameindi != '' &&
          formData.firstNameindi != 'undefined'
          ? formData.firstNameindi
          : formData.firstName != null &&
            formData.firstName != '' &&
            formData.firstName != 'undefined'
            ? formData.firstName
            : '') +
        ' ' +
        (formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName + ' '
            : '') +
        '' +
        (formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : ''),
      preferredName: formData.preferredName != null ? formData.preferredName : null,

      dateOfBirth:
        IndividualDOB != null &&
          IndividualDOB != '' &&
          IndividualDOB != 'undefined' &&
          IndividualDOB != undefined
          ? IndividualDOB
          : null,

      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      userResidentialAddress:formData.manuallyAddress == 'false' ?
      (formData.street_number +
      (formData.region != null && formData.region != undefined && formData.region != '' ? ', ' + formData.region : '') +
      (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
      (formData.postalCode != null && formData.postalCode != ''
        ? ', ' + formData.postalCode
        : '') +
      (formData.country != null && formData.country != '' ? ',' + formData.country : '')) :
      formData.isPrimaryResdAddress,
      isGoogleAddress:
        formData.manuallyAddress != null &&
          formData.manuallyAddress != 'undefined' &&
          formData.manuallyAddress != ''
          ? formData.manuallyAddress == 'true'
            ? true
            : false
          : false,

      isUserAddressSame:
        formData.isUserAddressSame === 'true' ||
          formData.isUserAddressSame === '' ||
          formData.isUserAddressSame === 'undefined' ||
          formData.isUserAddressSame === undefined
          ? 1
          : 0,
      userPostalAddress: formData.isPrimaryPostalAdress != null && formData.isPrimaryPostalAdress != '' ? formData.isPrimaryPostalAdress : null,
      priContactType:
        formData.priContactType === 'Mobile' ||
          formData.priContactType === '' ||
          formData.priContactType === 'undefined' ||
          formData.priContactType === undefined ||
          formData.priContactType === null
          ? 'Mobile'
          : formData.priContactType,
      priContactCountryCode:
        formData.priContactCountryCode === undefined ||
          formData.priContactCountryCode === 'undefined' ||
          formData.priContactCountryCode === null
          ? '64'
          : formData.priContactCountryCode,
      priContactNumber: formData.codeVerify === 'true' ? formData.priContactNumber : null,
      // isPriContactNumberVerified: formData.codeVerify === 'true' ? 1 : 0,
      isAlternateContact: formData.isAlternateContact,
      secContactType:
        formData.isAlternateContact == false
          ? null
          : formData.secContactType !== 'undefined' &&
            formData.secContactType !== null &&
            formData.secContactType !== ''
            ? formData.secContactType
            : 'Home',
      secContactCountryCode:
        formData.isAlternateContact == false
          ? null
          : formData.secContactCountryCode === undefined ||
            formData.secContactCountryCode === null ||
            formData.secContactCountryCode === 'undefined'
            ? '64'
            : formData.secContactCountryCode,

      secContactNumber: formData.isAlternateContact == false ? null : formData.secContactNumber,

      intendToInvest: formData.intendToInvest !== "" && formData.intendToInvest !== null && formData.intendToInvest !== undefined ? formData.intendToInvest : null,
      otherIntendToInvest: formData.otherIntendToInvest !== null && formData.otherIntendToInvest !== "" && formData.otherIntendToInvest !== undefined ? formData.otherIntendToInvest : null,
      totalIintend: formData.totalIintend !== null && formData.totalIintend !== "" && formData.totalIintend !== undefined ? formData.totalIintend : null,
      chooseToInvest: formData.chooseToInvest !== null && formData.chooseToInvest !== "" && formData.chooseToInvest !== undefined ? formData.chooseToInvest : null,
      otherChooseToInvest: formData.otherChooseToInvest !== null && formData.otherChooseToInvest !== "" && formData.otherChooseToInvest !== undefined ? formData.otherChooseToInvest : null,
      occupation: formData.occupation !== null && formData.occupation !== "" && formData.occupation !== null ? formData.occupation : null,
      sourceOfFund: formData.sourceOfFund !== null && formData.sourceOfFund !== "" && formData.sourceOfFund !== undefined ? formData.sourceOfFund : null,

      // occupation: formData.occupation != null ? formData.occupation : null,
      // otherOccupation: formData.otherOccupation != null ? formData.otherOccupation : null,
      placeOfBirth: formData.placeOfBirth != null ? formData.placeOfBirth : null,
      countryOfBirth: formData.countryOfBirth != null ? formData.countryOfBirth : null,
      residingCountry: formData.residingCountry != null ? formData.residingCountry : null,
      userIrdNumber: formData.userIrdNumber != null ? formData.userIrdNumber : null,
      userIrdNumberProof: null,
      taxType: formData.rwt,
      rwtValue: formData.rwt === 'Resident Withholding Tax' ? formData.rwtValue : null,
      taxExemption: formData.checkboxx === true ? true : false,
      examptProof:
        formData.examptProof !== null &&
          formData.examptProof !== '' &&
          formData.examptProof !== 'undefined'
          ? formData.examptProof
          : null,
      addressDetails: adress,
      isOtherCitizen: formData.isPostalAdd11 === 'false' ? 1 : 0,

      captureReference: formData.Capture != null ? formData.Capture : null,
      identificationType: formData.biometric != null ? formData.biometric : null,
      documentIdType:
        formData.biometric == 'Biometric'
          ? null
          : formData.idType === '1' ||
            formData.idType === '' ||
            formData.idType === 'undefined' ||
            formData.idType === undefined ||
            formData.idType === null
            ? 'NZ Driver Licence'
            : formData.idType === '2'
              ? 'NZ Passport'
              : formData.idType === '3'
                ? 'other'
                : "",
      firstName:
        formData.firstName != null && formData.firstName != '' && formData.firstName != 'undefined'
          ? formData.firstName
          : formData.firstNameindi != '' &&
            formData.firstNameindi != null &&
            formData.firstNameindi != 'undefined'
            ? formData.firstNameindi
            : null,
      middleName:
        formData.middleNameindi != null &&
          formData.middleNameindi != '' &&
          formData.middleNameindi != 'undefined'
          ? formData.middleNameindi
          : formData.middleName != null &&
            formData.middleName != '' &&
            formData.middleName != 'undefined'
            ? formData.middleName
            : '',
      lastName:
        formData.lastNameindi != null &&
          formData.lastNameindi != '' &&
          formData.lastNameindi != 'undefined'
          ? formData.lastNameindi
          : formData.lastName != null && formData.lastName != '' && formData.lastName != 'undefined'
            ? formData.lastName
            : '',
      documentNumber: formData.biometric == 'Biometric' ? null : formData.documentNumber,
      documentExpiryDate:
        formData.biometric == 'Biometric'
          ? null
          : IndividualExpiryDate != null && IndividualExpiryDate != ''
            ? IndividualExpiryDate
            : null,
      documentVersionNumber:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentVersionNumber != null
            ? formData.documentVersionNumber
            : null,
      documentProof:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentProof != null
            ? formData.documentProof
            : null,
      documentCountryOfIssue:
        formData.biometric == 'Biometric'
          ? null
          : formData.documentCountryOfIssue != null
            ? formData.documentCountryOfIssue
            : null,
      otherDocumentName:
        formData.biometric == 'Biometric'
          ? null
          : formData.otherDocumentName != null
            ? formData.otherDocumentName
            : null,
      primaryBankAccountName:
        formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
     
        // primaryBankName:
        // formData.primaryBankAccountName != null ? formData.primaryBankAccountName : null,
      
        primaryBankAccountNumber:
        formData.primaryBankAccountNumber != null ? formData.primaryBankAccountNumber : null,
      primaryBankProof: formData.primaryBankProof != null ? formData.primaryBankProof : null,
      bankSwiftCode:
        formData.isNZBank === 'undefined' || formData.isNZBank === 'true'
          ? null
          : formData.bankSwiftCode != null
            ? formData.bankSwiftCode
            : null,
      entityName: `${formData.fullName}${formData.secondholder !== "" && formData.secondholder !== null && formData.secondholder != undefined ? "&" : ""}${formData.secondholder !== "" && formData.secondholder !== null && formData.secondholder != undefined?formData.secondholder:""}`,


      isNZBank: formData.isNZBank === 'undefined' || formData.isNZBank === 'true' ? true : false,
      isPrimaryBank:
        formData.isNZBank12 === undefined ||
          formData.isNZBank12 === null ||
          formData.isNZBank12 === 'true'
          ? true
          : false,
      // certificateDetails: certificateData,

      // secondary

      secondaryDetails: {
        applicationStage: formData.applicationStage,
        investmentType:
          formData.investmentType != null && formData.investmentType != 'Select'
            ? formData.investmentType
            : '',
        occupation: formData.occupation != null ? formData.occupation : null,
        fundCode: formData.fundCode != null ? formData.fundCode : null,
        totalInvestment: formData.totalInvestment != null ? formData.totalInvestment : null,
        isMyFarm:
          formData.isMyFarm != null &&
            formData.isMyFarm != 'undefined' &&
            formData.isMyFarm != ''
            ? formData.isMyFarm == 'true'
              ? true
              : false
            : false,
        SecondaryShareOfIncome:
          formData.shareholdingPercentage != undefined ? 100 - formData.shareholdingPercentage : 50,
        fullLegalName:
          formData.secondholder != null &&
            formData.secondholder != '' &&
            formData.secondholder != 'undefined'
            ? formData.secondholder
            : '',
        preferredName: formData.preferredName2 != null ? formData.preferredName2 : null,
        dateOfBirth: jointDOB != null ? jointDOB : null,
        userResidentialAddress:
        formData.manuallyAddress2 == 'false'
        ? (formData.street_number1 +
          (formData.region1 != '' && formData.region1 != null && formData.region1 != undefined ? ', ' + formData.region1 : '') +
          (formData.city1 != '' ? ', ' + formData.city1 : '') +
          (formData.postalCode1 != null ? ', ' + formData.postalCode1 : '') +
          (formData.country1 != '' ? ', ' + formData.country1 : ''))
        :   formData.isSecondryResdAddress,
        isUserAddressSame:
          formData.isUserAddressSame2 === 'true' ||
            formData.isUserAddressSame2 === '' ||
            formData.isUserAddressSame2 === 'undefined' ||
            formData.isUserAddressSame2 === undefined
            ? 1
            : 0,
        userPostalAddress: formData.isSecondryPostalAdre != null && formData.isSecondryPostalAdre != '' ? formData.isSecondryPostalAdre : null,

        isGoogleAddress:
          formData.manuallyAddress2 != null &&
            formData.manuallyAddress2 != 'undefined' &&
            formData.manuallyAddress2 != ''
            ? formData.manuallyAddress2 == 'true' || formData.manuallyAddress2 == true
              ? true
              : formData.manuallyAddress2 == 'false' || formData.manuallyAddress2 == false
                ? false
                : true
            : true,
        priContactType:
          formData.priContactType2 === 'Mobile' ||
            formData.priContactType2 === '' ||
            formData.priContactType2 === 'undefined' ||
            formData.priContactType2 === undefined ||
            formData.priContactType2 === null
            ? 'Mobile'
            : formData.priContactType2,
        priContactCountryCode:
          formData.priContactCountryCode2 === undefined ||
            formData.priContactCountryCode2 === 'undefined' ||
            formData.priContactCountryCode2 === null
            ? '64'
            : formData.priContactCountryCode2,
        priContactNumber: formData.priContactNumber2,
        isAlternateContact: formData.isAlternateContact1,
        secContactType:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactType2 !== 'undefined' &&
              formData.secContactType2 !== '' &&
              formData.secContactType2 !== null
              ? formData.secContactType2
              : 'Home',
        secContactCountryCode:
          formData.isAlternateContact1 == false
            ? null
            : formData.secContactCountryCode2 === undefined ||
              formData.secContactCountryCode2 === null ||
              formData.secContactCountryCode2 === 'undefined'
              ? '64'
              : formData.secContactCountryCode2,
        secContactNumber: formData.isAlternateContact1 == false ? null : formData.secContactNumber2,

        intendToInvest: formData.intendToInvest3 !== "" && formData.intendToInvest3 !== null && formData.intendToInvest3 !== undefined ? formData.intendToInvest3 : null,
        otherIntendToInvest: formData.otherIntendToInvest3 !== null && formData.otherIntendToInvest3 !== "" && formData.otherIntendToInvest3 !== undefined ? formData.otherIntendToInvest3 : null,
        totalIintend: formData.totalIintend3 !== null && formData.totalIintend3 !== "" && formData.totalIintend3 !== undefined ? formData.totalIintend3 : null,
        chooseToInvest: formData.chooseToInvest3 !== null && formData.chooseToInvest3 !== "" && formData.chooseToInvest3 !== undefined ? formData.chooseToInvest3 : null,
        otherChooseToInvest: formData.otherChooseToInvest3 !== null && formData.otherChooseToInvest3 !== "" && formData.otherChooseToInvest3 !== undefined ? formData.otherChooseToInvest3 : null,
        occupation: formData.occupation3 !== null && formData.occupation3 !== "" && formData.occupation3 !== null ? formData.occupation3 : null,
        sourceOfFund: formData.sourceOfFund3 !== null && formData.sourceOfFund3 !== "" && formData.sourceOfFund3 !== undefined ? formData.sourceOfFund3 : null,

        // occupation: formData.occupation2 != null ? formData.occupation2 : null,
        // otherOccupation: formData.otherOccupation2 != null ? formData.otherOccupation2 : null,
        placeOfBirth: formData.placeOfBirth2 != null ? formData.placeOfBirth2 : null,
        countryOfBirth: formData.countryOfBirth2 != null ? formData.countryOfBirth2 : null,
        residingCountry: formData.residingCountry2 != null ? formData.residingCountry2 : null,
        userIrdNumber: formData.userIrdNumber2 != null ? formData.userIrdNumber2 : null,
        userIrdNumberProof: null,
        taxType: formData.rwt2,
        rwtValue: formData.rwt2 === 'Resident Withholding Tax' ? formData.rwtValue2 : null,
        taxExemption: formData.checkboxxjoint2 === true ? true : false,
        examptProof:
          formData.examptProof2 !== null &&
            formData.examptProof2 !== '' &&
            formData.examptProof2 !== 'undefined'
            ? formData.examptProof2
            : null,
        captureReference: formData.Capture2 != null ? formData.Capture2 : null,

        addressDetails: {
          suburb:
            formData.region1 != null && formData.region1 != '' && formData.region1 != 'undefined'
              ? formData.region1
              : region1,
          addressLine1:
            (formData.street_number1 != null &&
              formData.street_number1 != 'undefined' &&
              formData.street_number1 != ''
              ? formData.street_number1
              : street_number1 != null && street_number1 != 'undefined'
                ? street_number1
                : '') +
            ' ' +
            (route1 == null ? '' : route1),
          postalCode:
            formData.postalCode1 != null &&
              formData.postalCode1 != 'undefined' &&
              formData.postalCode1 != ''
              ? formData.postalCode1
              : postal_code1 != null && postal_code1 != 'undefined' && postal_code1 != ''
                ? postal_code1
                : '',
          city:
            formData.city1 != null && formData.city1 != 'undefined' && formData.city1 != ''
              ? formData.city1
              : city1,
          countryCode:
            formData.countryyCode3 != null &&
              formData.countryyCode3 != 'undefined' &&
              formData.countryyCode3 != ''
              ? formData.countryyCode3
              : 'NZ',
          country:
            formData.country1 != null && formData.country1 != 'undefined' && formData.country1 != ''
              ? formData.country1
              : country1,
          isPrimary: true,
          isActive: true
        },
        isOtherCitizen: formData.isPostalAdd12 === 'false' ? 1 : 0,

        // tinDetails: [
        //   {
        //     tinId:
        //       formData.tinId2 === '' ||
        //         formData.tinId2 === null ||
        //         formData.tinId2 === 'undefined' ||
        //         formData.tinId2 === undefined
        //         ? null
        //         : formData.tinId2,
        //     countryOfResidence:
        //       formData.tinResidential2 != null && formData.tinResidential2 != ''
        //         ? formData.tinResidential2
        //         : null,
        //     tinNumber:
        //       formData.tinNumber2 != null && formData.tinNumber2 != '' ? formData.tinNumber2 : null,
        //     tinReason:
        //       formData.tinReason2 != null && formData.tinReason2 != '' ? formData.tinReason2 : null
        //   }
        // ],
        captureReference: formData.Capture2 != null ? formData.Capture2 : null,
        identificationType: formData.biometric2 != null ? formData.biometric2 : null,
        documentIdType:
          formData.biometric2 == 'Biometric'
            ? ''
            : formData.idType2 === '1' ||
              formData.idType2 === '' ||
              formData.idType2 === 'undefined' ||
              formData.idType2 === undefined ||
              formData.idType2 === null
              ? 'NZ Driver Licence'
              : formData.idType2 === '2'
                ? 'NZ Passport'
                : formData.idType2 === '3'
                  ? 'other'
                  : "",
        firstName:
          formData.firstName12 != null &&
            formData.firstName12 != '' &&
            formData.firstName12 != 'undefined'
            ? formData.firstName12
            : null,
        middleName:
          formData.middleName12 != null &&
            formData.middleName12 != '' &&
            formData.middleName12 != 'undefined'
            ? formData.middleName12
            : null,
        lastName:
          formData.lastName12 != null &&
            formData.lastName12 != '' &&
            formData.lastName12 != 'undefined'
            ? formData.lastName12
            : null,
        documentNumber: formData.biometric2 == 'Biometric' ? null : formData.documentNumber2,
        documentExpiryDate:
          formData.biometric2 == 'Biometric'
            ? null
            : JointExpiryDate != null && JointExpiryDate != ''
              ? JointExpiryDate
              : null,
        documentVersionNumber:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentVersionNumber2 != null
              ? formData.documentVersionNumber2
              : null,
        documentCountryOfIssue:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentCountryOfIssue2 != null
              ? formData.documentCountryOfIssue2
              : null,
        otherDocumentName:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.otherDocumentName2 != null
              ? formData.otherDocumentName2
              : null,
        documentProof:
          formData.biometric2 == 'Biometric'
            ? null
            : formData.documentProof2 != null
              ? formData.documentProof2
              : null,
        isNZBank:
          formData.isNZBank2 === 'true'
            ? true
            : formData.isNZBank2 === undefined
              ? null
              : formData.isNZBank12 === 'false' && formData.isNZBank12 === 'false'
                ? false
                : true,
        // isNZBank: ,
        isPrimaryBank:
          formData.isNZBank12 === undefined ||
            formData.isNZBank12 === null ||
            formData.isNZBank12 === 'true'
            ? true
            : false,
        primaryBankAccountName:
          formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
       
          // primaryBankName:
          // formData.primaryBankAccountName2 != null ? formData.primaryBankAccountName2 : null,
       
          primaryBankAccountNumber:
          formData.primaryBankAccountNumber2 != null ? formData.primaryBankAccountNumber2 : null,
        primaryBankProof: formData.primaryBankProof2 != null ? formData.primaryBankProof2 : null,
        bankSwiftCode:
          formData.isNZBank2 === 'undefined' || formData.isNZBank2 === 'true'
            ? ''
            : formData.bankSwiftCode2 != null
              ? formData.bankSwiftCode2
              : null,
        entityName: `${formData.fullName}${formData.secondholder !== "" && formData.secondholder !== null && formData.secondholder != undefined ? "&" : ""}${formData.secondholder !== "" && formData.secondholder !== null && formData.secondholder != undefined ? formData.secondholder:"" }`,

        userEmail:
          formData.userEmail === formData.email1
            ? formData.userCode
            : formData.email1 != null && formData.email1 != '' && formData.email1 != 'undefined'
              ? formData.email1
              : formData.userCode,

        // certificateDetails: certificateDataSecondary
      }
    };
    if (value == "save" && status === "Progress") {

      formData.applicationStage = 'In Progress';
      if (formData.investmentType === 'Joint') {

        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                Swal.fire({
                  html: '<p class="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                  }
                });

                setLoader(false);
                formData.bioVerify = '';
              } else {
                Swal.fire({
                  html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                });
                setLoader(false);
              }
            }else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }else if(res.status === 208){
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });



      } else if (formData.investmentType === 'Individual') {
        setLoader(true);
        delete postdata.secondaryDetails;

        await axios
          .put(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                Swal.fire({
                  html: '<p class ="sweettt">Your application data has been saved. A new internet browser window should open and take you through the biometrics process (if the new window has not opened, the pop up may have been blocked, in which case you will need to allow the pop up to open).</p><p class="sweettt">Once you have completed the biometrics process, please come back to this page to resume your application.</p>',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                  }
                });
                setLoader(false);
                formData.bioVerify = '';
              } else {
                Swal.fire({
                  html: 'Your application data has been saved, you can now logout and resume your application when you are ready.',
                  confirmButtonColor: '#231f20',
                  allowOutsideClick: false
                });
                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });


      }
      else {
        Swal.fire({
          // icon: 'error',
          // title: 'Oops...',
          text: 'Please select the Investment type'
        });
      }

    } else if (value == "post" && status === "Submit") {
      formData.applicationStage = 'Submitted';

      if (formData.investmentType === 'Joint') {
        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        await axios
          .post(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            }else if(res.status === 208){
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false
              });
              setLoader(false);
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            } else {
              Swal.close();

              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });
      } else if (formData.investmentType === 'Individual') {
        delete postdata.secondaryDetails;
        setLoader(true);
        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setLoader(false);
        delete postdata.companyDetails
        await axios
          .post(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            } else if (res.status === 202) {
              setLoader(false);
              Swal.fire({
                text: `${res.data.message}`
              });
            } else {
              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });


      } else if (formData.investmentType === 'Company') {
        setLoader(true);

        Swal.fire({
          html: 'Please wait a moment while we submit your application. Don’t close this window or click out of it.',
          didOpen: () => {
            Swal.showLoading();
          }
        });
        setLoader(false);

        await axios
          .post(`${multisavedata}/onboard/company`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              go('Twentytwo');
              setLoader(false);
              Swal.close();
            } else if (res.status === 202) {
              setLoader(false);
              Swal.fire({
                text: `${res.data.message}`
              });
            } else {
              setLoader(false);
            }
          })
          .catch(() => {
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
            setLoader(false);
          });
      }

    }
    else if (value == "saveLogout" && status === "Progress") {
      formData.applicationStage = 'In Progress';
      if (formData.investmentType === 'Joint') {
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/joint`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }else if(res.status === 208){
              Swal.fire({
                html: 'Unfortunately we have encountered a problem while trying to save and submit your application.<p>Please go back to the Entity Details section by clicking on the menu button on the left, and amend the email address for the second joint account holder to be the same email address as you have provided for the first joint account holder.</p><p>You can should then be able to click back to the Review & Submit menu button, and Submit your application.</p> ',
                confirmButtonColor: '#231f20',
                allowOutsideClick: false
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else if (formData.investmentType === 'Individual') {
        delete postdata.secondaryDetails;
        setLoader(true);
        await axios
          .put(`${multisavedata}/onboard/individual`, postdata, {
            headers: { Authorization: `Bearer ${formData.token}` }
          })
          .then((res) => {
            if (res.status === 200) {
              if (formData.bioVerify == true) {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
                formData.bioVerify = '';
              } else {
                sessionStorage.clear();
                localStorage.clear();
                logout();

                setLoader(false);
              }
            } else if (res.status === 202) {
              Swal.fire({
                html: `${res.data.message}`
              });
              setLoader(false);
            }
          })
          .catch(() => {
            setLoader(false);
            Swal.fire({
              // icon: 'error',
              // title: 'Oops...',
              html: '<p class="oops">Oops! Unfortunately we have encountered an issue while trying to save and submit your application.</p><p class="oops">Please try to Submit your application again. If it says “Application already submitted”, then your application has submitted and you can logout.</p><p class="oops"> If this same error message comes up when you try to resubmit your application, you will need to click on the button to Save Data, and then Logout.</p> <p class="oops">You will then need to log back into your existing application, you can find the link to login in the email we sent you to verify your email address. Click on the link in the email, then click on the button to CONTNUE MY EXISTING APPLICATION. You will need to login again, and resume your application. We apologise if you need to re-enter your application details.</p>',
              allowOutsideClick: false
            });
          });
      } else {
        Swal.fire({
          // icon: 'error',
          // title: 'Oops...',
          text: 'Please select the Investment type'
        });
      }

    }
  }

  useEffect(() => {
    if (formData.submissionStatus == 1) {
      Swal.fire({
        text: 'Your application has already been submitted.',
        allowOutsideClick: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        logout();
        /* Read more about isConfirmed, isDenied below */
        // if (result.isConfirmed) {
        //   Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
        //   Swal.fire('Changes are not saved', '', 'info')
        // }
      });
    }
  }, [])

  return (
    <div className={`maisdn ${isActive ? '' : 'is_active'}`}>
      <button onClick={handleToggle}>
        <i class="fa fa-bars" aria-hidden="true"></i>
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
      <div className="left_Menus">
        <div className="left_Menus_Top">
          <div>
            <div class="nav_logo circle-box">
              <ConfigProvider colors={['#dcd4cc', '#dcd4cc', '#dcd4cc']}>
                <Avatar
                  className="avatarname"
                  name={
                    (formData.firstNameindi !== null &&
                      formData.firstNameindi !== '' &&
                      formData.firstNameindi !== undefined
                      ? formData.firstNameindi
                      : formData.firstName !== null &&
                        formData.firstName !== '' &&
                        formData.firstName !== 'undefined' &&
                        formData.firstName !== undefined
                        ? formData.firstName
                        : '') +
                    ' ' +
                    (formData.lastNameindi !== null &&
                      formData.lastNameindi !== '' &&
                      formData.lastNameindi !== undefined
                      ? formData.lastNameindi
                      : formData.lastName !== null &&
                        formData.lastName !== '' &&
                        formData.lastName !== 'undefined' &&
                        formData.lastName !== undefined
                        ? formData.lastName
                        : '')
                  }
                />
              </ConfigProvider>{' '}
            </div>
            <p>
              {formData.fullName !== 'undefined' &&
                formData.fullName !== '' &&
                formData.fullName !== null &&
                formData.fullName !== undefined
                ? formData.fullName
                : (formData.firstNameindi !== null &&
                  formData.firstNameindi !== '' &&
                  formData.firstNameindi !== 'undefined' &&
                  formData.firstNameindi !== undefined
                  ? formData.firstNameindi
                  : formData.firstName !== null &&
                    formData.firstName !== '' &&
                    formData.firstName !== 'undefined'
                    ? formData.firstName
                    : '') +
                ' ' +
                (formData.middleNameindi !== null &&
                  formData.middleNameindi !== '' &&
                  formData.middleNameindi !== 'undefined'
                  ? formData.middleNameindi
                  : formData.middleName !== null &&
                    formData.middleName !== '' &&
                    formData.middleName !== 'undefined'
                    ? formData.middleName + ' '
                    : '') +
                '' +
                (formData.lastNameindi !== null &&
                  formData.lastNameindi !== '' &&
                  formData.lastNameindi !== 'undefined'
                  ? ' ' + formData.lastNameindi
                  : formData.lastName !== null &&
                    formData.lastName !== '' &&
                    formData.lastName !== 'undefined'
                    ? ' ' + formData.lastName
                    : '')}
            </p>
          </div>
          <div className="menuCompanyName">{formData.companyName}</div>
          <button
            className={formData.class === 'Second' ? 'active' : null}
            disabled={formData.unclick}
            onClick={HandleSecond}
          >
            <i class="fa fa-angle-right" aria-hidden="true">
              {' '}
            </i>
            <span id="first" className="left_menu11">
              Investment details
            </span>
            {right === false ? '' : <i class="fas fa-check"></i>}{' '}
          </button>

          {formData.investmentType === 'Joint' ? (
            <button
              className={formData.class === 'Jointhird' ? 'active' : null}
              onClick={HandleJointThird}
              disabled={formData.unclick}
            >
              <i class="fa fa-angle-right" aria-hidden="true">
                {' '}
              </i>
              <span id="second" className="left_menu11">
                Entity Details{' '}
              </span>
              {jointthirdentity === false ? '' : <i class="fas fa-check"></i>}
            </button>
          ) : (
            ''
          )}

          {formData.investmentType === 'Company' ? (
            <button
              className={formData.class === 'Jointhird' ? 'active' : null}
              // className='active'
              onClick={HandleEntityOne}
              disabled={formData.unclick}
            >
              <i class="fa fa-angle-right" aria-hidden="true">
                {' '}
              </i>
              <span id="second2" className="left_menu11">
                Entity Details{' '}
              </span>
              {companyentity === false ? '' : <i class="fas fa-check"></i>}
            </button>
          ) : (
            ''
          )}

          <button
            className={formData.class === 'Third' ? 'active' : null}
            disabled={formData.unclick}
            onClick={HandleThird}
          >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
            <span id="third" className="left_menu11">
              {' '}
              Personal information
            </span>
            {formData.investmentType === 'Joint' ? (
              jointpersonal === false ? null : (
                <i class="fas fa-check"></i>
              )
            ) : personal === false ? null : (
              <i class="fas fa-check"></i>
            )}
          </button>

          <button
            onClick={handleFive}
            disabled={formData.unclick || formData.isMyFarm == "true"}
            className={formData.class === 'Five' ? 'active' : null}
          >
            <i class="fa fa-angle-right" aria-hidden="true">
              {' '}
            </i>{' '}
            <span id="fifth" className="left_menu11">
              {' '}
              Identity Verification
            </span>
            {formData.investmentType === 'Joint' ? (
              jointIdentity === false ? null : (
                <i class="fas fa-check"></i>
              )
            ) : identity === false ? null : (
              <i class="fas fa-check"></i>
            )}
          </button>
          <button
            onClick={HandleEight}
            disabled={formData.unclick || formData.isMyFarm == "true"}
            className={formData.class === 'Eight' ? 'active' : null}
          >
            <i class="fa fa-angle-right" aria-hidden="true">
              {' '}
            </i>
            <span id="seven" className="left_menu11">
              {' '}
              Bank Account Details
            </span>
            {formData.investmentType === 'Joint' ? (
              jointBank === false ? null : (
                <i class="fas fa-check"></i>
              )
            ) : bank === false ? null : (
              <i class="fas fa-check"></i>
            )}
          </button>
          <button
            onClick={Handletwentyone}
            disabled={formData.unclick}
            className={formData.class === 'Twentyone' ? 'active' : null}
          >
            <i class="fa fa-angle-right" aria-hidden="true">
              {' '}
            </i>
            <span id="eleven" className="left_menu11">
              {' '}
              Terms & conditions
            </span>
            {termcondi === false ? null : <i class="fas fa-check "></i>}
          </button>

          <button
            disabled={formData.unclick}
            onClick={Handletwentyone11}
            className={formData.class === 'Twentyone11' ? 'active' : null}
          >
            <i class="fa fa-angle-right" aria-hidden="true"></i>
            <span className="left_menu11"> Review & Submit</span>
          </button>
        </div>
        
        {formData.hidinglogout === false ? null : (
          loader===true? <div className="note-main-loader note-load">
          <div className="loader"></div>
        </div> :
          <div className="logout__bTN">
            <button onClick={llogggout}>SAVE & LOGOUT</button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MenuSidebar;
