/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';

import logo from '../logo.png';

import swal from 'sweetalert';
import info from '../info.png';
import $ from 'jquery';
import MenuSidebar from './MenuSidebar';
import { getName } from 'country-list';
import { infodetls } from './SaveInfo';



const Twentyone11 = ({ func, functwo, setForm, formData, navigation, saveAndExist }) => {
  const { otherOccupation, fulllname, termss, countryOfBirth, terms } = formData;
  const { previous, next } = navigation;



  // Validation All section starts.....................
  const [check, setCheck] = useState(true);
  formData.check = check;
  const [check2, setCheck2] = useState(true);
  formData.check2 = check2;
  const [check3, setCheck3] = useState(true);
  formData.check3 = check3;
  const [check4, setCheck4] = useState(true);
  formData.check4 = check4;
  const [check5, setCheck5] = useState(true);
  formData.check5 = check5;
  const [check6, setCheck6] = useState(true);
  formData.check6 = check6;
  const [check7, setCheck7] = useState(true);
  formData.check7 = check7;
  // Validation All section endsWith...............
  const infodetls2 = () => {
    swal({
      text: "You need to complete all required information before submitting your application. Please return to the menu button(s) on the left shown in red, which indicate the section where some fields have not yet been completed, and fill in those questions. Once you have completed the missing field(s), you can click on the menu button Review & Submit to return to this page and submit your application.",
      customClass: "bg_colory"

    })
  };
  useEffect(() => {
    if (check7 === false || check === false || check2 === false || check3 === false || check4 === false || check5 === false) {
      infodetls2()
    }
  })

  const [investbussiness1, setInvestbussiness1] = useState(formData.investmentBusines != "" &&
    formData.investmentBusiness != null ? formData.investmentBusiness.split('##') : ''
  );
  const [investproduct1, setInvestproduct1] = useState(
    formData.investmentProductExp != null ? formData.investmentProductExp.split('##') : ''
  );

  const [investment1, setInvestment] = useState(
    formData.investmentCriteria != null ? formData.investmentCriteria.split('##') : ''
  );
  const [otherinvest1, setOtherinvest] = useState(
    formData.otherInvestCriteria != null ? formData.otherInvestCriteria.split('##') : ''
  );

  const [certficateDetails2, setCertificateDetails2] = useState(
    formData.Certificate2 !== null && formData.Certificate2 !== "" && formData.Certificate2 !== "undefined"
      && formData.Certificate2 !== undefined ? formData.Certificate2.split('##') : ""
  )

  formData.hhhhh =
    investbussiness1 !== null &&
      investbussiness1 !== '' &&
      investbussiness1 !== 'undefined' &&
      investbussiness1 !== undefined
      ? investbussiness1
      : '';
  formData.hhhhh1 =
    investment1 !== null &&
      investment1 !== '' &&
      investment1 !== 'undefined' &&
      investment1 !== undefined
      ? investment1
      : '';
  formData.hhhhh12 =
    investproduct1 !== null &&
      investproduct1 !== '' &&
      investproduct1 !== 'undefined' &&
      investproduct1 !== undefined
      ? investproduct1
      : '';



  let JointAcount = sessionStorage.getItem('Jointacoount');
  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '17';
    go('submit');
  };


  const saveandexit = () => {
    func();
  };

  let setValues = localStorage.getItem('setValues');
  
  let applicationType = sessionStorage.getItem('applicationType');

  let previousHandler = () => {
    if (applicationType === 'Trust') {
      go('Jointfivteen');
    } else {
      formData.termss = termss
      formData.terms = terms
      formData.termsss1 = false
      formData.termsss2 = false
      formData.termsss3=false
      formData.futureInvestementOpportunities = false
      formData.class = 'Twentyone';
      previous();
    }
  };

  ///

  function setDateformate(date) {
    if (
      date !== null &&
      date !== 'undefined' &&
      typeof date !== 'undefined' &&
      date.includes('/')
    ) {
      let dat = date.split('/');
      dat = dat[2] + '-' + dat[1] + '-' + dat[0];
      return dat;
    }
    return date;
  }


  let residentialAddress1 = sessionStorage.getItem("residentialAddress1");
  let residentialAddress2 = sessionStorage.getItem("residentialAddress2");

  const handleClick = async (e, values) => {
    let pages = formData.tickCount;
    if (formData.investmentType == 'Individual' && pages == 6) {
      formData.color = false
      functwo();
    }

    else if (formData.investmentType == 'Joint' && pages == 7) {
      formData.color = false
      functwo();
    }

    else {
      formData.color = true
      if (formData.seventhTick === false || formData.eightTick === false) {
        $('#seven').addClass("fillColor");
      }

      if (formData.firstTick === false) {
        $('#first').addClass("fillColor");
        setCheck2(false);
      }
      if (formData.secondTick === false) {
        $('#second').addClass("fillColor");
        setCheck6(false)
      }
      if (formData.thirdTick === false || formData.fourthTick == false) {
        $('#third').addClass("fillColor");
        setCheck(false);
      }
      if (formData.fifthTick === false || formData.sixthTick == false) {
        setCheck3(false);
        if (formData.documentProof1 != "" && formData.documentProof1 != null && formData.documentProof1 != "undefined" && formData.documentProof1 != undefined) {
          formData.documentnotupload = true

        }
        else {
          formData.documentnotupload = false
        }

        if (formData.documentProof21 != "" && formData.documentProof21 != null && formData.documentProof21 != "undefined" && formData.documentProof21 != undefined) {
          formData.documentnotupload1 = true
        }

        else {
          formData.documentnotupload1 = false
        }

        $('#fifth').addClass("fillColor");
      }
      if (formData.seventhTick === false || formData.eightTick === false) {
        // setCheck4(false)
      }
      // if (formData.nineTick === false) {
      //   $('#nine').addClass("fillColor");
      //   setCheck5(false)
      // }
      if (formData.elevenTick === false) {
        $('#eleven').addClass("fillColor");
        setCheck7(false)
      }
      // Swal.fire({
      //   text: `Please complete or update the missing information by clicking on the menu button that is not yet ticked, and updating the missing information. Once you have completed the missing information and the menu button has updated to be ticked, you can click back onto the Review & Submit menu button to submit your application.`
      // });
    }
  };

  // const handleClick = async (e, values) => {functwo();}

  function removeError() {
    $('.error').html('');
  }
  const { go } = navigation;
  // function numberWithCommas(x) {
  //   return x.toString().replace(/(?<!\.\d+)\B(?=(\d{3})+\b)/g, ",");
  // }

  let IndividualExpiryDate = sessionStorage.getItem("documentExpiryDates");
  let JointExpiryDate = sessionStorage.getItem("documentExpiryDates1");
  console.log("formData.otherDocumentName2", formData.otherDocumentName2)
  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          {
            formData.investmentType == "Joint" ?
              <h2>Step 7: Review & Submit</h2> :
              <h2>Step 6: Review & Submit</h2>
          }

        </div>
        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          {!JointAcount ? (
            <div className="save-exit se_invff">
              <p className="save next" onClick={saveandexit}>
                SAVE DATA{' '}
              </p>
              <img src={info} onClick={infodetls} className="info" />
            </div>
          ) : null}

          {formData.investmentType === 'Individual' ? (
            <div className="ind">
              <h3>Investment Details:</h3>
              <div className="review_b">
                <label>Entity Type</label>
                <p>{formData.investmentType} </p>
              </div>
              {formData.fundCode !== null &&
                formData.fundCode !== '' &&
                formData.fundCode !== 'undefined' &&
                formData.fundCode !== undefined ? (
                <div className="review_b">
                  <label>Investment Opportunity</label>

                  <p>{formData.fundCode} </p>
                </div>
              ) : (
                ''
              )}
              {formData.totalInvestment !== null &&
                formData.totalInvestment !== '' &&
                formData.totalInvestment !== 'undefined' &&
                formData.totalInvestment !== undefined ? (
                <div className="review_b">
                  <label>Investment Amount</label>
                  <p>${(formData.totalInvestment)} </p>
                </div>
              ) : (
                ''
              )}

              {
                formData.isMyFarm === 'true' ? (
                  <div className="review_b">
                    <label>Investment With MyFarm In The Past 2 Years</label>
                    <p className='textCapitalize'>Yes</p>
                  </div>
                ) : (
                  <div className="review_b">
                    <label>Investment With MyFarm In The Past 2 Years</label>
                    <p className='textCapitalize'>No</p>
                  </div>
                )}

              <hr/>

              <h3>Personal Information:</h3>
              {formData.fullName !== null &&
                formData.fullName !== '' &&
                formData.fullName !== undefined &&
                formData.fullName !== 'undefined' ? (
                <div className="review_b">
                  <label>Full Name</label>
                  <p className='textCapitalize'>{formData.fullName}</p>
                </div>
              ) : (
                ''
              )}

              {formData.preferredName !== null &&
                formData.preferredName !== '' &&
                formData.preferredName !== undefined &&
                formData.preferredName !== 'undefined' ? (
                <div className="review_b">
                  <label>Preferred Name</label>
                  <p className='textCapitalize'>{formData.preferredName}</p>
                </div>
              ) : (
                ''
              )}

              {formData.userEmail !== null &&
                formData.userEmail !== '' &&
                formData.userEmail !== 'undefined' &&
                formData.userEmail !== undefined ? (
                <div className="review_b">
                  <label>Email</label>
                  <p className={"userEmail"}>{formData.userEmail}</p>
                </div>
              ) : (
                ''
              )}



              {formData.dateOfBirth !== null &&
                formData.dateOfBirth !== '' &&
                formData.dateOfBirth !== 'undefined' &&
                formData.dateOfBirth !== undefined ? (
                <div className="review_b">
                  <label>Date of Birth</label>
                  <p>{formData.dateOfBirth} </p>
                </div>
              ) : (
                ''
              )}

              {((formData.isPrimaryResdAddress !== null &&
                formData.isPrimaryResdAddress !== '' &&
                formData.isPrimaryResdAddress !== 'undefined' &&
                formData.isPrimaryResdAddress !== undefined)) ? (
                <div className="review_b">
                  <label>Residential Address </label>
                  <p>{ formData.isPrimaryResdAddress != null &&
                      formData.isPrimaryResdAddress != '' &&
                      formData.isPrimaryResdAddress != 'undefined'  &&  formData.manuallyAddress != 'false'
                      ? formData.isPrimaryResdAddress
                      :
                      formData.manuallyAddress == 'false' ?
                      
                      
                      formData.street_number +
                      (formData.region != null && formData.region != '' && formData.region != undefined ? ', ' + formData.region : '') +
                      (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
                      (formData.postalCode != null && formData.postalCode != ''
                        ? ', ' + formData.postalCode
                        : '') +
                      (formData.country != null && formData.country != '' ? ', ' + formData.country : '')
                      : null} </p>
                </div>
              ) : (
                ''
              )}
{
                (formData.isPrimaryPostalAdress!== null &&
                  formData.isPrimaryPostalAdress!== '' &&
                  formData.isPrimaryPostalAdress !== 'undefined' &&
                  formData.isPrimaryPostalAdress !== undefined) || (formData.isPrimaryResdAddress !== null &&
                    formData.isPrimaryResdAddress !== '' &&
                    formData.isPrimaryResdAddress !== 'undefined' &&
                    formData.isPrimaryResdAddress !== undefined) ? (
                <div className="review_b">
                  <label> {formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal Address" : (formData.isPrimaryPostalAdress!== null &&
                  formData.isPrimaryPostalAdress!== '' &&
                  formData.isPrimaryPostalAdress !== 'undefined' &&
                  formData.isPrimaryPostalAdress !== undefined) ?
                  "Postal Address" : null} </label>
                  <p className='post'>{formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal address is the same as home address" : formData.isPrimaryPostalAdress} </p>
                </div>
              ) : (
                ''
              )}


              {
                formData.priContactType !== '' &&
                  formData.priContactType !== undefined &&
                  formData.priContactType !== 'undefined' ? (
                  <div className="review_b">
                    <label> Primary Contact Type</label>
                    <p>{formData.priContactType == null ? "Mobile" : formData.priContactType} </p>
                  </div>
                ) : (
                  ''
                )}

              {formData.priContactNumber !== null &&
                formData.priContactNumber !== '' &&
                formData.priContactNumber !== 'undefined' &&
                formData.priContactNumber !== undefined ? (
                <div className="review_b">
                  <label>Primary Contact Number</label>
                  <p>{"+"}{formData.priContactCountryCode != null && formData.priContactCountryCode !== "" ? formData.priContactCountryCode : "64"}{" "}{formData.priContactNumber} </p>
                </div>
              ) : (
                ''
              )}

              {
                (formData.secContactNumber !== '' && formData.secContactNumber !== null &&
                  formData.secContactNumber !== undefined &&
                  formData.secContactNumber !== 'undefined' ? (
                  <div className="review_b">
                    <label> Secondary Contact Type</label>
                    <p>{formData.secContactType === null ? "Home" : formData.secContactType} </p>
                  </div>
                ) : (
                  ''
                ))}


              {
                (formData.secContactNumber !== null &&
                  formData.secContactNumber !== '' &&
                  formData.secContactNumber !== 'undefined' &&
                  formData.secContactNumber !== undefined ? (
                  <div className="review_b">
                    <label>Secondary Contact Number</label>
                    <p>{"+"}{formData.secContactCountryCode != null ? formData.secContactCountryCode : "64"}{" "}{formData.secContactNumber} </p>
                  </div>
                ) : (
                  ''
                ))}

              <>
                {
                  (formData.intendToInvest !== null &&
                    formData.intendToInvest !== 'Select' &&
                    formData.intendToInvest !== 'undefined' &&
                    formData.intendToInvest !== undefined ? (
                    <div className="review_b">
                      <label>Intend To Invest</label>
                      <p>{formData.intendToInvest} </p>
                    </div>
                  ) : (
                    ''
                  ))}
                {
                  (formData.intendToInvest == 'Other'
                    ? (
                      <div className="review_b">
                        <label>Other Intend To Invest</label>
                        <p>{formData.otherIntendToInvest} </p>
                      </div>
                    ) : (
                      ''
                    ))
                }
                {
                  (formData.totalIintend !== null &&
                    formData.totalIintend !== 'Select' &&
                    formData.totalIintend !== 'undefined' &&
                    formData.totalIintend !== undefined ? (
                    <div className="review_b">
                      <label> Intend To Total Invest</label>
                      <p>{formData.totalIintend} </p>
                    </div>
                  ) : (
                    ''
                  ))}
                {
                  (formData.chooseToInvest !== null &&
                    formData.chooseToInvest !== 'Select' &&
                    formData.chooseToInvest !== 'undefined' &&
                    formData.chooseToInvest !== undefined ? (
                    <div className="review_b">
                      <label>Choose To Invest</label>
                      <p>{formData.chooseToInvest} </p>
                    </div>
                  ) : (
                    ''
                  ))}
                {
                  (formData.chooseToInvest == 'Other'
                    ? (
                      <div className="review_b">
                        <label>Other Choose To Invest</label>
                        <p>{formData.otherChooseToInvest} </p>
                      </div>
                    ) : (
                      ''
                    ))
                }
                {
                  (formData.occupation !== null &&
                    formData.occupation !== '' &&
                    formData.occupation !== 'undefined' &&
                    formData.occupation !== undefined ? (
                    <div className="review_b">
                      <label>Your Occupation </label>
                      <p>{formData.occupation} </p>
                    </div>
                  ) : (
                    ''
                  ))}
                {
                  (formData.sourceOfFund !== null &&
                    formData.sourceOfFund !== '' &&
                    formData.sourceOfFund !== 'undefined' &&
                    formData.sourceOfFund !== undefined ? (
                    <div className="review_b">
                      <label>Source of Fund</label>
                      <p>{formData.sourceOfFund} </p>
                    </div>
                  ) : (
                    ''
                  ))}
              </>

              {
                formData.isMyFarm == 'false' ?
                  <>
                    {formData.placeOfBirth !== null &&
                      formData.placeOfBirth !== '' &&
                      formData.placeOfBirth !== 'undefined' &&
                      formData.placeOfBirth !== undefined ? (
                      <div className="review_b">
                        <label>Place of Birth</label>
                        <p>{formData.placeOfBirth} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.countryOfBirth !== null &&
                      formData.countryOfBirth !== '' &&
                      formData.countryOfBirth !== undefined &&
                      formData.countryOfBirth !== 'undefined' ? (
                      <div className="review_b">
                        <label>Country of Birth</label>
                        <p>{getName(formData.countryOfBirth)} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.residingCountry !== null &&
                      formData.residingCountry !== '' &&
                      formData.residingCountry !== 'undefined' &&
                      formData.residingCountry !== undefined ? (
                      <div className="review_b">
                        <label>Country of Residence</label>

                        <p>{getName(formData.residingCountry)} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.userIrdNumber !== null &&
                      formData.userIrdNumber !== '' &&
                      formData.userIrdNumber !== 'undefined' &&
                      formData.userIrdNumber !== undefined ? (
                      <div className="review_b">
                        <label>IRD Number</label>
                        <p>{formData.userIrdNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.rwt !== null && formData.rwt !== "Select" &&
                      formData.rwt !== '' &&
                      formData.rwt !== 'undefined' &&
                      formData.rwt !== undefined ? (
                      <div className="review_b">
                        <label> Tax Type</label>
                        <p>{formData.rwt} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.checkboxx !== null &&
                      formData.checkboxx !== '' &&
                      formData.checkboxx !== 'undefined' &&
                      formData.checkboxx === true && formData.rwt === "Non Resident Withholding Tax" ? (
                      <div className="review_b">
                        <label>Resident Withholding Tax Exemption</label>
                        <p>{formData.checkboxx === true ? "Yes" : "No"}</p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.rwtValue !== null &&
                      formData.rwtValue !== '' &&
                      formData.rwtValue !== 'undefined' &&
                      formData.rwtValue !== undefined &&
                      formData.rwt === "Resident Withholding Tax" ? (
                      <div className="review_b">
                        <label> Resident Withholding Tax Rate</label>
                        <p>{formData.rwtValue} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.tinResidential !== null &&
                      formData.tinResidential !== '' &&
                      formData.tinResidential !== 'undefined' &&
                      formData.tinResidential !== undefined ? (
                      <div className="review_b">
                        <label>Country of tax residence</label>

                        <p>{getName(formData.tinResidential)} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.tinNumber !== null &&
                      formData.tinNumber !== '' &&
                      formData.tinNumber !== 'undefined' &&
                      formData.tinNumber !== undefined ? (
                      <div className="review_b">
                        <label>Tax Identification Number (TIN)</label>
                        <p>{formData.tinNumber} </p>
                      </div>
                    ) : (
                      ''
                    )}

                    {formData.tinreason !== null &&
                      formData.tinreason !== '' &&
                      formData.tinreason !== 'undefined' &&
                      formData.tinreason !== undefined ? (
                      <div className="review_b">
                        <label>Reason if TIN is not available</label>
                        <p>{formData.tinreason} </p>
                      </div>
                    ) : (
                      ''
                    )}
                  </> : null
              }

              <hr />

              {
                formData.isMyFarm == 'true' ? null : <>
                  {formData.biometric === "Biometric" ? "" :
                    <>
                      <h3>Identity Verification:</h3>
                      {(formData.idType === '' || formData.idType === '1' || formData.idType === '2' || formData.idType === '3') ? (
                        <div className="review_b">
                          <label>Identity Verification Type </label>

                          <p>{formData.idType === '1' ||
                            formData.idType === '' ||
                            formData.idType === 'undefined' ||
                            formData.idType === undefined ||
                            formData.idType === null
                            ? 'NZ Driver Licence'
                            : formData.idType === '2'
                              ? 'NZ Passport'
                              : formData.idType === '3' ? "Other" : null
                            // : formData.otherDocumentName
                          }
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className="review_b">
                        <label>First Name</label>
                        <p>{formData.firstName} </p>
                      </div>
                      {formData.middleName !== null &&
                        formData.middleName !== '' &&
                        formData.middleName !== 'undefined' &&
                        formData.middleName !== undefined ? (
                        <div className="review_b">
                          <label>Middle Name</label>
                          <p>{formData.middleName} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.lastName !== null &&
                        formData.lastName !== '' &&
                        formData.lastName !== 'undefined' &&
                        formData.lastName !== undefined ? (
                        <div className="review_b">
                          <label>Last Name</label>
                          <p>{formData.lastName} </p>
                        </div>
                      ) : (
                        ''
                      )} </>
                  }



                  {formData.biometric === "Biometric" ? "" :
                    <>
                      {(formData.idType === '' || formData.idType === '1') &&
                        formData.documentNumber !== null &&
                        formData.documentNumber !== '' &&
                        formData.documentNumber !== undefined &&
                        formData.documentNumber !== 'undefined' ? (
                        <div className="review_b">
                          <label>Licence Number</label>
                          <p>{formData.documentNumber} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.idType === '2' &&
                        formData.documentNumber !== null &&
                        formData.documentNumber !== '' &&
                        formData.documentNumber !== 'undefined' &&
                        formData.documentNumber !== undefined ? (
                        <div className="review_b">
                          <label>Passport Number</label>
                          <p>{formData.documentNumber} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.idType === '3' &&
                        formData.documentNumber !== null &&
                        formData.documentNumber !== '' &&
                        formData.documentNumber !== 'undefined' &&
                        formData.documentNumber !== undefined ? (
                        <div className="review_b">
                          <label>Document Number</label>
                          <p>{formData.documentNumber} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.idType === '3' &&
                        formData.otherDocumentName !== null &&
                        formData.otherDocumentName !== '' &&
                        formData.otherDocumentName !== 'undefined' &&
                        formData.otherDocumentName !== undefined ? (
                        <div className="review_b">
                          <label>Document type</label>
                          <p>{formData.otherDocumentName} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {(formData.idType == '1') &&
                        IndividualExpiryDate !== null &&
                        IndividualExpiryDate !== '' &&
                        IndividualExpiryDate !== 'undefined' &&
                        IndividualExpiryDate !== undefined ? (
                        <div className="review_b">
                          <label>Licence Expiry Date</label>
                          <p>{IndividualExpiryDate} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.idType === '2' &&
                        IndividualExpiryDate !== null &&
                        IndividualExpiryDate !== '' &&
                        IndividualExpiryDate !== 'undefined' &&
                        IndividualExpiryDate !== undefined ? (
                        <div className="review_b">
                          <label>Passport Expiry Date</label>
                          <p>{IndividualExpiryDate} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.idType === '3' &&
                        IndividualExpiryDate !== null &&
                        IndividualExpiryDate !== '' &&
                        IndividualExpiryDate !== 'undefined' &&
                        IndividualExpiryDate !== undefined ? (
                        <div className="review_b">
                          <label>Document Expiry Date</label>
                          <p>{IndividualExpiryDate} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.idType === '2' &&
                        formData.documentCountryOfIssue !== null &&
                        formData.documentCountryOfIssue !== '' &&
                        formData.documentCountryOfIssue !== 'undefined' &&
                        formData.documentCountryOfIssue !== undefined ? (
                        <div className="review_b">
                          <label>Country of Issue </label>
                          <p>{getName(formData.documentCountryOfIssue)}  </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.idType === '3' &&
                        formData.documentCountryOfIssue !== null &&
                        formData.documentCountryOfIssue !== '' &&
                        formData.documentCountryOfIssue !== 'undefined' &&
                        formData.documentCountryOfIssue !== undefined ? (
                        <div className="review_b">
                          <label>Country of Issue </label>

                          <p>{getName(formData.documentCountryOfIssue)} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {
                        ((formData.idType === '1' || formData.idType === '') &&
                          formData.documentVersionNumber !== null &&
                          formData.documentVersionNumber !== '' &&
                          formData.documentVersionNumber !== 'undefined' &&
                          formData.documentVersionNumber !== undefined) ? (
                          <div className="review_b">
                            <label>Licence Version Number</label>
                            <p>{formData.documentVersionNumber} </p>
                          </div>
                        ) : (
                          ''
                        )}</>
                  }

                </>
              }
              {
                formData.isMyFarm === 'true' ? null : <>
                  {(formData.primaryBankAccountName !== '' &&
                    formData.primaryBankAccountName !== null &&
                    formData.primaryBankAccountName !== undefined &&
                    formData.primaryBankAccountName !== 'undefined') ||
                    (formData.primaryBankAccountNumber !== '' &&
                      formData.primaryBankAccountNumber !== null &&
                      formData.primaryBankAccountNumber !== 'undefined' &&
                      formData.primaryBankAccountNumber !== undefined) ? (
                    <>
                      <hr />
                      <h3>Bank Account Details:</h3>
                      {formData.primaryBankAccountName !== '' &&
                        formData.primaryBankAccountName !== null &&
                        formData.primaryBankAccountName !== undefined &&
                        formData.primaryBankAccountName !== 'undefined' ? (
                        <div className="review_b">
                          <label>Account Name</label>
                          <p>{formData.primaryBankAccountName} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.primaryBankAccountNumber !== '' &&
                        formData.primaryBankAccountNumber !== null &&
                        formData.primaryBankAccountNumber !== 'undefined' &&
                        formData.primaryBankAccountNumber !== undefined ? (
                        <div className="review_b">
                          <label>Account Number</label>
                          <p>{formData.primaryBankAccountNumber} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.bankSwiftCode !== '' &&
                        formData.bankSwiftCode !== null &&
                        formData.bankSwiftCode !== 'undefined' &&
                        formData.bankSwiftCode !== undefined ? (
                        <div className="review_b">
                          <label>Swift Code</label>
                          <p>{formData.bankSwiftCode} </p>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ) : (
                    ''
                  )}

                </>
              }
            </div>

          )
            :
            formData.investmentType === 'Joint' ? (
              <div className="Joint00">
                {/* <h1>Joint</h1> */}
                <h3>Investment Details:</h3>

                <div className="Investor-Certificate-joint">
                  <div className="review_b">
                    <label>Entity Type</label>
                    <p>{formData.investmentType} </p>
                  </div>
                  {formData.fundCode !== null &&
                    formData.fundCode !== '' &&
                    formData.fundCode !== 'undefined' &&
                    formData.fundCode !== undefined ? (
                    <div className="review_b">
                      <label>Investment Opportunity</label>
                      <p>{formData.fundCode} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.totalInvestment !== null &&
                    formData.totalInvestment !== '' &&
                    formData.totalInvestment !== 'undefined' &&
                    formData.totalInvestment !== undefined ? (
                    <div className="review_b">
                      <label>Investment Amount</label>
                      <p>{formData.totalInvestment} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {
                    formData.isMyFarm === 'true' ? (
                      <div className="review_b">
                        <label>Investment With MyFarm In The Past  2 Years</label>
                        <p className='textCapitalize'>Yes</p>
                      </div>
                    ) : (
                      <div className="review_b">
                        <label>Investment With MyFarm In The Past  2 Years</label>
                        <p className='textCapitalize'>No</p>
                      </div>
                    )}
                </div>
<hr/>
                <div className="Investor-Certificate-joint">
                  <h3>Personal Information: First Joint Holder</h3>

                  <div className="review_b">
                    <label>Full Name</label>
                    <p className='textCapitalize'>{formData.fullName}</p>
                  </div>

                  {formData.preferredName !== null &&
                    formData.preferredName !== '' &&
                    formData.preferredName !== undefined &&
                    formData.preferredName !== 'undefined' ? (
                    <div className="review_b">
                      <label>Preferred Name</label>
                      <p className='textCapitalize'>{formData.preferredName}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.userEmail !== null &&
                    formData.userEmail !== '' &&
                    formData.userEmail !== 'undefined' &&
                    formData.userEmail !== undefined ? (
                    <div className="review_b">
                      <label>Email</label>
                      <p className={"userEmail"}>{formData.userEmail}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.dateOfBirth !== null &&
                    formData.dateOfBirth !== '' &&
                    formData.dateOfBirth !== 'undefined' &&
                    formData.dateOfBirth !== undefined ? (
                    <div className="review_b">
                      <label>Date of Birth</label>
                      <p>{formData.dateOfBirth} </p>
                    </div>
                  ) : (
                    ''
                  )}
       {((formData.isPrimaryResdAddress !== null &&
                formData.isPrimaryResdAddress !== '' &&
                formData.isPrimaryResdAddress !== 'undefined' &&
                formData.isPrimaryResdAddress !== undefined)) ? (
                <div className="review_b">
                  <label>Residential Address </label>
                  <p>{ formData.isPrimaryResdAddress != null &&
                      formData.isPrimaryResdAddress != '' &&
                      formData.isPrimaryResdAddress != 'undefined' &&  formData.manuallyAddress != 'false'
                      ? formData.isPrimaryResdAddress
                      :
                      formData.manuallyAddress == 'false' ?
                      
                      
                      formData.street_number +
                      (formData.region != null && formData.region != '' && formData.region != undefined ? ', ' + formData.region : '') +
                      (formData.city != null && formData.city != '' ? ', ' + formData.city : '') +
                      (formData.postalCode != null && formData.postalCode != ''
                        ? ', ' + formData.postalCode
                        : '') +
                      (formData.country != null && formData.country != '' ? ', ' + formData.country : '')
                      : null} </p>
                </div>
              ) : (
                ''
              )}


            
{
                (formData.isPrimaryPostalAdress!== null &&
                  formData.isPrimaryPostalAdress!== '' &&
                  formData.isPrimaryPostalAdress !== 'undefined' &&
                  formData.isPrimaryPostalAdress !== undefined) || (formData.isPrimaryResdAddress !== null &&
                    formData.isPrimaryResdAddress !== '' &&
                    formData.isPrimaryResdAddress !== 'undefined' &&
                    formData.isPrimaryResdAddress !== undefined) ? (
                <div className="review_b">
                  <label> {formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal Address" : (formData.isPrimaryPostalAdress!== null &&
                  formData.isPrimaryPostalAdress!== '' &&
                  formData.isPrimaryPostalAdress !== 'undefined' &&
                  formData.isPrimaryPostalAdress !== undefined) ?
                  "Postal Address" : null} </label>
                  <p className='post'>{formData.isUserAddressSame === "true" || formData.isUserAddressSame === "undefined" || formData.isUserAddressSame === undefined ? "Postal address is the same as home address" : formData.isPrimaryPostalAdress} </p>
                </div>
              ) : (
                ''
              )}

                  {
                    formData.priContactType !== '' &&
                      formData.priContactType !== undefined &&
                      formData.priContactType !== 'undefined' ? (
                      <div className="review_b">
                        <label> Primary Contact Type</label>
                        <p>{formData.priContactType !== null ? formData.priContactType : "Mobile"} </p>
                      </div>
                    ) : (
                      ''
                    )}
                  {formData.priContactNumber !== null &&
                    formData.priContactNumber !== '' &&
                    formData.priContactNumber !== 'undefined' &&
                    formData.priContactNumber !== undefined ? (
                    <div className="review_b">
                      <label>Primary Contact Number</label>
                      <p>{"+"}{formData.priContactCountryCode != null && formData.priContactCountryCode !== "" ? formData.priContactCountryCode : "64"}{" "}{formData.priContactNumber} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {
                    (formData.secContactNumber !== '' && formData.secContactNumber !== null &&
                      formData.secContactNumber !== undefined &&
                      formData.secContactNumber !== 'undefined' ? (
                      <div className="review_b">
                        <label> Secondary Contact Type</label>
                        <p>{formData.secContactType === null || formData.secContactType === ""
                          || formData.secContactType === "undefined" || formData.secContactType === undefined ? "Home" : formData.secContactType} </p>
                      </div>
                    ) : (
                      ''
                    ))}
                  {
                    (formData.secContactNumber !== null &&
                      formData.secContactNumber !== '' &&
                      formData.secContactNumber !== 'undefined' &&
                      formData.secContactNumber !== undefined ? (
                      <div className="review_b">
                        <label>Secondary Contact Number</label>
                        <p>{"+"}{formData.secContactCountryCode != null ? formData.secContactCountryCode : "64"}{" "}{formData.secContactNumber} </p>
                      </div>
                    ) : (
                      ''
                    ))}

                  <>
                    {
                      (formData.intendToInvest !== null &&
                        formData.intendToInvest !== 'Select' &&
                        formData.intendToInvest !== 'undefined' &&
                        formData.intendToInvest !== undefined ? (
                        <div className="review_b">
                          <label>Intend To Invest</label>
                          <p>{formData.intendToInvest} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.intendToInvest == 'Other'
                        ? (
                          <div className="review_b">
                            <label>Other Intend To Invest</label>
                            <p>{formData.otherIntendToInvest} </p>
                          </div>
                        ) : (
                          ''
                        ))
                    }
                    {
                      (formData.totalIintend !== null &&
                        formData.totalIintend !== 'Select' &&
                        formData.totalIintend !== 'undefined' &&
                        formData.totalIintend !== undefined ? (
                        <div className="review_b">
                          <label>Total Intend To Invest</label>
                          <p>{formData.totalIintend} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.chooseToInvest !== null &&
                        formData.chooseToInvest !== 'Select' &&
                        formData.chooseToInvest !== 'undefined' &&
                        formData.chooseToInvest !== undefined ? (
                        <div className="review_b">
                          <label>Choose To Invest</label>
                          <p>{formData.chooseToInvest} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.chooseToInvest == 'Other'
                        ? (
                          <div className="review_b">
                            <label>Other Choose To Invest</label>
                            <p>{formData.otherChooseToInvest} </p>
                          </div>
                        ) : (
                          ''
                        ))
                    }
                    {
                      (formData.occupation !== null &&
                        formData.occupation !== '' &&
                        formData.occupation !== 'undefined' &&
                        formData.occupation !== undefined ? (
                        <div className="review_b">
                          <label>Your Occupation</label>
                          <p>{formData.occupation} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.sourceOfFund !== null &&
                        formData.sourceOfFund !== '' &&
                        formData.sourceOfFund !== 'undefined' &&
                        formData.sourceOfFund !== undefined ? (
                        <div className="review_b">
                          <label>Source of Fund</label>
                          <p>{formData.sourceOfFund} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                  </>

                  {
                    formData.isMyFarm == "true" ? null :
                      <>

                        {formData.placeOfBirth !== null &&
                          formData.placeOfBirth !== '' &&
                          formData.placeOfBirth !== 'undefined' &&
                          formData.placeOfBirth !== undefined ? (
                          <div className="review_b">
                            <label>Place of Birth </label>
                            <p>{formData.placeOfBirth} </p>
                          </div>
                        ) : (
                          ''
                        )}
                        {formData.countryOfBirth !== null &&
                          formData.countryOfBirth !== '' &&
                          formData.countryOfBirth !== 'undefined' &&
                          formData.countryOfBirth !== undefined ? (
                          <div className="review_b">
                            <label>Country of Birth</label>

                            <p>{getName(formData.countryOfBirth)} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.residingCountry !== null &&
                          formData.residingCountry !== '' &&
                          formData.residingCountry !== 'undefined' &&
                          formData.residingCountry !== undefined ? (
                          <div className="review_b">
                            <label>Country of Residence</label>

                            <p>{getName(formData.residingCountry)} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.userIrdNumber !== null &&
                          formData.userIrdNumber !== '' &&
                          formData.userIrdNumber !== 'undefined' &&
                          formData.userIrdNumber !== undefined ? (
                          <div className="review_b">
                            <label>IRD Number</label>
                            <p>{formData.userIrdNumber} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.rwt !== null && formData.rwt !== "Select" &&
                          formData.rwt !== '' &&
                          formData.rwt !== 'undefined' &&
                          formData.rwt !== undefined ? (
                          <div className="review_b">
                            <label> Tax Type</label>
                            <p>{formData.rwt} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.checkboxx !== null &&
                          formData.checkboxx !== '' &&
                          formData.checkboxx !== 'undefined' &&
                          formData.checkboxx === true && formData.rwt === "Non Resident Withholding Tax" ? (
                          <div className="review_b">
                            <label>Resident Withholding Tax Exemption</label>
                            <p>{formData.checkboxx === true ? "Yes" : "No"}</p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.rwtValue !== null &&
                          formData.rwtValue !== '' &&
                          formData.rwtValue !== 'undefined' &&
                          formData.rwtValue !== undefined && formData.rwt === "Resident Withholding Tax" ? (
                          <div className="review_b">
                            <label> Resident Withholding Tax Rate</label>
                            <p>{formData.rwtValue} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.tinResidential !== null &&
                          formData.tinResidential !== '' &&
                          formData.tinResidential !== 'undefined' &&
                          formData.tinResidential !== undefined ? (
                          <div className="review_b">
                            <label>Country of tax residence</label>

                            <p>{getName(formData.tinResidential)} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.tinNumber !== null &&
                          formData.tinNumber !== '' &&
                          formData.tinNumber !== 'undefined' &&
                          formData.tinNumber !== undefined ? (
                          <div className="review_b">
                            <label>Tax Identification Number (TIN)</label>
                            <p>{formData.tinNumber} </p>
                          </div>
                        ) : (
                          ''
                        )}

                        {formData.tinreason !== null &&
                          formData.tinreason !== '' &&
                          formData.tinreason !== 'undefined' &&
                          formData.tinreason !== undefined ? (
                          <div className="review_b">
                            <label>Reason if TIN is not available</label>
                            <p>{formData.tinreason} </p>
                          </div>
                        ) : (
                          ''
                        )}


                      </>
                  }
                  <hr />
                </div>

                <div className="Investor-Certificate-joint">
                  <h3>Personal Information: Second Joint Holder</h3>
                  {formData.secondholder !== null &&
                    formData.secondholder !== '' &&
                    formData.secondholder !== 'undefined' &&
                    formData.secondholder !== undefined ? (
                    <div className="review_b">
                      <label>Full Name</label>
                      <p className='textCapitalize'> {formData.secondholder}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.preferredName2 !== null &&
                    formData.preferredName2 !== '' &&
                    formData.preferredName2 !== undefined &&
                    formData.preferredName2 !== 'undefined' ? (
                    <div className="review_b">
                      <label>Preferred Name</label>
                      <p className='textCapitalize'>{formData.preferredName2}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {formData.email1 !== null &&
                    formData.email1 !== '' &&
                    formData.email1 !== 'undefined' &&
                    formData.email1 !== undefined ? (
                    <div className="review_b">
                      <label>Email</label>
                      <p className={"userEmail"}>{formData.email1}</p>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* {formData.preferredName2 !== null &&
                formData.preferredName2 !== '' &&
                formData.preferredName2 !== undefined &&
                formData.preferredName2 !== 'undefined' ? (
                <div className="review_b">
                  <label>Preferred Name</label>
                  <p>{formData.preferredName2} </p>
                </div>
              ) : (
                ''
              )} */}

                  {formData.dateOfBirth2 !== null &&
                    formData.dateOfBirth2 !== '' &&
                    formData.dateOfBirth2 !== 'undefined' &&
                    formData.dateOfBirth2 !== undefined ? (
                    <div className="review_b">
                      <label>Date of Birth</label>
                      <p>{formData.dateOfBirth2} </p>
                    </div>
                  ) : (
                    ''
                  )}


                  {((formData.isSecondryResdAddress !== null &&
                    formData.isSecondryResdAddress !== '' &&
                    formData.isSecondryResdAddress !== 'undefined' &&
                    formData.isSecondryResdAddress !== undefined) ) ? (
                    <div className="review_b">
                      <label>Residential Address</label>
                      <p>{
                         formData.isSecondryResdAddress != null &&
                         formData.isSecondryResdAddress != 'undefined' &&
                         formData.isSecondryResdAddress != '' && formData.manuallyAddress2 != "false"

                          ?  formData.isSecondryResdAddress : formData.manuallyAddress2 == "false" ?
                          
                          formData.street_number1 +
                          (formData.region1 != '' && formData.region1 != null && formData.region1 != undefined ? ', ' + formData.region1 : '') +
                          (formData.city1 != '' ? ', ' + formData.city1 : '') +
                          (formData.postalCode1 != null ? ', ' + formData.postalCode1 : '') +
                          (formData.country1 != '' ? ', ' + formData.country1 : '')
                          : null} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* {( ( formData.isSecondryPostalAdre != null &&  formData.isSecondryPostalAdre != "" &&  formData.isSecondryPostalAdre != "undefined" &&  formData.isSecondryPostalAdre != undefined)) 

        ? (
                    <div className="review_b">
                      { formData.isSecondryPostalAdre == "" ? null : <label>Postal Address</label>}
                      <p className='post'>{formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 == "" || formData.isUserAddressSame2 == undefined || formData.isUserAddressSame2 == null ? "Postal address is the same as home address" :  formData.isSecondryPostalAdre} </p>
                    </div>
                  ) : (
                    ''
                  )} */}
                
                  {
                (formData.isSecondryPostalAdre!== null &&
                  formData.isSecondryPostalAdre!== '' &&
                  formData.isSecondryPostalAdre !== 'undefined' &&
                  formData.isSecondryPostalAdre !== undefined) || (formData.isPrimaryResdAddress !== null &&
                    formData.isSecondryResdAddress !== '' &&
                    formData.isSecondryResdAddress !== 'undefined' &&
                    formData.isSecondryResdAddress !== undefined) ? (
                <div className="review_b">
                  <label> {formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 === undefined ? "Postal Address" : (formData.isSecondryPostalAdre !== null &&
                  formData.isSecondryPostalAdre!== '' &&
                  formData.isSecondryPostalAdre !== 'undefined' &&
                  formData.isSecondryPostalAdre !== undefined) ?
                  "Postal Address" : null} </label>
                  <p className='post'>{formData.isUserAddressSame2 === "true" || formData.isUserAddressSame2 === "undefined" || formData.isUserAddressSame2 === undefined ? "Postal address is the same as home address" : formData.isSecondryPostalAdre} </p>
                </div>
              ) : (
                ''
              )}


                  {formData.priContactType2 !== null &&
                    formData.priContactType2 !== '' ? (
                    <div className="review_b">
                      <label> Primary Contact Type</label>
                      <p>{formData.priContactType2 !== null && formData.priContactType2 !== ""
                        && formData.priContactType2 !== "undefined" && formData.priContactType2 !== undefined ? formData.priContactType2 : "Mobile"} </p>
                    </div>
                  ) : (
                    ''
                  )}
                  {formData.priContactNumber2 !== null &&
                    formData.priContactNumber2 !== '' &&
                    formData.priContactNumber2 !== 'undefined' &&
                    formData.priContactNumber2 !== undefined ? (
                    <div className="review_b">
                      <label>Primary Contact Number</label>
                      <p>{"+"}{formData.priContactCountryCode2 != null && formData.priContactCountryCode2 !== "" ? formData.priContactCountryCode2 : "64"}{" "}{formData.priContactNumber2} </p>
                    </div>
                  ) : (
                    ''
                  )}

                  {/* {formData.isAlternateContact1== true|| formData.isAlternateContact1 == "undefined" || formData.isAlternateContact1 == null?"":(  formData.secContactType2 !== null &&
                formData.secContactType2 !== ''   ? (
                <div className="review_b">
                  <label> Secondary Contact Type</label>
                  <p>{formData.secContactType2 != null?formData.secContactType2:"Home"} </p>
                </div>
              ) : (
                ''
              ))} */}
                  {(formData.secContactNumber2 !== null &&
                    formData.secContactNumber2 !== '' &&
                    formData.secContactNumber2 !== 'undefined' &&
                    formData.secContactNumber2 !== undefined ? (
                    <div className="review_b">
                      <label> Secondary Contact Type</label>
                      <p>{formData.secContactType2 != null && formData.secContactType2 != ""
                        && formData.secContactType2 != "undefined" && formData.secContactType2 != undefined ? formData.secContactType2 : "Home"} </p>
                    </div>
                  ) : (
                    ''
                  ))
                  }



                  {(formData.secContactNumber2 !== null &&
                    formData.secContactNumber2 !== '' &&
                    formData.secContactNumber2 !== 'undefined' &&
                    formData.secContactNumber2 !== undefined ? (
                    <div className="review_b">
                      <label>Secondary Contact Number</label>
                      <p>{"+"}{formData.secContactCountryCode2 != null && formData.secContactCountryCode2 != "" ? formData.secContactCountryCode2 : "64"}{" "}{formData.secContactNumber2} </p>
                    </div>
                  ) : (
                    ''
                  ))
                  }


                  <>
                    {
                      (formData.intendToInvest3 !== null &&
                        formData.intendToInvest3 !== 'Select' &&
                        formData.intendToInvest3 !== 'undefined' &&
                        formData.intendToInvest3 !== undefined ? (
                        <div className="review_b">
                          <label>Intend To Invest</label>
                          <p>{formData.intendToInvest3} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.intendToInvest3 == 'Other'
                        ? (
                          <div className="review_b">
                            <label>Other Intend To Invest</label>
                            <p>{formData.otherIntendToInvest3} </p>
                          </div>
                        ) : (
                          ''
                        ))
                    }
                    {
                      (formData.totalIintend3 !== null &&
                        formData.totalIintend3 !== 'Select' &&
                        formData.totalIintend3 !== 'undefined' &&
                        formData.totalIintend3 !== undefined ? (
                        <div className="review_b">
                          <label>Total Intend To Invest</label>
                          <p>{formData.totalIintend3} </p>
                        </div>
                      ) : (
                        ''
                      ))}

                    {
                      (formData.totalIintend3 == 'Other'
                        ? (
                          <div className="review_b">
                            <label>Other Choose To Invest</label>
                            <p>{formData.otherChooseToInvest3} </p>
                          </div>
                        ) : (
                          ''
                        ))
                    }
                    {
                      (formData.chooseToInvest3 !== null &&
                        formData.chooseToInvest3 !== 'Select' &&
                        formData.chooseToInvest3 !== 'undefined' &&
                        formData.chooseToInvest3 !== undefined ? (
                        <div className="review_b">
                          <label>Choose To Invest</label>
                          <p>{formData.chooseToInvest3} </p>
                        </div>
                      ) : (
                        ''
                      ))}

                    {
                      (formData.chooseToInvest3 == 'Other'
                        ? (
                          <div className="review_b">
                            <label>Other Choose To Invest</label>
                            <p>{formData.otherChooseToInvest3} </p>
                          </div>
                        ) : (
                          ''
                        ))
                    }

                    {
                      (formData.occupation3 !== null &&
                        formData.occupation3 !== '' &&
                        formData.occupation3 !== 'undefined' &&
                        formData.occupation3 !== undefined ? (
                        <div className="review_b">
                          <label>Your Occupation</label>
                          <p>{formData.occupation3} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                    {
                      (formData.sourceOfFund3 !== null &&
                        formData.sourceOfFund3 !== '' &&
                        formData.sourceOfFund3 !== 'undefined' &&
                        formData.sourceOfFund3 !== undefined ? (
                        <div className="review_b">
                          <label>Source of Fund</label>
                          <p>{formData.sourceOfFund3} </p>
                        </div>
                      ) : (
                        ''
                      ))}
                  </>
                  {
                    formData.isMyFarm == "true" ? null : <>

                      {formData.placeOfBirth2 !== null &&
                        formData.placeOfBirth2 !== '' &&
                        formData.placeOfBirth2 !== 'undefined' &&
                        formData.placeOfBirth2 !== undefined ? (
                        <div className="review_b">
                          <label>Place of Birth </label>
                          <p>{formData.placeOfBirth2} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.countryOfBirth2 !== null &&
                        formData.countryOfBirth2 !== '' &&
                        formData.countryOfBirth2 !== 'undefined' &&
                        formData.countryOfBirth2 !== undefined ? (
                        <div className="review_b">
                          <label>Country of Birth</label>

                          <p>{getName(formData.countryOfBirth2)} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {formData.residingCountry2 !== null &&
                        formData.residingCountry2 !== '' &&
                        formData.residingCountry2 !== 'undefined' &&
                        formData.residingCountry2 !== undefined ? (
                        <div className="review_b">
                          <label>Country of Residence</label>

                          <p>{getName(formData.residingCountry2)} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.userIrdNumber2 !== null &&
                        formData.userIrdNumber2 !== '' &&
                        formData.userIrdNumber2 !== 'undefined' &&
                        formData.userIrdNumber2 !== undefined ? (
                        <div className="review_b">
                          <label>IRD Number</label>
                          <p>{formData.userIrdNumber2} </p>
                        </div>
                      ) : (
                        ''
                      )}
                      <>

                        {formData.rwt2 !== null && formData.rwt2 !== "Select" &&
                          formData.rwt2 !== '' &&
                          formData.rwt2 !== 'undefined' &&
                          formData.rwt2 !== undefined ? (
                          <div className="review_b">
                            <label> Tax Type</label>
                            <p>{formData.rwt2} </p>

                          </div>
                        ) : (
                          ''
                        )}

                      </>

                      {formData.checkboxxjoint2 !== null &&
                        formData.checkboxxjoint2 !== '' &&
                        formData.checkboxxjoint2 !== 'undefined' &&
                        formData.checkboxxjoint2 === true && formData.rwt2 === "Non Resident Withholding Tax" ? (
                        <div className="review_b">
                          <label>Resident Withholding Tax Exemption</label>
                          <p>{formData.checkboxxjoint2 === true ? "Yes" : "No"}</p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.rwtValue2 !== null &&
                        formData.rwtValue2 !== '' &&
                        formData.rwtValue2 !== 'undefined' &&
                        formData.rwtValue2 !== undefined && formData.rwt2 === "Resident Withholding Tax" ? (
                        <div className="review_b">
                          <label> Resident Withholding Tax Rate</label>
                          <p>{formData.rwtValue2} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.tinResidential2 !== null &&
                        formData.tinResidential2 !== '' &&
                        formData.tinResidential2 !== 'undefined' &&
                        formData.tinResidential2 !== undefined ? (
                        <div className="review_b">
                          <label>Country of tax residence</label>

                          <p>{getName(formData.tinResidential2)} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.tinNumber2 !== null &&
                        formData.tinNumber2 !== '' &&
                        formData.tinNumber2 !== 'undefined' &&
                        formData.tinNumber2 !== undefined ? (
                        <div className="review_b">
                          <label>Tax Identification Number (TIN)</label>
                          <p>{formData.tinNumber2} </p>
                        </div>
                      ) : (
                        ''
                      )}

                      {formData.tinReason2 !== null &&
                        formData.tinReason2 !== '' &&
                        formData.tinReason2 !== 'undefined' &&
                        formData.tinReason2 !== undefined ? (
                        <div className="review_b">
                          <label>Reason if TIN is not available</label>
                          <p>{formData.tinReason2} </p>
                        </div>
                      ) : (
                        ''
                      )} </>
                  }
                  <hr />
                </div>

                {
                  formData.isMyFarm == "true" ? null :
                    <>
                      <div className="Identity-joint">
                        {(formData.isMyFarm === true || formData.isMyFarm === 'true') ? null : formData.biometric === "Biometric" ? "" : <>
                          <h3>Identity Verification: First Joint Holder</h3>
                          {(formData.idType === '' || formData.idType === '1' || formData.idType === '2' || formData.idType === '3') ? (
                            <div className="review_b">
                              <label>Identity Verification Type </label>
                              <p>{formData.idType === '1' ||
                                formData.idType === '' ||
                                formData.idType === 'undefined' ||
                                formData.idType === undefined ||
                                formData.idType === null
                                ? 'NZ Driver Licence'
                                : formData.idType === '2'
                                  ? 'NZ Passport'
                                  : formData.idType === '3' ? "Other" : null
                                //  formData.otherDocumentName
                              }</p>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="review_b">
                            <label>First Name</label>
                            <p>{formData.firstName} </p>
                          </div>
                          {formData.middleName !== null &&
                            formData.middleName !== '' &&
                            formData.middleName !== 'undefined' &&
                            formData.middleName !== undefined ? (
                            <div className="review_b">
                              <label>Middle Name</label>
                              <p>{formData.middleName} </p>
                            </div>
                          ) : (
                            ''
                          )}
                          {formData.lastName !== null &&
                            formData.lastName !== '' &&
                            formData.lastName !== 'undefined' &&
                            formData.lastName !== undefined ? (
                            <div className="review_b">
                              <label>Last Name</label>
                              <p>{formData.lastName} </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </>}


                        {formData.biometric === "Biometric" ? "" :
                          <>
                            {(formData.idType === '' || formData.idType === '1') &&
                              formData.documentNumber !== null &&
                              formData.documentNumber !== '' &&
                              formData.documentNumber !== undefined &&
                              formData.documentNumber !== 'undefined' ? (
                              <div className="review_b">
                                <label>Licence Number</label>
                                <p>{formData.documentNumber} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType === '2' &&
                              formData.documentNumber !== null &&
                              formData.documentNumber !== '' &&
                              formData.documentNumber !== 'undefined' &&
                              formData.documentNumber !== undefined ? (
                              <div className="review_b">
                                <label>Passport Number</label>
                                <p>{formData.documentNumber} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType === '3' &&
                              formData.documentNumber !== null &&
                              formData.documentNumber !== '' &&
                              formData.documentNumber !== 'undefined' &&
                              formData.documentNumber !== undefined ? (
                              <div className="review_b">
                                <label>Document Number</label>
                                <p>{formData.documentNumber} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType === '3' &&
                              formData.otherDocumentName !== null &&
                              formData.otherDocumentName !== '' &&
                              formData.otherDocumentName !== 'undefined' &&
                              formData.otherDocumentName !== undefined ? (
                              <div className="review_b">
                                <label>Document type</label>
                                <p>{formData.otherDocumentName} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {
                              (formData.idType === '1' &&
                                IndividualExpiryDate !== null &&
                                IndividualExpiryDate !== '' &&
                                IndividualExpiryDate !== 'undefined' &&
                                IndividualExpiryDate !== undefined) ? (
                                <div className="review_b">
                                  <label>Licence Expiry Date</label>
                                  <p>{IndividualExpiryDate} </p>
                                </div>
                              ) : (
                                ''
                              )}

                            {formData.idType === '2' &&
                              IndividualExpiryDate !== null &&
                              IndividualExpiryDate !== '' &&
                              IndividualExpiryDate !== 'undefined' &&
                              IndividualExpiryDate !== undefined ? (
                              <div className="review_b">
                                <label>Passport Expiry Date</label>
                                <p>{IndividualExpiryDate} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType === '3' &&
                              IndividualExpiryDate !== null &&
                              IndividualExpiryDate !== '' &&
                              IndividualExpiryDate !== 'undefined' &&
                              IndividualExpiryDate !== undefined ? (
                              <div className="review_b">
                                <label>Document Expiry Date</label>
                                <p>{IndividualExpiryDate} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType === '2' &&
                              formData.documentCountryOfIssue !== null &&
                              formData.documentCountryOfIssue !== '' &&
                              formData.documentCountryOfIssue !== 'undefined' &&
                              formData.documentCountryOfIssue !== undefined ? (
                              <div className="review_b">
                                <label>Country of Issue </label>

                                <p>{getName(formData.documentCountryOfIssue)} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType === '3' &&
                              formData.documentCountryOfIssue !== null &&
                              formData.documentCountryOfIssue !== '' &&
                              formData.documentCountryOfIssue !== 'undefined' &&
                              formData.documentCountryOfIssue !== undefined ? (
                              <div className="review_b">
                                <label>Country of Issue </label>

                                <p>{getName(formData.documentCountryOfIssue)} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {
                              ((formData.idType === '1' || formData.idType === '') &&
                                formData.documentVersionNumber !== null &&
                                formData.documentVersionNumber !== '' &&
                                formData.documentVersionNumber !== 'undefined' &&
                                formData.documentVersionNumber !== undefined) ? (
                                <div className="review_b">
                                  <label>Licence Version Number</label>
                                  <p>{formData.documentVersionNumber} </p>
                                </div>
                              ) : (
                                ''
                              )}

                          </>}


                        <hr />
                      </div>

                      <div className="Identity-joint ">
                        {formData.biometric2 === "Biometric" ? "" :
                          <>
                            <h3>Identity Verification: Second Joint Holder</h3>

                            <div className="review_b">
                              <label>Identity Verification Type </label>
                              <p>{formData.idType2 === '1' ||
                                formData.idType2 === '' ||
                                formData.idType2 === 'undefined' ||
                                formData.idType2 === undefined ||
                                formData.idType2 === null
                                ? 'NZ Driver Licence'
                                : formData.idType2 === '2'
                                  ? 'NZ Passport'
                                  : formData.idType2 === '3' ? "Other" : null
                                // formData.otherDocumentName2
                              }</p>
                            </div>
                            {formData.firstName12 !== null &&
                              formData.firstName12 !== '' &&
                              formData.firstName12 !== 'undefined' &&
                              formData.firstName12 !== undefined ? (
                              <div className="review_b">
                                <label>First Name</label>
                                <p>{formData.firstName12} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.middleName12 != null && formData.middleName12 != "" && formData.middleName12 != "undefined" && formData.middleName12 != undefined ? (
                              <div className="review_b">
                                <label>Middle Name</label>
                                <p>{formData.middleName12} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.lastName12 !== null &&
                              formData.lastName12 !== '' &&
                              formData.lastName12 !== 'undefined' &&
                              formData.lastName12 !== undefined ? (
                              <div className="review_b">
                                <label>Last Name</label>
                                <p>{formData.lastName12} </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </>}


                        {formData.biometric2 === "Biometric" ? "" :
                          <>
                            {(formData.idType2 === '' || formData.idType2 === '1') &&
                              formData.documentNumber2 !== null &&
                              formData.documentNumber2 !== '' &&
                              formData.documentNumber2 !== undefined &&
                              formData.documentNumber2 !== 'undefined' ? (
                              <div className="review_b">
                                <label>Licence Number</label>
                                <p>{formData.documentNumber2} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType2 === '2' &&
                              formData.documentNumber2 !== null &&
                              formData.documentNumber2 !== '' &&
                              formData.documentNumber2 !== undefined &&
                              formData.documentNumber2 !== 'undefined' ? (
                              <div className="review_b">
                                <label>Passport Number</label>
                                <p>{formData.documentNumber2} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType2 === '3' &&
                              formData.documentNumber2 !== null &&
                              formData.documentNumber2 !== '' &&
                              formData.documentNumber2 !== undefined &&
                              formData.documentNumber2 !== 'undefined' ? (
                              <div className="review_b">
                                <label>Document Number</label>
                                <p>{formData.documentNumber2} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType2 === '3' &&
                              formData.otherDocumentName2 !== null &&
                              formData.otherDocumentName2 !== '' &&
                              formData.otherDocumentName2 !== 'undefined' &&
                              formData.otherDocumentName2 !== undefined ? (
                              <div className="review_b">
                                <label>Document Type</label>
                                <p>{formData.otherDocumentName2} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {(formData.idType2 === '' || formData.idType2 === '1')
                              &&
                              JointExpiryDate !== null &&
                              JointExpiryDate !== '' &&
                              JointExpiryDate !== undefined &&
                              JointExpiryDate !== 'undefined' ? (
                              <div className="review_b">
                                <label>Licence Expiry Date</label>
                                <p>{JointExpiryDate} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType2 === '2' &&
                              JointExpiryDate !== null &&
                              JointExpiryDate !== '' &&
                              JointExpiryDate !== undefined &&
                              JointExpiryDate !== 'undefined' ? (
                              <div className="review_b">
                                <label>Passport Expiry Date</label>
                                <p>{JointExpiryDate} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType2 === '3' &&
                              JointExpiryDate !== null &&
                              JointExpiryDate !== '' &&
                              JointExpiryDate !== undefined &&
                              JointExpiryDate !== 'undefined' ? (
                              <div className="review_b">
                                <label>Document Expiry Date</label>
                                <p>{JointExpiryDate} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {formData.idType2 === '2'
                              &&
                              formData.documentCountryOfIssue2 !== null &&
                              formData.documentCountryOfIssue2 !== '' &&
                              formData.documentCountryOfIssue2 !== undefined &&
                              formData.documentCountryOfIssue2 !== 'undefined' ? (
                              <div className="review_b">
                                <label>Country of Issue </label>

                                <p>{getName(formData.documentCountryOfIssue2)} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.idType2 === '3' &&
                              formData.documentCountryOfIssue2 !== null &&
                              formData.documentCountryOfIssue2 !== '' &&
                              formData.documentCountryOfIssue2 !== 'undefined' &&
                              formData.documentCountryOfIssue2 !== undefined ? (
                              <div className="review_b">
                                <label>Country of Issue </label>
                                <p>{getName(formData.documentCountryOfIssue2)} </p>
                              </div>
                            ) : (
                              ''
                            )}

                            {(formData.idType2 === '' || formData.idType2 === '1')
                              &&
                              formData.documentVersionNumber2 !== null &&
                              formData.documentVersionNumber2 !== '' &&
                              formData.documentVersionNumber2 !== 'undefined' &&
                              formData.documentVersionNumber2 !== undefined ? (
                              <div className="review_b">
                                <label>Licence Version Number</label>
                                <p>{formData.documentVersionNumber2} </p>
                              </div>
                            ) : (
                              ''
                            )}

                          </>
                        }
                      </div>
                    </>
                }

                {
                  formData.isMyFarm == "true" ? null :
                    <>
                      <div className="BankAccount-Detail">
                        {(formData.primaryBankAccountName !== '' &&
                          formData.primaryBankAccountName !== null &&
                          formData.primaryBankAccountName !== undefined &&
                          formData.primaryBankAccountName !== 'undefined') ||
                          (formData.primaryBankAccountNumber !== '' &&
                            formData.primaryBankAccountNumber !== null &&
                            formData.primaryBankAccountNumber !== 'undefined' &&
                            formData.primaryBankAccountNumber !== undefined) ? (
                          <>
                             <hr />
                            <h3>Bank Account Details: First Joint Holder</h3>
                            {formData.primaryBankAccountName !== '' &&
                              formData.primaryBankAccountName !== null &&
                              formData.primaryBankAccountName !== undefined &&
                              formData.primaryBankAccountName !== 'undefined' ? (
                              <div className="review_b">
                                <label>Account Name</label>
                                <p>{formData.primaryBankAccountName} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.primaryBankAccountNumber !== '' &&
                              formData.primaryBankAccountNumber !== null &&
                              formData.primaryBankAccountNumber !== 'undefined' &&
                              formData.primaryBankAccountNumber !== undefined ? (
                              <div className="review_b">
                                <label>Account Number</label>
                                <p>{formData.primaryBankAccountNumber} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.bankSwiftCode !== '' &&
                              formData.bankSwiftCode !== null &&
                              formData.bankSwiftCode !== 'undefined' &&
                              formData.bankSwiftCode !== undefined ? (
                              <div className="review_b">
                                <label>Swift Code</label>
                                <p>{formData.bankSwiftCode} </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}

                      </div>
                      <div className="BankAccount-Detail">
                        {(formData.primaryBankAccountName2 !== '' &&
                          formData.primaryBankAccountName2 !== null && formData.isNZBank12 !== 'true' &&
                          formData.primaryBankAccountName2 !== 'undefined' &&
                          formData.primaryBankAccountName2 !== undefined) ||
                          (formData.primaryBankAccountNumber2 !== '' &&
                            formData.primaryBankAccountNumber2 !== null &&
                            formData.primaryBankAccountNumber2 !== 'undefined' &&
                            formData.primaryBankAccountNumber2 !== undefined) ? (
                          <>
                            {' '}
                            <h3>Bank Account Details: Second Joint Holder</h3>
                            {formData.primaryBankAccountName2 !== '' &&
                              formData.primaryBankAccountName2 !== null &&
                              formData.primaryBankAccountName2 !== undefined && formData.isNZBank12 !== 'true' &&
                              formData.primaryBankAccountName2 !== 'undefined' ? (
                              <div className="review_b">
                                <label>Account Name</label>
                                <p>{formData.primaryBankAccountName2} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.primaryBankAccountNumber2 !== '' && formData.isNZBank12 !== 'true' &&
                              formData.primaryBankAccountNumber2 !== null &&
                              formData.primaryBankAccountNumber2 !== 'undefined' &&
                              formData.primaryBankAccountNumber2 !== undefined ? (
                              <div className="review_b">
                                <label>Account Number</label>
                                <p>{formData.primaryBankAccountNumber2} </p>
                              </div>
                            ) : (
                              ''
                            )}
                            {formData.bankSwiftCode2 !== '' &&
                              formData.bankSwiftCode2 !== null && formData.isNZBank12 !== 'true' &&
                              formData.bankSwiftCode2 !== 'undefined' &&
                              formData.bankSwiftCode2 !== undefined ? (
                              <div className="review_b">
                                <label>Swift Code</label>
                                <p>{formData.bankSwiftCode2} </p>
                              </div>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          ''
                        )}

                      </div>
                    </>
                }

              </div>
            ) : (
              ''
            )}


          <div className="fotter-btn appli_rew">
            <button className="preview" onClick={previousHandler}>
              <img src={leftarrow} className="leftarrow" />
              Previous
            </button>
            <button type="submit" className="next setwidht" onClick={handleClick}>
              Submit Application
              <img src={rightarrow} className="rightarrow" />{' '}
            </button>
          </div>
        </div>
      </div>
    </>

  );
};

export default Twentyone11;
