import React from "react";

const states = [
  ["Select", "Select"],
  ["Individual", "Individual"],
  ["Joint", "Joint"],
  // ["Company", "Company"],

];

const Entity = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others}>
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default Entity;
