import React from "react";

const states = [
  ["Select", "Select"],
  ["EastPack Notes", "EastPack Notes"]
];

const Fundss = ({ label, ...others }) => (
  <>
    <label>{label}</label>
    <select {...others} >
      {states.map(([value, name]) => (
        <option value={value}>{name}</option>
      ))}
    </select>
  </>
);

export default Fundss;
