/*eslint eqeqeq: "off"*/
import React, { useState, useEffect } from 'react';
import ItemForm from '../ItemForm';
// import attch from '../attch.png';
import leftarrow from '../leftarrow.png';
import rightarrow from '../rightarrow.png';
// import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import InputMask from 'react-input-mask';
import ReactFlagsSelect from 'react-flags-select';
import logo from '../logo.png';
import $, { now } from 'jquery';
import info from '../info.png';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import swal from 'sweetalert';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { getCode } from 'country-list';
import { validateDateInput, IdExpDateValidator, expiryDateError } from '../components/Dateformator';
import MenuSidebar from '../Individual/MenuSidebar';
import { multisavedata } from '../../auth/Configuration';
import Swal from 'sweetalert2';
import { infodetls } from '../Individual/SaveInfo';
import UploadImage from '../Individual/UploadImage';


// const { useState } = React; 

const Four = ({ setForm, formData, navigation, saveAndExist, idinfo, func }) => {
  const {
    fullName,
    secondholder,
    idType2,
    documentVersionNumber2,
    documentCountryOfIssue2,
    otherDocumentName2,
    documentNumber2
  } = formData;
  const {next } = navigation;

  const [namefull, setNamefull] = useState(secondholder ? secondholder.trim().split(' ') : '');
  let text2 = ['world!', 'ny', 'jjij'];
  // let result = text2[-1];

  const [firstName1, setFirstName1] = useState(
    secondholder != null && secondholder != '' && secondholder != 'undefined' ? namefull[0] : ''
  );

  const [lastName1, setLastName1] = useState(
    namefull.length > 1 ? namefull[namefull.length - 1] : ''
  );

  const [middleName1, setMiddleName1] = useState(
    namefull && namefull.length > 2
      ? namefull
        .slice(1, namefull.length - 1)
        .join()
        .replace(/,/g, ' ')
      : ''
  );

  const [namefull1, setNamefull1] = useState(
    formData.secondholder ? formData.secondholder.trim().split(' ') : ''
  );

  if (
    formData.secondholder != null &&
    formData.secondholder != '' &&
    formData.secondholder != 'undefined'
  ) {
    formData.firstName12 = namefull1[0];
    if (namefull1.length > 1) {
      formData.lastName12 = namefull1[namefull1.length - 1];
    }

    if (namefull1 && namefull1.length > 2) {
      formData.middleName12 = namefull1
        .slice(1, namefull1.length - 1)
        .join()
        .replace(/,/g, ' ');
    }
  }

  const setNamer = (e) => {
    setFirstName1(e.target.value);
  };

  const setNamer1 = (e) => {
    setMiddleName1(e.target.value);
  };

  const setNamer2 = (e) => {
    setLastName1(e.target.value);
  };

  // formData.fullName = firstName + " "+ middleName + " "+ lastName

  const saveandexit = () => {
    formData.secondholder =
      firstName1 + (middleName1 ? ' ' + middleName1 : '') + (lastName1 ? ' ' + lastName1 : '');
    formData.documentCountryOfIssue2 = selected;
    var x = $('#colorselector option:selected').val();
    formData.idType2 = x;
    if (x === '1' || x === '') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        func();
      } else {
        func();
      }
    } else if (formData.idType2 === '2') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        func();
      } else {
        func();
      }
    } else {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        func();
      } else {
        func();
      }
    }
  };

  let countrtyCode = [
    'NZ',
    'AF',
    'AL',
    'DZ',
    'AS',
    'AD',
    'AO',
    'AI',
    'AG',
    'AR',
    'AM',
    'AW',
    'AU',
    'AT',
    'AZ',
    'BS',
    'BH',
    'BD',
    'BB',
    'BY',
    'BE',
    'BZ',
    'BJ',
    'BM',
    'BT',
    'BO',
    'BA',
    'BW',
    'BR',
    'IO',
    'BG',
    'BF',
    'BI',
    'KH',
    'CM',
    'CA',
    'CV',
    'KY',
    'CF',
    'TD',
    'CL',
    'CN',
    'CO',
    'KM',
    'CG',
    'CD',
    'CK',
    'CR',
    'CI',
    'HR',
    'CU',
    'CW',
    'CY',
    'CZ',
    'DK',
    'DJ',
    'DM',
    'DO',
    'EC',
    'EG',
    'SV',
    'GQ',
    'ER',
    'EE',
    'ET',
    'FK',
    'FO',
    'FJ',
    'FI',
    'FR',
    'PF',
    'GA',
    'GM',
    'GE',
    'DE',
    'GH',
    'GI',
    'GR',
    'GL',
    'GD',
    'GU',
    'GT',
    'GG',
    'GN',
    'GW',
    'HT',
    'HN',
    'HK',
    'HU',
    'IS',
    'IN',
    'ID',
    'IR',
    'IQ',
    'IE',
    'IM',
    'IL',
    'IT',
    'JM',
    'JP',
    'JE',
    'JO',
    'KZ',
    'KE',
    'KI',
    'KP',
    'KR',
    'KW',
    'KG',
    'LA',
    'LV',
    'LB',
    'LS',
    'LR',
    'LY',
    'LI',
    'LT',
    'LU',
    'MO',
    'MK',
    'MG',
    'MW',
    'MY',
    'MV',
    'ML',
    'MT',
    'MH',
    'MQ',
    'MR',
    'MU',
    'MX',
    'FM',
    'MD',
    'MC',
    'MN',
    'ME',
    'MS',
    'MA',
    'MZ',
    'MM',
    'NA',
    'NR',
    'NP',
    'NL',
    'NZ',
    'NI',
    'NE',
    'NG',
    'NU',
    'NF',
    'MP',
    'NO',
    'OM',
    'PK',
    'PW',
    'PS',
    'PA',
    'PG',
    'PY',
    'PE',
    'PH',
    'PN',
    'PL',
    'PT',
    'PR',
    'QA',
    'RO',
    'RU',
    'RW',
    'KN',
    'WS',
    'SM',
    'ST',
    'SA',
    'SN',
    'RS',
    'SC',
    'SL',
    'SG',
    'SX',
    'SK',
    'SI',
    'SB',
    'SO',
    'ZA',
    'SS',
    'ES',
    'LK',
    'SD',
    'SR',
    'SZ',
    'SE',
    'CH',
    'SY',
    'TW',
    'TJ',
    'TZ',
    'TH',
    'TG',
    'TK',
    'TO',
    'TT',
    'TN',
    'TR',
    'TM',
    'TC',
    'TV',
    'UG',
    'UA',
    'AE',
    'GB',
    'US',
    'UY',
    'UZ',
    'VU',
    'VE',
    'VN',
    'VI',
    'YE',
    'ZM',
    'ZW'
  ];

  let errortext = 'please uploade a valid image';
  const [errror, setErrror] = useState();
  const [imageLoader, setImageLoader] = useState();
  const [imageLoader1, setImageLoader1] = useState();

  const [state, setState] = useState({ shown: true });

  const [file, setFile] = useState({
    image: ''
  });
  const [imgeFile64, setImgeFile64] = useState('');
  const [documentFile64, setdocumentFile64] = useState('');
  let token = sessionStorage.getItem('token');

  const [user, setUser] = useState();
  // let { document, fileName } = documentFile64;
  const datetime = new Date();
  let valuedefine='document2'
  let labeltxt = 'Please attach a copy of your photo identification document (maximum file size is 2MB).';
  const columnName =  'documentProof';
  const fileName =  idType2 === '1' ||
            idType2 === 'undefined' ||
            idType2 === '' ||
            idType2 === undefined ||
            idType2 === null ||
            idType2 === 'NZ Drivers Licence'
            ? `Secondary_NZ_Drivers_Licence`
            : idType2 === '2'
              ? `Secondary_NZ_Passport`
              : idType2 === '3'
                ? `secondary__other`
                : ''


  let documentExpiryDates1 = sessionStorage.getItem('documentExpiryDates1');

  useEffect(() => {
    let documentExpiryDates1 = sessionStorage.getItem('documentExpiryDates1');
    $('.ui.icon.input>input').val(documentExpiryDates1);

    let dataStr = sessionStorage.getItem('allData');

    let dataObj = JSON.parse(dataStr);
    function setDateformate(date) {
      if (
        date !== null &&
        date !== 'undefined' &&
        typeof date !== 'undefined' &&
        date.includes('-')
      ) {
        let dat = date.split('-');
        dat = dat[2] + '/' + dat[1] + '/' + dat[0];
        return dat;
      }
      return date;
    }
    function readURL(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);
        $('#name_tag').text(input.files[0].name);
      }
    }
    function readURL1(input, imgControlName) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          $(imgControlName).attr('src', e.target.result);
        };
        reader.readAsDataURL(input.files[0]);

        $('#name_tag1').text(input.files[0].name);
      }
    }

    $('#imag2').change(function () {
      var imgControlName = '#ImgPreview2';
      readURL(this, imgControlName);
      $('.preview2').addClass('it');
      $('.btn-rmv2').addClass('rmv');
    });
    $('#imag4').change(function () {
      var imgControlName = '#ImgPreview3';
      readURL1(this, imgControlName);
      $('.preview3').addClass('it');
      $('.btn-rmv3').addClass('rmv');
    });

    $('#removeImage2').click(function (e) {
      e.preventDefault();
      $('#imag2').val('');
      $('#ImgPreview2').attr('src', '');
      $('.preview2').text('');
      $('.btn-rmv2').removeClass('rmv');
    });
    $('#removeImage3').click(function (e) {
      e.preventDefault();
      $('#imag4').val('');
      $('#ImgPreview3').attr('src', '');
      $('.preview3').text('');
      $('.btn-rmv3').removeClass('rmv');
    });

    $(function () {
      $('#colorselector')
        .change(function () {
          var select = $(this).find(':selected').val();
          $('.hide').hide();
          $('#' + select).show();
        })
        .change();
    });

    $('.flagsave').click(function () {
      let documentCountryOfIssue2 = $('.counrtyselext3 #select_flag_button > span > span').text();
      localStorage.setItem('documentCountryOfIssue2', documentCountryOfIssue2);
    });
  });
  const [validate, setValidate] = useState(false);
  function expirydatesremovee(valuee) {
    sessionStorage.removeItem('documentExpiryDates1');
    {
      valuee === 'pass'
        ? sessionStorage.setItem('documentExpiryDates1', '')
        : valuee === 'lice'
          ? sessionStorage.setItem('documentExpiryDates1', '')
          : sessionStorage.setItem('documentExpiryDates1', '');
    }
  }
  function expirydates(valuee) {
    {
      valuee === 'pass'
        ? (documentExpiryDates1 = $('.ui.icon.input.pass>input').val())
        : valuee === 'lice'
          ? (documentExpiryDates1 = $('.ui.icon.input.lice>input').val())
          : (documentExpiryDates1 = $('.ui.icon.input.other>input').val());
    }

    sessionStorage.setItem('documentExpiryDates1', documentExpiryDates1);
    formData.documentExpiryDates1 = documentExpiryDates1;
    var d = new Date();
    var y = d.getFullYear();
    var m = d.getMonth() + 1;
    var D = d.getDate();
    var str =
      formData.documentExpiryDates1 !== 'undefined' &&
        formData.documentExpiryDates1 !== undefined &&
        formData.documentExpiryDates1 !== null
        ? formData.documentExpiryDates1
        : documentExpiryDates1;
    var year = str.split('/').pop();
    var date = str.split('/');
    var month = date[1];
    if (year > y) {
      setValidate(true);
      removeError();
      // alert("df",validate)
    } else if (year >= y && month > m) {
      setValidate(true);
      removeError();
    } else if (year >= y && month >= m && date[0] >= D) {
      setValidate(true);
      removeError();
    } else {
      setValidate(false);
      sessionStorage.removeItem('documentExpiryDates1');
      formData.documentExpiryDates1 = '';
    }
  }
  useEffect(() => {
    sessionStorage.setItem('documentExpiryDates1', documentExpiryDates1);
    formData.documentExpiryDates1 = documentExpiryDates1;
    var d = new Date();
    var y = d.getFullYear();
    var m = d.getMonth() + 1;
    var D = d.getDate();
    var str =
      formData.documentExpiryDates1 !== 'undefined' &&
        formData.documentExpiryDates1 !== undefined &&
        formData.documentExpiryDates1 !== null
        ? formData.documentExpiryDates1
        : '';
    var year = str.split('/').pop();
    var date = str.split('/');
    var month = date[1];
    if (year > y) {
      setValidate(true);
      removeError();
      // alert("df",validate)
    } else if (year >= y && month > m) {
      setValidate(true);
      removeError();
    } else if (year >= y && month >= m && date[0] >= D) {
      setValidate(true);
      removeError();
    } else {
      setValidate(false);
      sessionStorage.removeItem('documentExpiryDates1');
      formData.documentExpiryDates1 = '';
    }
  }, []);
  let documentExpiryDate2s = '';
  function previousclick(e) {
    formData.secondholder =
      firstName1 + (middleName1 ? ' ' + middleName1 : '') + (lastName1 ? ' ' + lastName1 : '');
    let name = $('#name_tag').text();
    let nameExpression = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    formData.documentCountryOfIssue2 = selected;
    formData.documentNumber2 = documentNumber2;
    var x = $('#colorselector option:selected').val();
    formData.idType2 = x;
    if (x === '1' || x === '') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        go('Jointten');
      } else {
        go('Jointten');
      }
    } else if (formData.idType2 === '2') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        go('Jointten');
      } else {
        go('Jointten');
      }
    } else {
      documentExpiryDate2s = $('.ui.icon.input.counrtyselext8>input').val();

      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        go('Jointten');
      } else {
        go('Jointten');
      }
    }
  }

  function handleClick(e) {
    formData.secondholder =
      firstName1 + (middleName1 ? ' ' + middleName1 : '') + (lastName1 ? ' ' + lastName1 : '');
    let name = $('#name_tag').text();
    let nameExpression = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
    formData.documentCountryOfIssue2 = selected;
    var x = $('#colorselector option:selected').val();

    formData.idType2 = x;
    if (formData.documentProof2 === '' || formData.documentProof2 === null || formData.documentProof2 === undefined) {
      $('.documentError').html('Please attach identification document');
    }
    else{
    if (x === '1' || x === '') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        if (validate === true) {
          formData.class = 'Eight';
          next();
        } else {
          $('.licexperror').html('Please select valid expiry date');
        }
      } else {
        if (validate === true) {
          formData.class = 'Eight';
          next();
        } else {
          $('.licexperror').html('Please select valid expiry date');
        }
      }
    } else if (formData.idType2 === '2') {
      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        formData.class = 'Eight';
        next();
      } else {
        formData.class = 'Eight';
        next();
      }
    } else {
      documentExpiryDates1 = $('.ui.icon.input.counrtyselext8>input').val();
      formData.documentExpiryDates1 = documentExpiryDates1;

      if (
        formData.documentProof2 !== null &&
        formData.documentProof2 !== undefined &&
        formData.documentProof2 !== 'undefined' &&
        formData.documentProof2 !== ''
      ) {
        formData.class = 'Eight';
        next();
      } else {
        formData.class = 'Eight';
        next();
      }
    }
  }
}
  function removeError() {
    $('.error').html('');
    $('.documentCountryOfIssue2error').html('');


  }

  const [currentDate, setNewDate] = useState(null);
  const onChange = (event, data) => setNewDate(data.value);

  saveAndExist = () => {
    formData.status = 'PENDING';
    formData.step = '5';
    go('submit');
  };
 
  idinfo = () => {
    swal({
      text: 'MyFarm is required to comply with the New Zealand Government regulations to fight the funding of terrorism and money laundering activities. We are required to obtain, verify, and record information about your identity.'
    });
  };
  const { go } = navigation;

  const [selected, setSelected] = useState(formData.documentCountryOfIssue2);

  $('select').change(function () {
    $(this).css('color', $(this).addClass('select_op_color'));
  });
  const inform = () => {
    swal({
      text: 'Certified copies must be stamped or endorsed as true copies of the originals by a person authorized by law to take statutory declarations. This maybe a Lawyer, Notary Public, Justice of Peace or Court Official.'
    });
  };
  const inform1 = () => {
    Swal.fire({
      html: '<p class="sizeText">The secondary form of identification must be a certified copy of one of the following:</p><ul class="sideleft"><li>Credit card statement issued by a registered bank (dated within the last 3 months)</li><li>Bank statement issued by a registered bank (dated within the last 3 months)</li><li>SuperGold Card</li><li>NZ Defence Photo ID</li><li>Police Photo ID</li><li>Community Services Card</li><li>Statement issued by a government agency</li></ul>'
    });
  };

  function setvalue() {
    formData.documentNumber2 = '';
    sessionStorage.setItem('documentExpiryDates1', '');

    formData.documentExpiryDate = '';
    formData.documentVersionNumber2 = '';
    formData.documentCountryOfIssue2 = '';
    formData.documentProof2 = '';
    $('#name_tag').text('');
    $('.btn-rmv2').text('');
    $('.preview2').text('');
    $('.preview3').text('');
    $('#removeImage2').html('');
    $('.btn-rmv2').removeClass('rmv');
    $('.btn-rmv3').removeClass('rmv');
  }
  formData.firstName1 = firstName1;
  formData.lastName1 = lastName1;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (formData.check3 === false) {
      if (firstName1 === '') {
        $('.firstName1').html('Please enter your first name');
        console.log('Please enter your first name');
      }
      if (
        formData.documentNumber2 === '' ||
        formData.documentNumber2 === null ||
        formData.documentNumber2 === undefined
      ) {
        $('.documentNumber2').html('Please enter your document number');
      }
      //       if( formData.documentProof2===''||formData.documentProof2===null||formData.documentProof2===undefined){
      //         $('.document').html('Please attach your document proof');
      // }
      if (
        formData.otherDocumentName2 === '' ||
        formData.otherDocumentName2 === null ||
        formData.otherDocumentName2 === undefined
      ) {
        $('.otherDocumentName2').html('Please enter your document type');
      }
      if (
        documentExpiryDates1 === '' ||
        documentExpiryDates1 === null ||
        documentExpiryDates1 === undefined
      ) {
        $('.documentExpiryDates1').html('Please select document expiry date');
      }
      if (lastName1 === '') {
        $('.lastName').html('Please enter your last name');
      }
      if (
        documentVersionNumber2 === '' ||
        documentVersionNumber2 === null ||
        documentVersionNumber2 === undefined
      ) {
        $('.documentVersionNumber2').html('Please enter document version number');
      }
      if (
        formData.documentNumber2 === '' ||
        formData.documentNumber2 === null ||
        formData.documentNumber2 === undefined
      ) {
        $('.licenceNumbersecond').html('Please enter your licence number');
      }

      if (
        formData.documentNumber2 === '' ||
        formData.documentNumber2 === null ||
        formData.documentNumber2 === undefined
      ) {
        $('.Passportnumber').html('Please enter your passport number');
      }
      if (
        formData.documentCountryOfIssue2 === '' ||
        formData.documentCountryOfIssue2 === 'Select' ||
        formData.documentCountryOfIssue2 === null ||
        formData.documentCountryOfIssue2 === undefined
      ) {
        $('.documentCountryOfIssue2error').html('Please select document country of Issue');
      }
      if (formData.documentProof2 === '' || formData.documentProof2 === null || formData.documentProof2 === undefined) {
        $('.documentError').html('Please attach identification document');
      }
    }

  });

  // Validation start
  const [image, setImage] = useState();
  const downloadImage = (imageUrl) => {
    let postadv = {
      downloadUrl: imageUrl
    };
    axios
      .post(`${multisavedata}/download/image`, postadv, {
        headers: { Authorization: `Bearer ${formData.token}` }
      })
      .then((res) => {
        window.open(res.data.url);
      });
  };


  return (
    <>
      <MenuSidebar navigation={navigation} formData={formData} />
      <div className="main_container">
        <div className="heading__top">
          <h2>
          Step 4: Identity Verification -{' '}
            {(firstName1 != null && firstName1 != '' && firstName1 != 'undefined' && firstName1 != undefined
              ? firstName1
              : '') +
              ' ' +
              (lastName1 != null && lastName1 != '' && lastName1 != 'undefined'  && lastName1 != undefined ? lastName1 : '')}
          </h2>
        </div>

        <div className="form">
          <img src={logo} alt="img" className="imageCover" />
          <div className="save-exit se_invff">
            <p className="save next" onClick={saveandexit}>
              SAVE DATA{' '}
            </p>
            <img src={info} onClick={infodetls} className="info" />
          </div>

          <Row className="six_page">
            <Col md={12}>
              <label className="font-fami">
                Select the type of photo identification you are providing
              </label>

              <div className="button dropdown">
                <select
                  id="colorselector"
                  name="idType2"
                  value={formData.idType2}
                  onChange={(e) => {
                    setvalue();
                    formData.check3 = true;

                    setForm(e);
                    removeError();
                  }}
                >
                  <option value="1" selected>
                    NZ Driver Licence
                  </option>
                  <option value="2">NZ Passport</option>
                  <option value="3">Other</option>
                </select>
              </div>

              <div class="output date-w">
                <label> First name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="firstName1"
                  onKeyPress={removeError}
                  value={firstName1}
                  onChange={(e) => {
                    formData.check3 = true;

                    setForm(e);
                    setNamer(e);
                    removeError();
                  }}
                  placeholder="Enter first name(s)"
                />
                <span className="error firstName1 fullNameerror"></span>
                <label> Middle name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="middleName1"
                  onKeyPress={removeError}
                  value={middleName1}
                  onChange={(e) => {
                    formData.check3 = true;

                    setForm(e);
                    setNamer1(e);
                    removeError();
                  }}
                  placeholder="Enter middle name(s)"
                />
                {/* <span className="error middleName"></span> */}
                <label> Last name</label>
                <ItemForm
                  type="text"
                  className="quantity"
                  name="lastName1"
                  onKeyPress={removeError}
                  value={lastName1}
                  onChange={(e) => {
                    formData.check3 = true;

                    setForm(e);
                    setNamer2(e);
                    removeError();
                  }}
                  placeholder="Enter last name(s)"
                />
                <span className="error lastName"></span>
                <div id="1" className="colors red hide">
                  <label> Licence number</label>
                  <ItemForm
                    className="digitsOnly"
                    type="text"
                    name="documentNumber2"
                    onKeyPress={removeError}
                    value={documentNumber2}
                    onChange={(e) => {
                      formData.check3 = true;

                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter licence number"
                  />
                  <span className="error licenceNumbersecond"></span>
                  <label> Expiry date</label>
                  <SemanticDatepicker
                    onChange={(e) => {
                      formData.check3 = true;

                      removeError();
                      expirydates('lice');
                    }}
                    onClick={(e) => {
                      formData.check3 = true;
                      removeError();
                      expirydatesremovee('lice');
                    }}
                    className="lice counrtyselext6 numbers input__w50_a"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDates1"
                    // value={documentExpiryDate2}
                    allowOnlyNumbers
                  />
                  <span className="error documentExpiryDates1 licexperror" />

                  <label> Version number</label>
                  <InputMask
                    maxlength="3"
                    className="numbers"
                    name="documentVersionNumber2"
                    onKeyPress={removeError}
                    value={documentVersionNumber2}
                    onChange={(e) => {
                      formData.check3 = true;

                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter version number"
                  />
                  <span className="error documentVersionNumber2"></span>
                </div>

                <div id="2" className="colors yellow hide" onClick={removeError}>
                  <label> Passport number</label>
                  <ItemForm
                    className="digitsOnly"
                    name="documentNumber2"
                    onKeyPress={removeError}
                    maxLength={15}
                    value={documentNumber2}
                    onChange={(e) => {
                      formData.check3 = true;

                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter passport number"
                  />
                  <span className="error Passportnumber passportNumbersecond"></span>
                  <label> Expiry date</label>

                  <SemanticDatepicker
                    onChange={(e) => {
                      formData.check3 = true;

                      removeError();
                      expirydates('pass');
                    }}
                    onClick={(e) => {
                      formData.check3 = true;
                      removeError();
                      expirydatesremovee('pass');
                    }}
                    className="pass counrtyselext7 numbers input__w50_a"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDates1"
                    allowOnlyNumbers
                  // value={documentExpiryDate2}
                  />
                  <span className="error documentExpiryDates1 licexperror" />

                  <div className="FlagsSelectcss">
                    <label> Country of issue</label>
                    <ReactFlagsSelect
                      name="documentCountryOfIssue2"
                      selected={selected}
                      onSelect={(code) => {
                        setSelected(code)
                        removeError();
                        formData.check3 = true;


                      }}
                      className="counrtyselext3 input__w50"
                      value={documentCountryOfIssue2}
                      onChange={(e) => {
                        formData.check3 = true;

                        setForm(e);
                        removeError();
                      }}
                      onClick={removeError()}
                      defaultCountry={
                        documentCountryOfIssue2 && documentCountryOfIssue2 !== null
                          ? getCode(documentCountryOfIssue2.toUpperCase())
                          : null
                      }
                      countries={countrtyCode}
                      placeholder="Select"
                    />
                  </div>
                  <span className="error documentCountryOfIssue2error"></span>
                </div>

                <div id="3" className="colors blue hide" onClick={removeError}>
                  <label> Document type</label>
                  <ItemForm
                    className="digitsOnly"
                    type="text"
                    onKeyPress={removeError}
                    name="otherDocumentName2"
                    maxLength={30}
                    value={otherDocumentName2}
                    onChange={(e) => {
                      formData.check3 = true;

                      setForm(e);
                      removeError();
                    }}
                    placeholder="EG passport, firearms licence"
                  />
                  <span className="error otherDocumentName2"></span>
                  <label> Document number</label>
                  <input
                    type="text"
                    name="documentNumber2"
                    onKeyPress={removeError}
                    maxLength={15}
                    value={documentNumber2}
                    onChange={(e) => {
                      formData.check3 = true;

                      setForm(e);
                      removeError();
                    }}
                    placeholder="Enter number"
                    className="digitsOnly"
                  />
                  <span className="error documentNumber2"></span>

                  <label> Expiry date</label>

                  <SemanticDatepicker
                    onChange={(e) => {
                      formData.check3 = true;

                      removeError();
                      expirydates('other');
                    }}
                    onClick={(e) => {
                      formData.check3 = true;
                      removeError();
                      expirydatesremovee('other');
                    }}
                    className="other counrtyselext8 numbers"
                    minDate={now()}
                    format="DD/MM/YYYY"
                    name="documentExpiryDates1"
                    allowOnlyNumbers
                  />
                  <span className="error documentExpiryDates1 licexperror" />

                  <div className="FlagsSelectcss">
                    <label> Country of issue</label>

                    <ReactFlagsSelect
                      name="documentCountryOfIssue2"
                      selected={selected}
                      value={documentCountryOfIssue2}
                      onKeyPress={removeError}
                      onChange={(e) => {
                        setForm(e);
                        removeError();
                        formData.check3 = true;

                      }}
                      placeholder="Select"
                      onClick={removeError()}
                      onSelect={(code) => {
                        formData.check3 = true;
                        setSelected(code)
                      }

                      }
                      defaultCountry={
                        documentCountryOfIssue2 && documentCountryOfIssue2 !== null
                          ? getCode(documentCountryOfIssue2.toUpperCase())
                          : null
                      }
                    />
                  </div>

                  <span className="error documentCountryOfIssue2error"></span>
                </div>
              </div>
            </Col>
          </Row>
          {/* <Row className="fullinput">
          <Col xs={12} className='d-flex flex-wrap'>
              <div className='attach'>
              <div class="yes1">
                <button className="click-hover">
                  {' '}
                  <span class="btn_upload">
                    <input
                      type="file"
                      id="imag2"
                      title=""
                      name="image"
                      onClick={() => {
                        formData.check3 = true;

                        removeError();
                      }}
                      value={document}
                      onChange={(e) => {
                        formData.check3 = true;

                        uploadWithJSON(e.target.files[0]);
                        removeError();
                      }}

                    // onClick={(e) => (e.target.value = null)}
                    />
                  </span>
                  CLICK TO ATTACH
                </button>
              </div>
              </div>
              <div className="attach-styhle-proof">
                <label className="bankdClick">
                   Please attach a copy of your photo identification document.
                </label>
              </div>
            </Col>

          </Row>
          <span className="error document"></span>
          <div className="spceimg">
            {formData.idType2 === '1' ||
              formData.idType2 === '' ||
              formData.idType2 === 'undefined' ||
              formData.idType2 === undefined ||
              formData.idType2 === null ? (
              formData.documentProof2 !== null &&
                formData.documentProof2 !== undefined &&
                formData.documentProof2 !== 'undefined' &&
                formData.documentProof2 !== '' ? (
                <a href="#" className="licedocument-downloade" onClick={()=>downloadImage(formData.documentProof2)}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : formData.idType2 === '2' ? (
              formData.documentProof2 !== null &&
                formData.documentProof2 !== undefined &&
                formData.documentProof2 !== 'undefined' &&
                formData.documentProof2 !== '' ? (
                <a href="#" className="licedocument-downloade" onClick={()=>downloadImage(formData.documentProof2)}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : formData.idType2 === '3' ? (
              formData.documentProof2 !== null &&
                formData.documentProof2 !== undefined &&
                formData.documentProof2 !== 'undefined' &&
                formData.documentProof2 !== '' ? (
                <a href="#" className="licedocument-downloade" onClick={()=>downloadImage(formData.documentProof2)}>
                  {' '}
                  <div className="main-downloade-wrapper">
                    <div className="downloads">
                      <i className="fa fa-download downloadeStyle"></i>
                    </div>
                    <p className="downloadeMsg">View attached file</p>
                  </div>
                </a>
              ) : (
                ''
              )
            ) : (
              ''
            )}

            <span id="name_tag" class="preview2"></span>
            <input type="button" id="removeImage2" value="x" class="btn-rmv2" />

            <p className="red">{errror}</p>
            {imageLoader === true ? (
              <div class="progress">
                <span class="progress-bar" style={{ width: '100%' }}></span>
              </div>
            ) : null}
          </div>

          {formData.idType2 === '1' ||
            formData.idType2 == '' ||
            formData.idType2 == 'undefined' ||
            formData.idType2 == undefined ? (
            
          ) : (
            ''
          )} */}
            <UploadImage
            formData={formData}
            columnName={columnName}
            fileName={fileName}
            labeltxt={labeltxt}
            buttonName='CLICK TO ATTACH'
            valuedefine={valuedefine}
          />
          <span className='documentError error'></span>

          <div className="fotter-btn">
            <button className="preview" onClick={previousclick}>
              <img src={leftarrow} className="leftarrow" alt="" />
              Previous
            </button>
            {imageLoader === true ? (
              <button type="submit" className="next flagsave">
                <i class="fas fa-circle-notch fa-spin iconstyle "></i>Uploading....
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            ) : (
              <button type="submit" className="next flagsave" onClick={handleClick}>
                Continue
                <img src={rightarrow} className="rightarrow" alt="" />{' '}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Four;
